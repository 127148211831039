import React, { memo } from 'react'

// Libraries
import { Col, Nav, Row, Tab, Container, } from "react-bootstrap"
import { PropTypes } from "prop-types";

// Components
import Accordions from '../Accordion/Accordion'

// Data
import { TabData05 } from './TabData'



const Tab05 = (props) => {

    return (
        <Tab.Container fluid id="left-tabs-example" defaultActiveKey={0}>
            <Row className="tab-style-05 justify-center">
                <Col lg={12}>
                    <Nav className="justify-center xxl:mb-[50px] mxl:mb-[50px] xl:mb-[50px] lg:mb-[50px] md:mb-[50px] xs:mb-[0px]">
                        {
                            props.data.map((item, i) => {
                                return (
                                    <Nav.Item key={i} className="mx-[20px] px-[20px] md:px-[8px] sm:mx-0 sm:mb-[15px] xs:mb-[0px] xs:w-auto lg:mx-[-30px] xs:mx-[-7px] md:mx-[10px] flex-center mxl:mt-[100px] xl:mt-[80px] lg:mt-[120px] md:mt-[80px] xs:mt-[0px]">
                                        <Nav.Link className="text-center" eventKey={i}>
                                            {item.tabicons && <i className={`${item.tabicons} text-[#0F3443] mb-[10px] block  mxl:text-[50px] xl:text-[30px] lg:text-[30px] md:text-[30px] xs:text-[20px]`}></i>}               
                                            {item.tabTitle}
                                        </Nav.Link>
                                    </Nav.Item>
                                )
                            })
                        }
                    </Nav>
                </Col>
                <Col>
                    <Tab.Content>
                        {
                            props.data.map((item, i) => {
                                return (
                                    <Tab.Pane key={i} className="tab-style" eventKey={i}>
                                        <Container fluid>
                                            <Row>
                                                <Col>
                                                    {item.img && <img height="" width="" src={item.img} alt="" />}
                                                </Col>
                                                <Col lg={6} md={6}>                                         
                                                    {item.title && <h3 className="tracking-[1px] text-uppercase font-serif font-semibold text-[#326765]  mxl:text-[22px] xl:text-[22px] lg:text-[22px] md:text-[15px] mxl:mb-[10px] xl:mb-[10px] lg:mb-[10px] md:mb-[10px] xs:mt-[10px] md:leading-[28px] xs:leading-[25px] xs:mb-[10px]">{item.title}</h3>}
                                                    {item.content && <p className="text-[#0F3443] mb-[10px] text-justify mxl:text-[20px] md:text-[14px] xs:text-[13px] font-light xxl:leading-[32px] mxl:leading-[32px] xl:leading-[25px] lg:leading-[25px] md:leading-[20px] xs:leading-[20px]">{item.content}</p>}
                                                    {item.subcontent && <p className="text-[#0F3443] mb-[10px] text-justify mxl:text-[20px] md:text-[14px] xs:text-[13px] font-light xxl:leading-[32px] mxl:leading-[32px] xl:leading-[25px] lg:leading-[25px] md:leading-[20px] xs:leading-[20px]">{item.subcontent}</p>}
                                                    {item.subsubcontent && <p className="text-[#0F3443] text-justify mxl:text-[20px] md:text-[14px] xs:text-[13px] font-light xxl:leading-[32px] mxl:leading-[32px] xl:leading-[25px] lg:leading-[25px] md:leading-[20px] xs:leading-[20px]">{item.subsubcontent}</p>}
                                                </Col>
                                            </Row>
                                            </Container>
                                    </Tab.Pane>
                                )
                            })
                        }
                    </Tab.Content>
                </Col>
            </Row>
        </Tab.Container>
    )
}



{/* <Container fluid>
    <Row>
        <Col>
            {item.img && <img height="" width="" src={item.img} alt="" />}
        </Col>


        <Col
            xl={5}
            lg={6}
            className="bg-[#0F3443] py-40 px-[9%] mxl:p-[5%] xl:p-[6%] lg:p-[6%] md:p-[10%] sm:py-[15%] sm:px-[7%] text-center">
            <m.span {...fadeIn} className="font-serif font-medium text-gradient bg-gradient-to-r from-[#F2C05A] to-[#D36135] tracking-[2px] xl:text-[25px] mxl:text-[30px] xs:text-[14px] uppercase block mb-[35px]">
                Innovation at the Forefront:
            </m.span>
            <m.h2 {...{
                ...fadeIn,
                transition: { delay: 0.4 }
            }} className="text-center heading-4 mxl:text-[50px] mxl:tracking-[2px] mxl:leading-[75px] xs:text-[25px] font-serif font-semibold text-white -tracking-[.5px] mb-12 w-[90%] sm:w-full">
                Redefining <br></br>Biodiversity Measures <br></br> with Advanced Insect Monitoring
            </m.h2>
            <m.p {...{
                ...fadeIn,
                transition: { delay: 0.6 }
            }} className="text-lg mxl:text-[25px] xl:text-[20px] mxl:leading-[45px] mxl:tracking-[2px] font-light text-white leading-[38px] w-[90%] lg:w-full md:w-[80%] sm:w-full md:text-xmd">
                Introducing the eVolito sensor system. A paradigm shift

                <span className="font-serif font-medium text-gradient bg-gradient-to-r from-[#F2C05A] to-[#D36135] tracking-[2px] xl:text-[22px] mxl:text-[25px] xs:text-[14px] xs:tracking-[0px] uppercase block">
                    Elevate your environmental intelligence <br></br>with our sensor technology
                </span><br></br><span className="mxl:text-[25px] xl:text-[22px] font-regular mxl:tracking-[2px]">
                    Disclosing precision, sustainability, and innovation. </span>
            </m.p>
        </Col>
    </Row>
</Container> */}









Tab05.defaultProps = {
    data: TabData05
}

Tab05.propTypes = {
    data: PropTypes.arrayOf(
        PropTypes.exact({
            tabTitle: PropTypes.string,
            tabicons: PropTypes.string,
            subtitle: PropTypes.string,
            img: PropTypes.string,
            title: PropTypes.string,
            content: PropTypes.string,
            buttonTitle: PropTypes.string,
            buttonLink: PropTypes.string,
        })
    ),
}


export default memo(Tab05)




// const Tab05 = (props) => {
//     return (
//         <div>
//             <Tab.Container id="left-tabs-example" defaultActiveKey={0}>
//                 <Row className="tab-style-05 justify-center">
//                     <Col lg={12} className="text-center">
//                         <Nav className="mb-[78px] md:mb-20 xs:mx-[15px] items-center justify-center font-medium uppercase">
//                             {
//                                 props.data.map((item, i) => {
//                                     return (
//                                         <Nav.Item key={i} className="">
//                                             <Nav.Link className="text-center" eventKey={i}>
//                                                 {item.tabTitle}
//                                             </Nav.Link>
//                                         </Nav.Item>
//                                     )
//                                 })
//                             }
//                         </Nav>
//                     </Col>
//                     <Col>
//                         <Tab.Content>
//                             {
//                                 props.data.map((item, i) => {
//                                     return (
//                                         <Tab.Pane eventKey={i} key={i}>
//                                             <Row className="justify-center">
//                                                 <Col lg={10} className='md:px-0'>
//                                                     <Accordions
//                                                         theme="accordion-style-05"
//                                                         className=""
//                                                         themeColor="light"
//                                                         data={item.activities}
//                                                     />
//                                                 </Col>
//                                             </Row>
//                                         </Tab.Pane>
//                                     )
//                                 })
//                             }
//                         </Tab.Content>
//                     </Col>
//                 </Row>
//             </Tab.Container>
//         </div>
//     )
// }

// Tab05.defaultProps = {
//     data: TabData05
// }

// Tab05.propTypes = {
//     data: PropTypes.arrayOf(
//         PropTypes.exact({
//             tabTitle: PropTypes.string,
//             tabicons: PropTypes.string,
//             activities: PropTypes.arrayOf(
//                 PropTypes.exact({
//                     accordionid: PropTypes.string,
//                     accordionhref: PropTypes.string,
//                     time: PropTypes.string,
//                     title: PropTypes.string,
//                     content: PropTypes.string,
//                     author: PropTypes.string,
//                 })),
//         })
//     ),
// }

// export default memo(Tab05)
