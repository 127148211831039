import React, { useState, memo } from 'react'
import { m } from "framer-motion"


// Libraries

import { Col, Container, NavLink, Navbar, Row, Nav, Tab, } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import * as cheerio from 'cheerio'


// Components

import { fadeIn, fadeInLeft } from '../../../../Functions/GlobalAnimations'
import Blockquote from '../../../../Components/Blockquote/Blockquote'
import Overlap from '../../../../Components/Overlap/Overlap'
import Tab05 from '../../../../Components/Tab/Tab05'
import IconWithText from '../../../../Components/IconWithText/IconWithText'

// Data

import { blogData } from '../../../../Components/Blogs/BlogData'

const IconWithTextData_03 = [
  {
    icon: "line-icon-Monitor-Analytics text-gradient bg-gradient-to-r from-[#75BDBA] via-[#50A5A2] to-[#326765] text-[40px]",
    title: "Assess Current Efforts",
    content: "Evaluate your existing practices and identify areas for improvement. Conduct a thorough review of your ESG (Environmental, Social, and Governance) practices and assess the impact of your products and services.",
  },
  {
    icon: "line-icon-Biotech text-gradient bg-gradient-to-r from-[#75BDBA] via-[#50A5A2] to-[#326765] text-[40px]",
    title: "Define CSR Objectives",
    content: "Set clear, achievable goals that align with your company's values and mission. These objectives should reflect your commitment to social and environmental responsibility.",
  },
  {
    icon: "line-icon-Cursor-Click2 text-gradient bg-gradient-to-r from-[#75BDBA] via-[#50A5A2] to-[#326765] text-[40px]",
    title: "Prioritize Initiatives",
    content: "Choose a manageable number of priority initiatives that are relevant and achievable. Involve employees, partners, and stakeholders in the decision-making process to ensure that the selected initiatives have broad support.",
  },
  {
    icon: "line-icon-Gear-2 text-gradient bg-gradient-to-r from-[#75BDBA] via-[#50A5A2] to-[#326765] text-[40px]",
    title: "Develop an Action Plan",
    content: "Create a detailed plan with timelines, tasks, and responsibilities. This plan should outline the steps needed to achieve your CSR objectives and provide a roadmap for implementation.",
  },
]

const fancyTextBox04 = [
  {
    icon: {
      class: "line-icon-Globe text-basecolor"
    },
    title: "Global Efforts",
    subTitle: "Needed",
    description: "Biodiversity faces numerous threats, including habitat destruction, climate change, pollution, overexploitation, and invasive species. These threats often interact synergistically, exacerbating their impacts on ecosystems. Addressing these challenges requires coordinated global efforts and innovative solutions.",
    backgroundText: "01"
  },
  {
    icon: {
      class: "line-icon-Cranium text-basecolor"
    },
    title: "Long-Term Studies",
    subTitle: "in Biodiversity",
    description: "Understanding the complexities of biodiversity and ecosystem stability necessitates long-term and large-scale studies. These studies can provide insights into the temporal and spatial dynamics of ecosystems, helping to identify trends, patterns, and causal relationships. Long-term research is essential for developing effective conservation strategies and for understanding the long-term impacts of human activities and environmental changes.",
    backgroundText: "02"
  },
  {
    icon: {
      class: "line-icon-Biotech text-basecolor"
    },
    title: "Biodiversity Monitoring",
    subTitle: "Enhanced Technologies",
    description: "Advancements in technology are revolutionizing biodiversity monitoring. Tools such as remote sensing, DNA barcoding, and advanced sensors for measuring insect activity and other biological parameters enable more accurate and comprehensive assessments of biodiversity. These technologies facilitate real-time monitoring and data collection, improving our ability to track changes and respond to threats promptly.",
    backgroundText: "03"
  }
]

const ProcessStepData06 = [
  {
    title: "Global Efforts Needed",
    content: ""
  },
  {
    title: "Long-Term Studies in Biodiversity",
    content: ""
  },
  {
    title: "Enhanced Biodiversity Monitoring Technologies",
    content: ""
  },
]

const TabData05 = [
  {
    tabTitle: 'Environmental Responsibility',
    // tabicons: "ti-light-bulb",
    subtitle: 'Transform your ideas. We know that good design means good business.',
    img: '/assets/img/blog/enviRes.jpg',
    title: 'Environmental Responsibility',
    content: 'Companies can significantly reduce their environmental footprint by adopting sustainability practices. This includes reducing energy consumption, utilizing renewable resources, and minimizing waste. Implementing these practices not only conserves natural resources but also often leads to cost savings and efficiency improvements.',
    subcontent: 'Addressing climate change is a crucial aspect of CSR. Businesses can contribute to climate action by reducing greenhouse gas emissions, supporting biodiversity through conservation efforts, and engaging in activities that mitigate their environmental impact. These initiatives not only help combat climate change but also enhance the company’s reputation as an environmentally responsible entity.',
    // buttonTitle: "watch a projects",
    // buttonLink: "#"
  },
  {
    tabTitle: 'Social Responsibility',
    // tabicons: "ti-timer",
    img: '/assets/img/blog/sociRes.jpg',
    title: 'Social Responsibility',
    subtitle: 'Transform your ideas. We know that good design means good business.',
    content: 'Social responsibility involves ensuring safe working conditions and fair wages for all employees. Companies must strive to eliminate exploitative labor practices and promote fair treatment across their supply chains. This commitment fosters a more loyal and motivated workforce.',
    subcontent: 'Social responsibility is also about fostering diversity, equality, and inclusion (DEI). Companies should actively work towards creating a diverse workforce, ensuring equal opportunities for all employees, and fostering an inclusive environment where every individual feels valued and respected. This broader approach to social responsibility helps build a more dynamic and innovative workplace, enhances employee satisfaction, and strengthens the companys reputation as a fair and ethical employer.',
    subsubcontent: 'Promoting honesty and accountability is essential for building trust with stakeholders. Transparent business operations, including clear communication about company practices and policies, help ensure that all stakeholders are informed and engaged. This openness can prevent misunderstandings and promote a positive corporate image.',
    // buttonTitle: "watch a projects",
    // buttonLink: "#"
  },
  {
    tabTitle: 'Philanthropic Responsibility',
    // tabicons: "ti-pie-chart",
    img: '/assets/img/blog/philRes.jpg',
    title: 'Philanthropic Responsibility',
    subtitle: 'Transform your ideas. We know that good design means good business.',
    content: 'Businesses can demonstrate their commitment to social responsibility by engaging with their communities. This can involve financing of projects, providing of resources, or investing time in local and national causes. Such initiatives not only provide direct benefits to the community but also enhance the company’s reputation and foster goodwill.',
    subcontent: 'Investing in programs that support education, disaster relief, and other social initiatives is another vital aspect of philanthropic responsibility. These programs can have a significant impact on society, addressing pressing issues and providing necessary resources to those in need.',
    // subsubcontent:'',
    // buttonTitle: "watch a projects",
    // buttonLink: "#"
  },
  {
    tabTitle: 'Economic Responsibility',
    // tabicons: "ti-crown",
    img: '/assets/img/blog/econRes.jpg',
    title: 'Economic Responsibility',
    subtitle: 'Transform your ideas. We know that good design means good business.',
    content: 'Economic responsibility involves aligning business operations with social and environmental goals without compromising profitability. Companies can achieve sustainable growth by integrating ethical considerations into their business strategies, ensuring that their operations contribute positively to society and the environment.',
    subcontent: 'Supporting local economic development through job creation and partnerships with local businesses is a key aspect of economic responsibility. These efforts can help build stronger communities and foster economic resilience.',
    // subsubcontent:'',
    // buttonTitle: "watch a projects",
    // buttonLink: "#"
  },
]

const TextSliderData02 = [
  {
    img: "/assets/img/blog/snail.jpg",
    // title: 'Impact on',
    subtitle: 'Biodiversity',
    content: 'Higher biodiversity generally enhances both resistance and resilience. Diverse ecosystems can better withstand and recover from disturbances because they have multiple species performing various ecological roles. This functional redundancy means that if one species is affected, others can fill its role, maintaining ecosystem processes and stability.',

  },
  {
    img: "/assets/img/blog/complex.jpg",
    // title: 'Pollination',
    subtitle: 'Habitat Complexity',
    content: "Complex habitats with diverse physical structures provide more niches and support greater species diversity, contributing to ecosystem stability. These habitats offer various microenvironments that cater to different species' needs, reducing competition and allowing for coexistence. Additionally, the presence of multiple niches can buffer ecosystems against disturbances by ensuring that functional roles are maintained even if some species are lost.",

  },
  {
    img: "/assets/img/blog/humming.jpg",
    // title: "Medicinal",
    subtitle: "Functional Redundancy",
    content: 'The presence of multiple species with similar ecological functions can buffer the ecosystem against the loss of individual species, enhancing stability. One example is pollinators, where most people instantly think of bees. However, other animals, like hummingbirds, also contribute immensely to pollinating a wide range of plants and flowers.',

  },
  {
    img: "/assets/img/blog/connectedForest.jpg",
    // title: "Ecosystem",
    subtitle: "Connectivity",
    content: "Well-connected ecosystems allow for species movement and gene flow, which support recovery and adaptation to disturbances. These connections enable organisms to recolonize areas, maintain genetic diversity, and adapt to changing conditions, thereby enhancing the overall resilience of the ecosystem.",

  },
  {
    img: "/assets/img/blog/earthworm.jpg",
    // title: "Resilience to",
    subtitle: "Ecosystem Services",
    content: "The continuous provision of services such as pollination, nutrient cycling, and water purification helps maintain ecosystem stability. Earthworms, as an example, are significant to nutrient recycling because they consume and break down organic matter. Their burrowing activity also improves soil structure and aeration, promoting plant root growth and facilitating microbial activity essential for decomposition.",

  },
]

// Filter the blog data category wise
const blogCleanData = blogData.filter((item) => item.blogType === "clean");

const metadata = {
  "id": 3,
  "editor": {
    "uuid": "5bb7c896-58f2-4371-bda4-20d7fc95decd",
    "firstname": "Line",
    "lastname": "Kræmer",
    "email": "likr@faunaphotonics.com",
    "initials": "likr"
  },
  "title": "What Does CSR Stand For? And Why It Is Important",
  "url": null,
  "image": null,
  "slug": "what-does-csr-stand-for-and-why-it-is-important",
  "created_at": "2024-06-14T07:33:54.000000Z",
  "updated_at": "2024-06-14T07:33:54.000000Z",
  "deleted_at": null
}

const scroll = (h) => {
  const sections = document.querySelectorAll('h2')
  const target = sections[h]
  target.scrollIntoView({
    behavior: 'smooth',
    block: 'start',
  })
}


const CorporateSus = ({ post, props, style }) => {
  const [data, stripped, content, setData,] = useState(post)
  const swiperRef = React.useRef(null)

  const [header] = useState(post)

  const $ = cheerio.load(stripped)
  const h2 = $('h2')
  const toc = h2.map((contents) => {
    return contents.children[0]?.children[0]?.data
  })

  const scroll = (h) => {
    const sections = document.querySelectorAll('h2')
    const target = sections[h]
    target.scrollIntoView({
      behavior: 'smooth',
      block: 'start',
    })
  }


  const [activeSlide, setActiveSlide] = useState(0)

  const [submitted, setSubmitted] = useState(false)

  return (

    <div>

      <section className="w-full xxl:h-[900px] mxl:h-[800px] xl:h-[500px] sm:h-[200px] xs:h-[150px] cover-background relative"
        style={{ backgroundImage: "url('/assets/img/blog/blog3Banner.jpg')" }}>
      </section>

      {/* Section Start */}
      <section className=" bg-white pb-0 sm:py-[50px] xs:overflow-hidden xs:mt-[-80px] mxl:mt-[50px] xl:mt-[100px]">
        <Container className="xs:p-0">
          <Row className="justify-center z-10">
            <Overlap className="!px-0">
              <Col lg={10} className="font-serif text-center bg-[#326765] text-[#fff] mxl:py-[6rem] xl:py-[4.5rem] lg:py-[4.5rem] md:py-[4.5rem] sm:py-[3rem] xs:py-[2.5rem] xs:px-[15px] mxl:w-full relative mx-auto opacity-[95%] xs:pt-[-200px] xs:mb-[20px] "
                style={{ backgroundImage: "url('/assets/img/webp/blog-post-layout-02-img-pattern.webp')" }}>
                <div className="w-[1px] h-[90px] xs:h-[40px] bg-white mx-auto absolute top-0 left-1/2 -translate-x-1/2 -translate-y-1/2"></div>
                <div className="uppercase text-md font-medium mxl:mb-[25px] xl:mb-[25px] lg:mb-[25px] md:mb-[25px] xs:mb-[10px] font-serif">
                  <div className='text-white bg-black'></div>
                  <Link aria-label="link" to={'/solutions'} className="text-[#fff] inline-block xs:text-[12px]">Solutions</Link>
                  <span className="mx-[20px] w-[1px] h-[10px] bg-white inline-block"></span>
                  <Link aria-label="link" to={'/metrics'} className="inline-block text-[#fff] xs:text-[12px]">Metrics</Link>
                  <span className="mx-[20px] w-[1px] h-[10px] bg-white inline-block"></span>
                  <Link aria-label="link" to={'/contact'} className="inline-block text-[#fff] xs:text-[12px]">Contact</Link>
                </div>
                <h1 className="font-semibold w-full mxl:mb-[15px] xl:mb-[5px] lg:mb-[3px] xs:mb-[-25px] mxl:tracking-[1px] md:w-full xs:w-full xxl:text-[44px] mxl:text-[44px] xl:text-[38px] lg:text-[32px] md:text-[30px]  xs:text-[15px]">
                  What does CSR stand for?
                </h1>

                <h1 className="font-semibold xs:mb-[-20px] w-full mxl:tracking-[1px] md:w-full xs:w-full xxl:text-[44px] mxl:text-[44px] xl:text-[38px] lg:text-[32px] md:text-[30px] xs:text-[15px]">
                  And Why it's Important?
                </h1>
                <div className="inline-block text-[#fff] mt-[25px] xs:text-[13px]">
                  By FaunaPhotonics 08. July 2024
                </div>
              </Col>
            </Overlap>
          </Row>
        </Container>
      </section>
      {/* Seaction End */}

      {/* Section Start */}
      <m.section className="bg-white mxl:mt-[100px] xl:mt-[100px] lg:mt-[100px] md:mt-[50px] sm:mt-[40px] xs:mt-[20px]">
        <Container>
          <Row>
            <Col className="justify-center">
              <Col>
                <div className="bg-white">
                  <p className="text-justify font-light text-[#0F3443] mxl:text-[30px] xl:text-[28px] md:text-[22px] sm:text-[20px] xs:text-[12px] mxl:leading-[45px] xl:leading-[40px] lg:leading-[35px] md:leading-[30px] sm:leading-[30px] xs:leading-[20px]">
                    In today's interconnected world, businesses are increasingly expected
                    to play a role beyond generating profits. The concept of Corporate
                    Social Responsibility (CSR) has gained prominence as stakeholders,
                    consumers, employees, and investors demand more ethical, sustainable,
                    and socially responsible practices from companies. This shift reflects
                    a broader recognition that businesses must contribute positively to
                    society and the environment to ensure long-term success and credibility.
                  </p>
                </div>
              </Col>
            </Col>
          </Row>
        </Container>
      </m.section>
      {/* Seaction End */}

      {/* Section Start */}
      <m.section className="bg-white overflow-hidden mxl:mt-[50px] xl:mt-[50px] lg:mt-[50px] md:mt-[25px] sm:mt-[40px] xs:mt-[20px]">
        <Container>
          <Row className="justify-center">
            <h2 className="text-uppercase text-center font-serif font-semibold text-[#0F3443] mxl:mb-[50px] xl:mb-[50px] lg:mb-[50px] md:mb-[25px] sm:mb-[30px] xs:mb-[15px] tracking-[3px] mxl:text-[50px] xl:text-[50px] lg:text-[45px] md:text-[32px] xs:text-[20px] xs:tracking-[1px] xs:leading-[22px]">
              Definition of <span className="text-gradient bg-gradient-to-tr from-[#428A87] to-[#326765] font-semibold">CSR </span>
            </h2>
            <div className="bg-white">
              <p className="text-justify font-light text-[#0F3443] mxl:text-[30px] xl:text-[28px] md:text-[22px] sm:text-[20px] xs:text-[12px] mxl:leading-[45px] xl:leading-[40px] lg:leading-[35px] md:leading-[30px] sm:leading-[30px] xs:leading-[20px]">
                Corporate Social Responsibility (CSR) refers to a business model in which
                companies incorporate social and environmental concerns into their operations
                and interactions with stakeholders. CSR goes beyond legal compliance and aims
                to foster a positive impact on society while maintaining profitability. This
                approach encourages businesses to consider the broader consequences of their
                actions and strive to improve their community and environmental footprint.
              </p>
            </div>
          </Row>
        </Container>
      </m.section>
      {/* Section End */}

      {/* Section Start */}
      <section className="mxl:mt-[100px] xl:mt-[100px] lg:mt-[100px] md:mt-[50px] sm:mt-[40px] xs:mt-[30px]">
        <Container>
          <Row className="justify-center">
            <m.div className="col-12 col-md-6 bg-lightgray bg-no-repeat bg-right-bottom px-[70px] md:px-[40px] xxl:py-[60px] mxl:py-[60px] xl:py-[50px] lg:py-[50px] md:py-[30px] xs:py-10 lg:px-[5.5rem]" {...{ ...fadeIn, transition: { delay: 0.1 } }} >
              <Blockquote
                theme="blockquote-style-04-02"
                subtitle="CSR: NOT JUST A BUZZWORD"
                style={{
                  '--subtitle-color': "text-[#499795]"
                }}
                subtitleextra="Relevance of CSR"
                content="
                For large corporations, CSR is not just a buzzword 
                but a critical component of their strategy. Embracing 
                CSR can lead to numerous benefits, such as enhanced 
                reputation, reduced risks, and increased employee 
                engagement. As consumers become more informed and 
                selective about the brands they support, companies 
                that prioritize CSR are better positioned to build 
                trust and loyalty. This, in turn, can drive long-term 
                profitability and sustainability."
              />
            </m.div>
            <m.div className="col col-md-6 bg-cover bg-center sm:h-[450px] xs:h-[300px]" style={{ backgroundImage: "url(/assets/img/blog/buzzword.jpg)" }} {...{ ...fadeIn, transition: { delay: 0.3 } }}></m.div>
          </Row>
        </Container>
      </section>
      {/* Seaction End */}

      {/* Section Start */}
      <m.section className="justify-center bg-white text-stroke overflow-hidden mxl:mt-[150px] xl:mt-[150px] lg:mt-[100px] md:mt-[50px] xs:mt-[40px]">
        <Container fluid>
          <h2
            className="bg-gradient-to-r font-serif title font-bold text-center uppercase mxl:text-[130px] xl:text-[80px] lg:text-[70px] md:text-[50px] sm:text-[35px] xs:text-[25px] mxl:leading-[100px] xl:leading-[100px] lg:leading-[100px] md:leading-0 xs:leading-[50px] -tracking-[-1px] xs:-tracking-[-1px]"
            style={{
              backgroundImage: "url(/assets/img/blog/leafBg.jpg)",
              backgroundClip: "text",
              WebkitTextFillColor: "transparent",
            }}>
            WHY CSR MATTERS
          </h2>
        </Container>
      </m.section>
      {/* Section End */}

      {/* Section Start */}
      <m.section className="bg-white mxl:mt-[50px] xl:mt-[50px] lg:mt-[50px] md:mt-[25px] xs:mt-[0px]">
        <Container>
          <Row>
            <Col className="justify-center">
              <Col>
                <div className="bg-white">
                  <p className="text-justify font-light text-[#0F3443] mxl:mb-[50px] xl:mb-[50px] lg:mb-[50px] md:mb-[25px] xs:mb-[20px] xxl:leading-[45px] mxl:text-[30px] xl:text-[28px] md:text-[22px] sm:text-[20px] xs:text-[12px] mxl:leading-[45px] xl:leading-[40px] lg:leading-[35px] md:leading-[30px] sm:leading-[30px] xs:leading-[20px]">
                    At its core, CSR is rooted in the ethical obligation of
                    businesses to contribute positively to society. Companies
                    with significant influence and resources, which can be
                    leveraged to address social issues and mitigate environmental
                    harm. By adopting CSR practices, businesses acknowledge
                    their role in fostering a fair and sustainable world,
                    demonstrating a commitment to values that go beyond profit.
                  </p>
                </div>
                <div className="bg-white mxl:mb-[50px] xl:mb-[50px] lg:mb-[50px] md:mb-[25px] xs:mb-[20px]">
                  <p className="text-justify font-light text-[#0F3443] mxl:text-[30px] xl:text-[28px] md:text-[22px] sm:text-[20px] xs:text-[12px] mxl:leading-[45px] xl:leading-[40px] lg:leading-[35px] md:leading-[30px] sm:leading-[30px] xs:leading-[20px]">
                    In an age where information is readily accessible, a company's
                    reputation can be its most valuable asset. CSR initiatives
                    can significantly enhance a company's brand image by showcasing
                    its dedication to ethical practices and social responsibility.
                    Consumers are more likely to trust and support brands that align
                    with their values, making CSR a powerful tool for building customer
                    loyalty and differentiation in a competitive market.
                  </p>
                </div>
                <div className="bg-white mxl:mb-[50px] xl:mb-[50px] lg:mb-[50px] md:mb-[25px] xs:mb-[20px]">
                  <p className="font-light text-[#0F3443] text-justify mxl:text-[30px] xl:text-[28px] md:text-[22px] sm:text-[20px] xs:text-[12px] mxl:leading-[45px] xl:leading-[40px] lg:leading-[35px] md:leading-[30px] sm:leading-[30px] xs:leading-[20px] ">
                    CSR also serves as a proactive approach to managing risks.
                    Companies that prioritize ethical behavior and transparency are
                    less likely to face legal issues, regulatory fines, or public
                    relations crises. By addressing potential problems before they
                    escalate, businesses can protect their reputation and avoid
                    costly repercussions. Additionally, strong CSR practices can
                    help companies navigate complex regulatory environments and
                    meet the growing demands of socially conscious investors.
                  </p>
                </div>
                <div className="bg-white">
                  <p className="font-regular text-[#0F3443] text-justify mxl:text-[30px] xl:text-[28px] md:text-[22px] sm:text-[20px] xs:text-[12px] mxl:leading-[45px] xl:leading-[40px] lg:leading-[35px] md:leading-[30px] sm:leading-[30px] xs:leading-[20px] border-l-[7px] border-[#428A87] xl:border-l-[7px] lg:border-l-[5px] md:border-l-[5px] xs:border-l-[2px] pl-4 pr-4">
                    A robust CSR program can significantly boost employee morale
                    and engagement. Today's workforce increasingly seeks employers
                    whose values align with their own. Companies that demonstrate a
                    commitment to social and environmental responsibility attract
                    top talent and foster a sense of pride and loyalty among employees.
                    Engaged employees are more productive, innovative, and likely to
                    stay with the company, reducing turnover and enhancing overall performance.
                  </p>
                </div>
              </Col>
            </Col>
          </Row>
        </Container>
      </m.section>
      {/* Seaction End */}

      {/* Section Start */}
      <m.section className="bg-white overflow-hidden mxl:mt-[100px] xl:mt-[100px] lg:mt-[100px] md:mt-[25px] xs:mt-[20px]">
        <Container>
          <Row className="justify-center">
            <h2 className="text-uppercase text-center font-serif font-semibold mxl:mb-[50px] xl:mb-[50px] lg:mb-[50px] md:mb-[25px] xs:mb-[20px] tracking-[3px] text-[#0F3443] xxl:text-[50px] mxl:text-[50px] xl:text-[50px] lg:text-[45px] md:text-[32px] xs:text-[20px] xs:tracking-[1px] xs:leading-[22px]">
              Relevance of <span className="text-gradient bg-gradient-to-tr from-[#428A87] to-[#326765] font-semibold">CSR </span>
            </h2>
            <div className="bg-white ">
              <p className="font-light text-[#0F3443] mxl:text-justify xl:text-justify mxl:text-[30px] xl:text-[28px] md:text-[22px] xs:text-[12px] xxs:text-[12px] mxl:leading-[45px] xl:leading-[40px] lg:leading-[35px] md:leading-[30px] xs:leading-[20px]">
                For large corporations, CSR is not just a buzzword but a critical
                component of their strategy. Embracing CSR can lead to numerous
                benefits, such as enhanced reputation, reduced risks, and increased
                employee engagement. As consumers become more informed and selective
                about the brands they support, companies that prioritize CSR are
                better positioned to build trust and loyalty. This, in turn, can
                drive long-term profitability and sustainability.
              </p>
            </div>
          </Row>
        </Container>
      </m.section>
      {/* Section End */}

      {/* Seaction Start */}
      <m.section
        className="bg-[#326765] overflow-hidden mxl:mt-[100px] xl:mt-[100px] lg:mt-[100px] md:mt-[50px] xs:mt-[40px] mxl:py-[100px] xl:py-[70px] lg:py-[60px] md:py-[50px] xs:py-[30px] xs:px-[5px]"
        {...fadeIn}
        style={{ backgroundImage: "url('/assets/img/webp/blog-post-layout-02-img-pattern.webp')" }}>
        <Container>
          <Row className="justify-center">
            <Col xl={6} md={8} lg={7} className="px-md-0">
              <p className="font-bold text-[#fff] tracking-[1px]  text-center font-serif xxl:text-[40px] mxl:text-[40px] xl:text-[40px] md:text-[35px] xs:text-[25px]">
                WANT TO KNOW MORE?
              </p>
              <p className="font-bold text-darkgray tracking-[1px] my-16 text-center font-serif xl:leading-[55px] xxl:text-[40px] mxl:text-[40px] xxl:leading-[55px] mxl:leading-[55px] xl:text-[40px] xs:text-[25px] xs:leading-[30px]">
                REACH OUT TO OUR BIODIVERSITY EXPERTS TODAY
              </p>
              <Link
                className="uppercase inner-link nav-link font-bold text-[#fff] tracking-[1px]  text-center font-serif xxl:text-[40px] mxl:text-[40px] xl:text-[40px] md:text-[35px] xs:text-[25px]"
                to={'/contact'}>Click Here!
              </Link>
            </Col>
          </Row>
        </Container>
      </m.section>
      {/* Seaction End */}

      {/* Section Start */}
      <m.section className="bg-white overflow-hidden mxl:mt-[100px] xl:mt-[100px] lg:mt-[100px] md:mt-[50px] sm:mt-[40px] xs:mt-[40px]">
        <Container>
          <Row className="justify-center">
            <h2 className="text-uppercase text-[#0F3443] text-center font-serif font-semibold tracking-[3px] mxl:text-[50px] xl:text-[50px] lg:text-[45px] md:text-[32px] xs:text-[15px] xs:tracking-[1px] xs:leading-[22px]">
              Key Components of <span className="text-gradient bg-gradient-to-tr from-[#428A87] to-[#326765] font-semibold">CSR </span>
            </h2>
          </Row>
        </Container>
      </m.section>
      {/* Section End */}

      {/* Section Start */}
      <m.section className="mxl:mt-[50px] xl:mt-[50px] lg:mt-[50px] md:mt-[25px] xs:mt-[20px]">
        <Container>
          <Tab05 data={TabData05} />
        </Container>
      </m.section>
      {/* Section End */}

      {/* Section Start */}
      <m.section className="justify-center bg-white overflow-hidden mxl:mt-[150px] xl:mt-[150px] lg:mt-[100px] md:mt-[50px] xs:mt-[20px]">
        <Container fluid>
          <h2
            className="bg-gradient-to-r font-serif title font-bold text-center uppercase mxl:text-[130px] xl:text-[80px] lg:text-[70px] md:text-[50px] xs:text-[20px] mxl:leading-[100px] xl:leading-[100px] lg:leading-[100px] md:leading-[130px] xs:leading-[50px] -tracking-[-1px] xs:-tracking-[-1px]"
            style={{
              backgroundImage: "url(/assets/img/blog/leafBg.jpg)",
              backgroundClip: "text",
              WebkitTextFillColor: "transparent",
            }}>
            The Triple Bottom Line
          </h2>
        </Container>
      </m.section>
      {/* Section End */}

      {/* Section Start */}
      <m.section className="bg-white mxl:mt-[50px] xl:mt-[50px] lg:mt-[50px] md:mt-[25px] xs:mt-[0px]">
        <Container>
          <Row className="justify-center">
            <div className="bg-white">
              <p className="text-justify font-light text-[#0F3443] mxl:text-[30px] xl:text-[28px] md:text-[22px] sm:text-[20px] xs:text-[12px] mxl:leading-[45px] xl:leading-[40px] lg:leading-[35px] md:leading-[30px] sm:leading-[30px] xs:leading-[20px]">
                The triple bottom line (TBL) is a framework that encourages businesses
                to focus on three key areas: People, Planet, and Profit. This approach
                goes beyond traditional financial metrics to include social and
                environmental performance, providing a more comprehensive view of a
                company's impact.
              </p>
            </div>
          </Row>
        </Container>
      </m.section>
      {/* Seaction End */}

      {/* Section Start */}
      <m.section className="bg-white overflow-hidden mxl:mt-[50px] xl:mt-[50px] lg:mt-[50px] md:mt-[25px] xs:mt-[20px]">
        <Container>
          <Row className="justify-center">
            <h2 className="text-uppercase text-center font-serif font-semibold mxl:mb-[50px] xl:mb-[50px] lg:mb-[50px] md:mb-[25px] xs:mb-[20px] tracking-[3px] text-[#0F3443] mxl:text-[50px] xl:text-[50px] lg:text-[45px] md:text-[32px] xs:text-[20px] xs:tracking-[1px] xs:leading-[22px]">
              Integration with <span className="font-semibold text-gradient bg-gradient-to-tr from-[#428A87] to-[#326765]">CSR </span>
            </h2>
            <Col className="justify-center">
              <Col>
                <div className="bg-white">
                  <p className="text-justify font-light text-[#0F3443] mxl:text-[30px] xl:text-[28px] md:text-[22px] sm:text-[20px] xs:text-[12px] mxl:leading-[45px] xl:leading-[40px] lg:leading-[35px] md:leading-[30px] sm:leading-[30px] xs:leading-[20px]">
                    By focusing on the triple bottom line, companies can achieve long-term
                    success and sustainability. Integrating People, Planet, and Profit into
                    their business models ensures that companies are not only financially
                    successful but also socially responsible and environmentally sustainable.
                    This holistic approach helps businesses build a positive reputation,
                    attract and retain top talent, and create lasting value for all stakeholders.
                  </p>
                </div>
              </Col>
            </Col>
          </Row>
        </Container>
      </m.section>
      {/* Section End */}

      {/* Section Start */}
      <m.section className="justify-center bg-white overflow-hidden mxl:mt-[150px] xl:mt-[150px] lg:mt-[100px] md:mt-[50px] xs:mt-[20px]">
        <Container fluid>
          <h2
            className="bg-gradient-to-r font-serif title font-bold text-center uppercase mxl:text-[130px] xl:text-[80px] lg:text-[70px] md:text-[50px] sm:text-[40px] xs:text-[20px] mxl:leading-[100px] xl:leading-[90px] lg:leading-[80px] md:leading-[60px] xs:leading-[30px] -tracking-[-1px] xs:-tracking-[-1px]"
            style={{
              backgroundImage: "url(/assets/img/blog/leafBg.jpg)",
              backgroundClip: "text",
              WebkitTextFillColor: "transparent",
            }}>
            Implementing CSR <br></br>in Your Business
          </h2>
        </Container>
      </m.section>
      {/* Section End */}

      {/* Section Start */}
      <section className="justify-center bg-white overflow-hidden mxl:mt-[50px] xl:mt-[50px] lg:mt-[50px] md:mt-[25px] sm:mt-[40px] xs:mt-[0px]">
        <Container>
          <IconWithText
            grid="row-cols-1 row-cols-lg-4 row-cols-sm-2 text-center gap-y-10"
            theme="icon-with-text-03"
            data={IconWithTextData_03}
            animation={fadeIn}
            animationDelay={0.2}
          />
        </Container>
      </section>
      {/* Section End */}

      {/* Section Start */}
      <m.section className="bg-white overflow-hidden mxl:mt-[50px] xl:mt-[50px] lg:mt-[50px] md:mt-[50px] xs:mt-[20px]">
        <Container>
          <Row className="justify-center">
            <h3 className="text-[#0F3443] text-uppercase mxl:mb-[50px] xl:mb-[50px] lg:mb-[50px] md:mb-[25px] xs:mb-[20px] text-center font-serif font-semibold tracking-[3px] mxl:text-[50px] xl:text-[50px] lg:text-[45px] md:text-[32px] xs:text-[20px] xs:tracking-[1px] xs:leading-[30px]">
              Conduct a <br></br><span className="text-gradient bg-gradient-to-tr from-[#428A87] to-[#326765] font-semibold">Double Materiality </span><br></br> Assessment
            </h3>
              <Col>
                <div className="bg-white">
                  <p className="text-justify font-light text-[#0F3443] mxl:mb-[50px] xl:mb-[50px] lg:mb-[50px] md:mb-[25px] xs:mb-[20px] mxl:text-[30px] xl:text-[28px] md:text-[22px] sm:text-[20px] xs:text-[12px] mxl:leading-[45px] xl:leading-[40px] lg:leading-[35px] md:leading-[30px] sm:leading-[30px] xs:leading-[20px]">
                    From 2024 onwards, large and listed companies will be required to adhere
                    to sustainability reporting standards that include double materiality
                    assessments. This essential component of implementing CSR evaluates
                    your operations' impact from two angles: financial materiality, which
                    examines how sustainability issues affect your company's financial
                    performance, and environmental/social materiality, which assesses
                    how your activities impact the environment and society.
                  </p>
                </div>
                <div className="bg-white">
                  <p className="text-justify font-light text-[#0F3443] mxl:mb-[50px] xl:mb-[50px] lg:mb-[50px] md:mb-[25px] xs:mb-[20px] mxl:text-[30px] xl:text-[28px] md:text-[22px] sm:text-[20px] xs:text-[12px] mxl:leading-[45px] xl:leading-[40px] lg:leading-[35px] md:leading-[30px] sm:leading-[30px] xs:leading-[20px]">
                    These legal requirements mandate that covered companies perform a
                    double materiality assessment to determine the necessary information
                    for their sustainability reports. This ensures businesses provide
                    comprehensive and relevant data, addressing both financial implications
                    and broader social and environmental impacts.
                  </p>
                </div>
                <div className="bg-white">
                  <p className="text-justify font-light text-[#0F3443] mxl:text-[30px] xl:text-[28px] md:text-[22px] sm:text-[20px] xs:text-[12px] mxl:leading-[45px] xl:leading-[40px] lg:leading-[35px] md:leading-[30px] sm:leading-[30px] xs:leading-[20px]">
                    Incorporating double materiality assessments into your CSR strategy
                    helps identify and manage risks and opportunities more effectively,
                    fostering long-term value creation and accountability. This approach
                    not only complies with regulatory requirements but also enhances
                    transparency and trust with stakeholders.
                  </p>
                </div>
              </Col>
          </Row>
        </Container>
      </m.section>
      {/* Section End */}


      {/* Section Start */}
      <m.section className="justify-center bg-white overflow-hidden mxl:mt-[100px] xl:mt-[100px] lg:mt-[100px] md:mt-[50px] xs:mt-[20px]">
        <Container fluid>
          <h2
            className="bg-gradient-to-r font-serif title font-bold text-center uppercase mxl:text-[130px] xl:text-[80px] lg:text-[70px] md:text-[50px] sm:text-[35px] xs:text-[20px] mxl:leading-[100px] xl:leading-[80px] lg:leading-[80px] md:leading-[60px] sm:leading-[100px] xs:leading-[50px] -tracking-[-1px] xs:-tracking-[-1px]"
            style={{
              backgroundImage: "url(/assets/img/blog/leafBg.jpg)",
              backgroundClip: "text",
              WebkitTextFillColor: "transparent",
            }}>
            Measuring
            CSR Impact
          </h2>
        </Container>
      </m.section>
      {/* Section End */}

      {/* Section Start */}
      <m.section className="bg-white overflow-hidden mxl:mt-[50px] xl:mt-[50px] lg:mt-[50px] md:mt-[25px] sm:mt-[40px] xs:mt-[0px]">
        <Container>
          <Row className="justify-center">
            <Col className="justify-center">
              <Col>
                <div className="bg-white">
                  <p className="text-justify font-light text-[#0F3443] mxl:mb-[50px] xl:mb-[50px] lg:mb-[40px] md:mb-[30px] xs:mb-[20px] mxl:text-[30px] xl:text-[28px] md:text-[22px] sm:text-[20px] xs:text-[12px] mxl:leading-[45px] xl:leading-[40px] lg:leading-[35px] md:leading-[30px] xs:leading-[20px]">
                    Establish metrics to track your progress and measure the impact of
                    your CSR initiatives. This can include metrics such as energy use,
                    waste management, community engagement, biodiversity monitoring and
                    employee diversity. Regular reporting and transparency are crucial
                    for maintaining accountability and demonstrating progress to stakeholders.
                  </p>
                </div>
                <div className="bg-white">
                  <p className="text-justify font-light text-[#0F3443] mxl:text-[30px] xl:text-[28px] md:text-[22px] sm:text-[20px] xs:text-[12px] mxl:leading-[45px] xl:leading-[40px] lg:leading-[35px] md:leading-[30px] sm:leading-[30px] xs:leading-[20px]">
                    Regularly review and adjust your CSR strategies to enhance their
                    effectiveness. Hold periodic meetings to monitor progress, recognize
                    successes, identify shortfalls, and make necessary adjustments.
                    Continuous improvement ensures that your CSR efforts remain relevant
                    and impactful.
                  </p>
                </div>
              </Col>
            </Col>
          </Row>
        </Container>
      </m.section>
      {/* Section End */}

      {/* Section Start */}
      <m.section className="bg-white overflow-hidden mxl:mt-[50px] xl:mt-[50px] lg:mt-[50px] md:mt-[25px] sm:mt-[40px] xs:mt-[20px]">
        <Container>
          <Row className="justify-center">
            <h3 className="text-uppercase text-center font-serif font-semibold mxl:mb-[50px] xl:mb-[50px] lg:mb-[50px] md:mb-[25px] xs:mb-[20px] tracking-[3px] text-[#0F3443] mxl:text-[50px] xl:text-[50px] lg:text-[45px] md:text-[32px] xs:text-[18px] xs:tracking-[1px] xs:leading-[22px]">
              <span className="text-gradient bg-gradient-to-tr from-[#428A87] to-[#326765] font-semibold">Challenges </span>& <span className="text-gradient bg-gradient-to-tr from-[#428A87] to-[#326765] font-semibold">Solutions </span>
            </h3>
            <Col className="justify-center">
              <Col>
                <div className="bg-white">
                  <p className="text-justify font-light text-[#0F3443] mxl:mb-[50px] xl:mb-[50px] lg:mb-[50px] md:mb-[30px] xs:mb-[20px] mxl:text-[30px] xl:text-[28px] md:text-[22px] sm:text-[20px] xs:text-[12px] mxl:leading-[45px] xl:leading-[40px] lg:leading-[35px] md:leading-[30px] sm:leading-[30px] xs:leading-[20px]">
                    Implementing CSR can present challenges such as high initial costs,
                    balancing profit with social goals, and ensuring stakeholder engagement.
                    These obstacles can be daunting, but they are not insurmountable.
                  </p>
                </div>
                <div className="bg-white">
                  <p className="text-justify font-light text-[#0F3443] mxl:text-[30px] xl:text-[28px] md:text-[22px] sm:text-[20px] xs:text-[12px] mxl:leading-[45px] xl:leading-[40px] lg:leading-[35px] md:leading-[30px] sm:leading-[30px] xs:leading-[20px]">
                    Practical solutions include starting with small, manageable initiatives,
                    engaging stakeholders early and often, and leveraging existing resources
                    and partnerships. By addressing challenges proactively, companies can
                    successfully integrate CSR into their core business model and achieve
                    lasting benefits.
                  </p>
                </div>
              </Col>
            </Col>
          </Row>
        </Container>
      </m.section>
      {/* Section End */}

      {/* Section Start */}
      <m.section className="bg-white  mxl:mt-[100px] xl:mt-[150px] lg:mt-[50px] md:mt-[50px] sm:mt-[40px] xs:mt-[20px]">
        <Container>
          <Row className="row-cols-1 justify-center">
            <Col xl={8} lg={10} className="text-center">
              <div className=" mxl:w-[100px] xl:w-[100px] lg:w-[100px] md:w-[90px] xs:w-[60px] xxl:h-[4px] mxl:h-[4px] xl:h-[4px] lg:h-[4px] md:h-[4px] xs:h-[2px]  bg-[#326765] transform rotate-90 xxl:mb-[70px] mxl:mb-[70px] xl:mt-[-40px] xl:mb-[70px] lg:my-[70px] md:my-[60px] xs:my-[30px] inline-block">
              </div>
              <p className="font-serif font-medium xs:font-regular text-[#0F3443] mxl:text-[50px] xl:text-[45px] lg:text-[45px] md:text-[40px] xs:text-[20px] mxl:leading-[65px] xl:leading-[65px] lg:leading-[55px] md:leading-[55px] xs:leading-[30px] -tracking-[1px]">
                By adopting CSR practices, companies can build <span className="text-gradient bg-gradient-to-tr from-[#428A87] to-[#326765] font-semibold">
                  <br></br>a better future </span><br></br>for all stakeholders & ensure <span className="text-gradient bg-gradient-to-tr from-[#428A87] to-[#326765] font-semibold">
                  <br></br>their own long-term success. </span></p>
            </Col>
          </Row>
        </Container>
      </m.section>
      {/* Section End */}

      <m.section className=" mxl:my-[300px] xl:my-[300px] lg:my-[300px] md:py-[200px] sm:pt-[200px] xs:mt-[100px] xs:mb-[-100px]">
        <Container>
          <Row className="">
            <Col className="mb-[2%]">
              <p className="font-serif text-dark text-left text-[15px] font-light mb-[-150px] lg:mb-[15px] xs:mb-[-20px]">Sources</p>
            </Col>
          </Row>
          <Row>
            <div className=" p-[10px] sm:p-[20px] text-[15px] xs:text-[5px] xs:leading-[10px]">
              <li>
                https://www.bdc.ca/en/articles-tools/entrepreneur-toolkit/templates-business-guides/glossary/corporate-social-responsibility
              </li>
              <li>
                https://www.investopedia.com/terms/c/corp-social-responsibility.asp
              </li>
              <li>
                https://www.unido.org/our-focus/advancing-economic-competitiveness/competitive-trade-capacities-and-corporate-responsibility/corporate-social-responsibility-market-integration/what-csr
              </li>
              <li>
                https://www.ibm.com/topics/corporate-social-responsibility
              </li>
            </div>
          </Row>
        </Container>
      </m.section>
    </div>


  )
}

export default memo(CorporateSus)


