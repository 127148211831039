import React, {useEffect, useState, memo} from 'react'

import {Col, Container, Row} from 'react-bootstrap'
import {m} from 'framer-motion';
import {fadeIn} from '../../../../Functions/GlobalAnimations'
import FaunaFooter from "../../Components/FaunaFooter"
import FaunaHeaderForOtherPage from "../../Components/FaunaHeaderForOtherPage"
import FaunaHeader from '../../Components/FaunaHeader'


const Home = (props) => {
    const app_site = 'https://app.faunaphotonics.com'
    const [items, setItems] = useState([])

    useEffect(() => {
        fetch(app_site + '/api/privacy')
            .then((res) => res.json())
            .then((resJson) => {
                setItems(resJson)
            })
    }, [])

    return (
        <>
            <div
                id="top"
                // className="bg-white"
                style={props.style}>

                <FaunaHeaderForOtherPage />

                <m.section className="py-[160px] lg:py-[120px] md:py-[95px] sm:py-[80px] xs;py-[50px]" {...fadeIn}>
                    <Container>
                        <Row>
                            <Col className="mb-[4%]">
                                <h3 className="font-bold text-dark text-uppercase text-center mb-[25px] lg:mb-[15px]">Privacy</h3>
                            </Col>
                        </Row>
                        <Row>
                            <Col className="justify-center">
                                <Col>
                                    <div style={{borderRadius: '18px'}} className="bg-lightgray p-[6rem] sm:px-[4rem]">
                                        <ol
                                            className={'font-bold'}
                                            style={{listStyle: 'auto', fontSize: '2rem'}}>
                                            {!!Object.keys(items).length ? Object.keys(items).map((header, hi) => {
                                                return (<li key={hi}>
                                                    <div className={'font-bold my-4'}>{header}</div>
                                                    <ol
                                                        className={'font-normal'}
                                                        style={{
                                                            listStyle: 'auto',
                                                            fontSize: '1.2rem',
                                                            lineHeight: '2.5rem'
                                                        }}>
                                                        {items[header].map((paragraph, pi) => {
                                                            return (<li key={pi}>{paragraph}</li>)
                                                        })}
                                                    </ol>
                                                </li>)
                                            }) : <span>Loading</span>}
                                        </ol>
                                    </div>
                                </Col>
                            </Col>
                        </Row>
                    </Container>
                </m.section>


                <FaunaFooter/>
            </div>
        </>
    )
}


export default memo(Home)
