import React, { memo, useState } from 'react'
import { Col, Container, Row } from "react-bootstrap"
import Buttons from "../../../Components/Button/Buttons"
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, EffectFade } from "swiper";
import { Link as ScrollTo } from "react-scroll"




const SwiperData = [
  {
    img: "/assets/img/webp/planthopperHero.jpg",

  },
  {
    img: "/assets/img/webp/butterflyHero.jpg",

  },
  {
    img: "/assets/img/webp/bumbleHero.jpg",

  },
  {
    img: "/assets/img/webp/vespidHero.jpg",

  },
  {
    img: "/assets/img/webp/rhinoHero.jpg",

  },
  {
    img: "/assets/img/webp/flyHero.jpg",

  },
  {
    img: "/assets/img/webp/dragonHero.jpg",

  },
  {
    img: "/assets/img/webp/skipperHero",

  },
  {
    img: "/assets/img/webp/checkeredHero.jpg",

  },
]
const FaunaHero = () => {
  const [activeSlide, setActiveSlide] = useState(0)

  return (

    <div className="bg-white">


      {/* // <section className="full-screen xxl:h-[900px] mxl:h-[800px] xl:h-[850px] lg:h-[800px] md:h-[700px] sm:h-[200px] xs:h-[200px] xxs:h-[200px]">
    // <section className="full-screen h-full mxl:h-[1000px] mt-[0px] mb-[0px] pb-[400px]"> */}
      {/* //   className=" xxl:mb-[-650px] mxl:mb-[-500px] xl:mb-[-500px] lg:mb-[-500px] md:mb-[-500px] xs:mb-[-400px] swiper-pagination-light xxl:h-[900px] mxl:h-[900px] xs:h-[100px] xl:h-[860px] relative architecture-slider min-h-[-webkit-fill-available]" */}
      {/* // TURN OFF IF ONLY ONE HERO IMAGE */}

      {/* <section className="bg-white architecture-slider-wrapper full-screen min-h-[-webkit-fill-available] relative overflow-hidden w-full xs:w-[300px] h-full xs:h-[200px] sm:h-[350px] md:h-[450px] xl:h-[800px]">
  
        <Swiper
            className=" swiper-pagination-light relative min-h-[-webkit-fill-available] mxl:h-[950px] xl:h-[1000px] lg:h-[800px] md:h-[0px] sm:h-[0px] xs:h-[0px] "
              slidesPerView={1}
              loop={true}
              modules={[
                EffectFade, 
                // Navigation, 
                Autoplay]}
              autoplay={{ delay: 8000, disableOnInteraction: false }}
            //   navigation={true}
              effect="fade"
              speed={2000}
              onSlideChange={(swiperCore) => {
                const { realIndex } = swiperCore;
                setActiveSlide(realIndex)
              }}>
            {
                SwiperData.map((item, i) => {
                    return (
                        <SwiperSlide
                            key={i}
                            style={{ backgroundImage: `url(${item.img})` }}
                            className="bg-no-repeat bg-cover overflow-hidden relative bg-center xs:w-[50%]">
                            <div className="absolute h-full w-full top-0 left-0"></div>
                            <Container className="h-full">
                                <Row className="h-full ">
                                    <Col
                                        mxl={8}
                                        xl={8}
                                        lg={7}
                                        sm={8}
                                        xs={12}
                                        className="h-full text-[#0F3443] font-serif flex-col relative mt-[100px] mxl:mt-[200px] md:mt-[35px] sm:mt-[20px] xs:mt-[8px] ml-[0px] mxl:ml-[-300px] xl:ml-[0px] lg:ml-[70px] md:ml-[10px] sm:ml-[0px] xs:ml-[10px]">
                                        <h1 className="font-semibold mxl:text-[60px] xl:text-[65px] lg:text-[40px] md:text-[30px] sm:text-[22px] xs:text-[15px] mxl:leading-[80px] xl:leading-[80px] lg:leading-[55px] md:leading-[30px] sm:leading-[30px] xs:leading-[18px]">
                                        Making Biodiversity
                                        <br></br> 
                                        Simple & Measurable
                                        </h1>
                                        <p className="mxl:mt-[40px] xl:mt-[30px] lg:mt-[-10px] md:mt-[10px] sm:mt-[-10px] xs:mt-[-12px] mr-[0px] lg:mr-[100px] sm:mr-[-10px] xs:mr-[130px] font-light mxl:leading-[45px] xl:leading-[40px] lg:leading-[30px] md:leading-[25px] sm:leading-[20px] xs:leading-[8px] mxl:text-[35px] xl:text-[30px] lg:text-[22px] md:text-[18px]  sm:text-[12px] xs:text-[8px]">
                                        Utilizing the capabilities of insects as natural 
                                        indicators ensures precise measurements and valuable 
                                        insights for making important sustainability decisions.
                                        </p>
                                        <div className="inline-block py-[50px] lg:py-[30px] md:py-[20px] xs:py-[10px] ">
                                            <ScrollTo
                                                to="solutions"
                                                offset={0}
                                                delay={0}
                                                smooth={true}
                                                duration={800}
                                                className="inner-link nav-link">
                                                <Buttons
                                                ariaLabel="button"
                                                href="#"
                                                className="font-semibold uppercase rounded-[2rem] border-0"
                                                themeColor={["#0F3443", "#1A5B74"]}
                                                size="md"
                                                color="#fff"
                                                title="Our Solutions"
                                                /></ScrollTo>
                                        </div>
                                    </Col>
                                </Row>
                            </Container>
                        </SwiperSlide>
                    )
                })
            }
        </Swiper>
    </section> */}

      <section className="bg-white architecture-slider-wrapper relative w-full">

        <Swiper
          className="swiper-pagination-light relative min-h-[-webkit-fill-available]"
          slidesPerView={1}
          loop={true}
          modules={[
            EffectFade,
            // Navigation,
            Autoplay
          ]}
          autoplay={{ delay: 8000, disableOnInteraction: false }}
          // navigation={true}
          effect="fade"
          speed={2000}
          onSlideChange={(swiperCore) => {
            const { realIndex } = swiperCore;
            setActiveSlide(realIndex)
          }}
        >
          {
            SwiperData.map((item, i) => {
              return (
                <SwiperSlide
                  key={i}
                  style={{ backgroundImage: `url(${item.img})`, objectFit: 'cover' }}
                  className="bg-no-repeat bg-cover overflow-hidden relative bg-center h-full xs:h-[200px]">
                  <div className="absolute h-full w-full top-0 left-0"></div>
                  <Container className="h-full">
                    <Row className="mxl:pb-[500px] xl:pb-[300px] lg:pb-[250px] md:pb-[150px] sm:pb-[90px] xs:pb-[0px]">
                      <h1 className="font-semibold text-[#0F3443] mxl:mt-[100px] xl:mt-[100px] lg:mt-[95px] md:mt-[20px] sm:mt-[20px] xs:mt-[12px] mxl:text-[60px] xl:text-[50px] lg:text-[45px] md:text-[38px] sm:text-[25px] xs:text-[15px] mxl:leading-[75px] xl:leading-[50px] lg:leading-[50px] md:leading-[40px] sm:leading-[28px] xs:leading-[15px]">
                        Making Biodiversity
                        <br></br>
                        Simple & Measurable
                      </h1>
                      <p className="font-light text-[#0F3443] md:mt-[-5px] sm:mt-[-10px] xs:mt-[-10px] mxl:text-[40px] xl:text-[30px] lg:text-[25px] md:text-[15px] sm:text-[15px] xs:text-[8px] mxl:leading-[50px] xl:leading-[35px] lg:leading-[30px] md:leading-[20px] sm:leading-[18px] xs:leading-[10px]">
                        Utilizing the capabilities of insects <br></br>
                        as natural indicators ensures <br></br>
                        precise measurements & valuable insights <br></br>
                        for making important sustainability decisions.
                      </p>
                      <div className="inline-block mxl:mt-[50px] xl:mt-[50px] lg:mt-[30px] md:mt-[30px] sm:mt-[30px] xs:mt-[10px]">
                        <ScrollTo
                          to="solutions"
                          offset={0}
                          delay={0}
                          smooth={true}
                          duration={800}
                          className="inner-link nav-link">
                          <Buttons
                            ariaLabel="button"
                            href="#"
                            className="font-semibold uppercase rounded-[2rem] border-0"
                            themeColor={["#0F3443", "#1A5B74"]}
                            size="md"
                            color="#fff"
                            title="Our Solutions"
                          />
                        </ScrollTo>
                      </div>
                    </Row>
                  </Container>
                </SwiperSlide>
              )
            })
          }
        </Swiper>
      </section>
    </div>

  )
}

export default memo(FaunaHero)