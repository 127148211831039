import React, { memo, useState  } from 'react'
// Libraries
import { Col, Container, Row } from 'react-bootstrap'
import { m } from "framer-motion"


// Components

import { Parallax } from "react-scroll-parallax";
import { fadeIn } from '../../../Functions/GlobalAnimations'
import { Link } from 'react-router-dom'
import { TiltBox } from '../../../Components/FancyText/FancyText'
import Overlap from '../../../Components/Overlap/Overlap'




const SwiperData = [
    {
        img: "/assets/img/webp/heroBumblebee.webp",
        // title: "Making Biodiversity Simple & Measurable",
        // subtitle: "Utilizing the capabilities of insects as natural indicators ensures precise measurements and valuable insights for making important sustainability decisions.",
    },
    {
        img: "/assets/img/webp/heroPlanthopper.webp",
        // title: "Making Biodiversity Simple & Measurable",
        // subtitle: "Utilizing the capabilities of insects as natural indicators ensures precise measurements and valuable insights for making important sustainability decisions.",
    },
    {
        img: "/assets/img/webp/heroButterfly.webp",
        // title: "Making Biodiversity Simple & Measurable",
        // subtitle: "Utilizing the capabilities of insects as natural indicators ensures precise measurements and valuable insights for making important sustainability decisions.",
    },
    {
        img: "/assets/img/webp/heroVespid.webp",
        // title: "Making Biodiversity Simple & Measurable",
        // subtitle: "Utilizing the capabilities of insects as natural indicators ensures precise measurements and valuable insights for making important sustainability decisions.",
    },
    {
        img: "/assets/img/webp/heroRhino.webp",
        // title: "Making Biodiversity Simple & Measurable",
        // subtitle: "Utilizing the capabilities of insects as natural indicators ensures precise measurements and valuable insights for making important sustainability decisions.",
    },
    {
        img: "/assets/img/webp/heroFly.webp",
        // title: "Making Biodiversity Simple & Measurable",
        // subtitle: "Utilizing the capabilities of insects as natural indicators ensures precise measurements and valuable insights for making important sustainability decisions.",
    },
    {
        img: "/assets/img/webp/heroDragon.webp",
        // title: "Making Biodiversity Simple & Measurable",
        // subtitle: "Utilizing the capabilities of insects as natural indicators ensures precise measurements and valuable insights for making important sustainability decisions. ",
    },
    {
        img: "/assets/img/webp/heroSkipper.webp",
        // title: "Making Biodiversity Simple & Measurable",
        // subtitle: "Utilizing the capabilities of insects as natural indicators ensures precise measurements and valuable insights for making important sustainability decisions.",
    },
    {
        img: "/assets/img/webp/heroCheckered.webp",
        // title: "Making Biodiversity Simple & Measurable",
        // subtitle: "Utilizing the capabilities of insects as natural indicators ensures precise measurements and valuable insights for making important sustainability decisions.",
    },
]

const FaunaHeroKnowledge = () => {
    const [activeSlide, setActiveSlide] = useState(0)

    return (
    
    <section className="bg-white">

<section className=" w-full xxl:h-[600px] mxl:h-[500px] xl:h-[300px] xs:h-[100px] cover-background relative"
        style={{ backgroundImage: "url('/assets/img/blog/biodiversityBg.jpeg')" }}
        >
      </section>

      {/* Section Start */}
      {/* <section className=" bg-white pb-0 sm:py-[50px] xs:overflow-hidden xs:mt-[-100px] mxl:mt-[-35px]">
        <Container className="xs:p-0">
          <Row className="justify-center z-10">
            <Overlap className="!px-0">
              <TiltBox className="p-0">
                <Col lg={10} className="font-serif text-center bg-[#0F3443] text-white py-[7rem] xs:py-[50px] xs:px-[15px] relative mx-auto opacity-[95%] xs:pt-[-200px] xs:mb-[20px] "
                  style={{ backgroundImage: "url('/assets/img/webp/blog-post-layout-02-img-pattern.webp')" }}>
                  <div className="w-[1px] h-[90px] bg-white mx-auto absolute top-0 left-1/2 -translate-x-1/2 -translate-y-1/2 "></div>
                  <div className="uppercase text-md font-medium mb-[25px] font-serif xs:mt-[30px]">
                    <div className='text-white bg-black'></div>
                    <Link aria-label="link" to={'/solutions'} className="text-white inline-block xs:text-[12px]">Solutions</Link>
                    <span className="mx-[20px] w-[1px] h-[10px] bg-white inline-block"></span>
                    <Link aria-label="link" to={'/metrics'} className="inline-block text-white xs:text-[12px]">Metrics</Link>
                    <span className="mx-[20px] w-[1px] h-[10px] bg-white inline-block"></span>
                    <Link aria-label="link" to={'/contact'} className="inline-block text-white xs:text-[12px]">Contact</Link>
                  </div>
                  <h1 className="font-semibold w-[90%] mx-auto md:w-[70%] xs:w-[90%] text-[44px] leading-[50px] xs:text-[20px] xs:leading-[30px] xs:pt-[-40px] xs:pb-[30px] xs:mb-[-10px] xs:my-[-10px]">
                    Making Biodiversity 
                    <br></br>Simple & Transparent
                  </h1>
                  
                </Col>
              </TiltBox>
            </Overlap>
          </Row>
        </Container>
      </section> */}

      <section className=" bg-white pb-0 sm:py-[50px] xs:overflow-hidden xs:mt-[-100px] mxl:mt-[-35px] xl:mt-[30px]">
        <Container className="xs:p-0">
          <Row className="justify-center z-10">
            <Overlap className="!px-0">
            <Col lg={10} className="font-serif text-center bg-[#0F3443] text-white py-[7rem] xs:py-[50px] xs:px-[15px] relative mx-auto opacity-[95%] xs:pt-[-200px] xs:mb-[20px] "
                  style={{ backgroundImage: "url('/assets/img/webp/blog-post-layout-02-img-pattern.webp')" }}>
                  <div className="w-[1px] h-[90px] xl:h-[50px] bg-white mx-auto absolute top-0 left-1/2 -translate-x-1/2 -translate-y-1/2 "></div>
                  <div className="uppercase text-md font-medium mb-[25px] font-serif xs:mt-[30px]">
                    <div className='text-white bg-black'></div>
                    <Link aria-label="link" to={'/solutions'} className="text-white inline-block xs:text-[12px]">Solutions</Link>
                    <span className="mx-[20px] w-[1px] h-[10px] bg-white inline-block"></span>
                    <Link aria-label="link" to={'/metrics'} className="inline-block text-white xs:text-[12px]">Metrics</Link>
                    <span className="mx-[20px] w-[1px] h-[10px] bg-white inline-block"></span>
                    <Link aria-label="link" to={'/contact'} className="inline-block text-white xs:text-[12px]">Contact</Link>
                  </div>
                  <h3 className="font-semibold w-[90%] mx-auto md:w-[70%] xs:w-[90%] text-[44px] leading-[50px] xs:text-[20px] xs:leading-[30px] xs:pt-[-40px] xs:pb-[30px] xs:mb-[-10px] xs:my-[-10px]">
                    Making Biodiversity 
                    <br></br>Simple & Transparent
                  </h3>          
                </Col>
            </Overlap>
          </Row>
        </Container>
      </section>

      {/* Seaction End */}
         
     


    </section>)
}

export default memo(FaunaHeroKnowledge)