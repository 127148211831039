import React, { memo } from 'react'
import { Col, Container, Row } from "react-bootstrap"
import { m } from "framer-motion"
import { fadeInLeft } from "../../../Functions/GlobalAnimations"

const FaunaElevate = () => {
    return (<section className="bg-white overflow-hidden startup-processstep xxl:pb-[150px] mxl:pb-[150px] xxl:pt-[150px] mxl:pt-[150px] xl:pt-[100px] xs:pt-[15px]">
        <Container>
            <Row className="items-center justify-center">
                <Col
                    xl={5}
                    lg={6}
                    md={10}>
                    <Col className="xxl:pb-[10px] mxl:pb-[10px] xl:pb-[50px] xs:pb-[30px]">
                        <h2 className="heading-5 xxl:pb-[20px] mxl:pb-[20px] xxl:text-[50px] mxl:text-[60px] xxl:leading-[55px] mxl:leading-[75px] font-serif block font-bold text-gradient bg-gradient-to-r from-[#174E64] via-[#326765] to-[#91CAC8] xl:text-[45px] xl:pb-[10px] xl:leading-[55px] xl:tracking-[.5px] xl:w-[85%] xs:w-[95%] xs:text-[35px] xs:leading-[40px]">
                            Elevate <br></br> 
                            your Corporate Environmental Strategy
                        </h2>
                        <div className="xxl:pb-[65px] mxl:pb-[65px] xxl:text-[25px] mxl:text-[25px] font-serif font-light text-[#0B2732] xl:leading-[30px] xs:leading-[18px] xl:text-[25px] xs:text-[15px]">
                            with scalable and comparative biodiversity measurements
                            to disclose your nature-related risks and impact.
                        </div>
                        <div className="inline-block xl:pt-[60px] xs:pt-[30px]">
                            {/* <ScrollTo
                                to="solutions"
                                offset={0}
                                delay={0}
                                smooth={true}
                                duration={800}
                                className="inner-link nav-link"><Buttons
                                    ariaLabel="button"
                                    href="#"
                                    className="font-semibold uppercase rounded-[2rem] border-0"
                                    themeColor={["#0F3443", "#1A5B74"]}
                                    size="md"
                                    color="#fff"
                                    title="Our Solutions"
                                /></ScrollTo> */}
                        </div>
                    </Col>
                </Col>
                <m.div className="offset-xl-1 col-lg-6 " {...fadeInLeft}>
                    <div className="-mr-[30vw] relative">
                        <img
                            src="/assets/img/webp/dashboard.webp"
                            width={1109}
                            height={638}
                            className="overflow-hidden"
                            alt="" />
                    </div>
                </m.div>
            </Row>
        </Container>
    </section>
    )
}


export default memo(FaunaElevate)