const HeaderData = [
        {
            title: 'Home',
            link: '/home-startup'
        },
        {
            title: 'Solutions',
            link: '/home-business'
        },
        {
            title: 'Contact',
            link: '/home-corporate'
        },
]

export default HeaderData