import React, {memo} from 'react'
import {Container, Row, Col} from "react-bootstrap"
import {fadeIn} from "../../../Functions/GlobalAnimations"
import InfoBannerStyle01 from "../../../Components/InfoBanner/InfoBannerStyle01"

const infoData = [
    {
        img: "/assets/img/webp/habitatassessment.webp",
        title: "Habitat Assessment",
        content: "Our biodiversity specialists provide remote habitat assessments. We assess the condition of your habitat, its connectivity to surrounding landscapes and potential influential environmental variables.",
        price: "ASSESS",
        // btnName: "Booking Now ",
        // btnLink: "#"
    },
    {
        img: "/assets/img/webp/designPlanning.webp",
        title: "Design & Plan",
        content: "We collaborate with our customers to help plan and design your biodiversity monitoring program to meet the best possible data outcomes for your biodiversity objectives.",
        price: "PLAN",
        // btnName: "Booking Now ",
        // btnLink: "#"
    },
    {
        img: "/assets/img/webp/eVolito.webp",
        title: "Biodiversity Monitoring",
        content: "We help you establish, measure, and track your biodiversity targets. We collect baseline data that allows you to benchmark against natural habitats, as well as to track the performance of your biodiversity initiatives.",
        price: "MEASURE",
        // btnName: "Booking Now ",
        // btnLink: "#"
    },
    {
        img: "/assets/img/webp/biodiversityIntelligence.webp",
        title: "Biodiversity Intelligence",
        content: "Our data analytics platform combines AI and ML to deliver data insights on the state of nature at your sites, which is accessible through our biodiversity intelligence platform customer portal.",
        price: "REPORT",
        // btnName: "Booking Now ",
        // btnLink: "#"
    }
]


const FaunaSolutions = () => {

    return (<section
        id={'solutions'}
        className="bg-white">
        <Container>
            <Row className="justify-center">
                <Col
                    xl={8}
                    className="text-center mxl:mt-[0px] xl:mt-[0px] lg:mt-[0px] md:mt-[0px] sm:mt-[0px] xs:mt-[0px]">
                    <span className="font-serif font-semibold xxl:text-[30px] mxl:text-[30px] xl:text-[20px] xs:text-xxs text-[#c89965] uppercase tracking-[1px] block mb-[10px] mt-0 xs:mt-[-200px]">
                        In-field sensor technology</span>
                    <h2 className=" font-serif font-semibold uppercase text-gradient bg-gradient-to-r from-[#E29779] via-[#F2C05A] to-[#D36135] xxl:text-[50px] mxl:text-[50px] xl:text-[50px] xl:leading-[55px] xxl:pb-[50px] mxl:pb-[50px] xl:pb-[30px] xxl:leading-[65px] mxl:leading-[65px] sm:text-[35px] xs:text-[30px] sm:leading-[40px] xs:leading-[28px]">
                        A Completely Automated<br></br>
                        & Unbiased<br></br> Data Solution <br></br>
                        <span className="font-light lowercase xxl:text-[45px] mxl:text-[45px] xl:text-[45px] sm:text-[25px] xs:text-[20px] ">
                            for Biodiversity Reporting</span></h2>
                    <p className="xxl:text-[30px] mxl:text-[30px] xl:text-[30px] xxl:leading-[40px] mxl:leading-[40px] sm:text-[25px] xs:text-[14px] xs:leading-[20px] font-light xl:leading-[40px] mxl:mb-[50px] lg:mb-[30px] md:mb-[35px] xs:mb-[20px]">
                    Improve sustainability decision-making by integrating our 
                    industry-leading data solution and in-field sensor 
                    technology for continuous biodiversity monitoring and nature impact reporting.
                    </p>
                </Col>
            </Row>
            <InfoBannerStyle01
                animation={fadeIn}
                grid="row row-cols-1 row-cols-xl-4 xs:gap-y-[20px]"
                className="justify-center"
                data={infoData}/>
        </Container>
    </section>)
}

export default memo(FaunaSolutions)