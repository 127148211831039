import React, {memo} from 'react'
import {Row, Container} from 'react-bootstrap'
import {m} from "framer-motion"
import {PropTypes} from "prop-types";
import {InfoBannerData01} from './InfoBannerData'

const InfoBannerStyle01 = (props) => {
    return (
        <Row className={`${props.className ? ` ${props.className}` : ""}${props.grid ? ` ${props.grid}` : ""}`}>
            {
                props.data.map((item, i) => {
                    return (
                        <Container>
                        <Row className="justify-center">
                        <m.div
                            key={i}
                            className="justify-center col pl-0 mxl:pl-[320px] xl:pl-[340px] lg:pl-[300px] md:pl-[165px] sm:pl-[100px] xs:pl-[30px] py-4" {...{...props.animation, transition: {delay: i * props.animationDelay}}}>
                            <img
                                className="mxl:w-[500px] xl:w-[500px] lg:w-[400px] md:w-[400px] sm:w-[350px] xs:w-full mxl:h-[300px] xl:h-[300px] md:h-[250px] xs:h-[180px]"
                                width={360}
                                height={279}
                                src={item.img}
                                alt="feature specials"
                                style={{

                                    borderTopLeftRadius: '1.5rem',
                                    borderTopRightRadius: '1.5rem'
                                }}
                            />
                            <div
                                className="relative bg-white box-shadow-small p-10 w-full mxl:w-[500px] xl:w-[500px] lg:w-[400px] md:w-[400px] sm:w-[350px] xs:w-full mxl:h-[250px] xl:h-[200px] lg:h-[200px] md:h-[200px] xs:h-[250px]"
                                style={{
                                    borderBottomLeftRadius: '1.5rem',
                                    borderBottomRightRadius: '1.5rem',
                                    boxShadow: '0 10px 20px rgba(0, 0, 0, 0.25)',
                                }}>
                                <div className="bg-[#0F3443] text-[1.5rem] font-serif text-[#F2C05A] uppercase absolute font-semibold text-sm -top-[15px] right-0 py-[5px] px-[20px]">{item.price}</div>
                                <h2 className="font-serif font-medium mxl:text-lg xl:text-md xs:text-xmd text-darkgray block mb-[10px] xs:mb-[0px]">{item.title}</h2>
                                <p className="mb-[25px] xl:leading-[22px] xs:leading-[18px]">{item.content}</p>
                            </div>
                        </m.div>
                        </Row>
                        </Container>
                    )
                })
            }
        </Row>
    )
}

InfoBannerStyle01.defaultProps = {
    data: InfoBannerData01,
    animationDelay: 0.2
}

InfoBannerStyle01.propTypes = {
    className: PropTypes.string,
    data: PropTypes.arrayOf(
        PropTypes.exact({
            img: PropTypes.string,
            title: PropTypes.string,
            content: PropTypes.string,
            price: PropTypes.string,
            btnName: PropTypes.string,
            btnLink: PropTypes.string,
        })
    ),
    animation: PropTypes.object,
    animationDelay: PropTypes.number,
}


export default memo(InfoBannerStyle01)
