import React, { memo } from 'react'

// Libraries
import { Col, Tab, Tabs, Rows, Container, Row } from 'react-bootstrap'
import { PropTypes } from "prop-types";
import { m } from "framer-motion";


// Components


import Blockquote from '../../Components/Blockquote/Blockquote'
import Buttons from '../../Components/Button/Buttons'
import { fadeIn, fadeInLeft, zoomIn } from "../../Functions/GlobalAnimations";

// Data
import { TabData01 } from './TabData'





const Tab01 = (props) => {
    return (
        <Tabs className="tab-style-01 font-serif mb-[2rem] flex bg-transparent border-b-0 font-medium text-center justify-center md:mb-[60px] sm:mb-[20px] md:mt-[-50px] xs:mt-[-100px]">
            {
                props.data.map((item, i) => {
                    return (
                        <Tab className="tab-style-01" key={i} eventKey={i} title={item.tabTitle ? item.tabTitle : "Tab Title "}>
                            <m.section className="py-[130px] lg:py-[120px] md:py-[95px] sm:py-[80px] xs:py-[50px] overflow-hidden" {...fadeIn}>
                                <Container>
                                    <Row>
                                        <Col lg={11} xs={12}>
                                            <Row className="">
                                                <Col className="mb-[7%] xs:mt-[10px] sm:mt-[-20px] md:mt-[-80px] mt-[-70px]">
                                                    {item.subtitle1 && <h2 className="text-center justify-center text-[#0F3443] mb-[15px] xs:mb-[5px] text-[35px] xs:text-[20px] font-serif font-semibold tracking-[1px] uppercase xs:leading-[45px] leading-[20px]">{item.subtitle1}</h2>}
                                                    {item.title && <h2 className="text-center justify-center font-serif font-light text-[#D36135] text-[25px] xs:text-[15px] leading-[35px] tracking-[0px] mb-[10px] md:mt-[20px] md:mb-[30px] xs:my-[-10px]">{item.title}</h2>}
                                                </Col>
                                            </Row>
                                            <Row className="items-center">
                                                <m.div className="col-12 col-md-6 xs:mb-[30px]" {...fadeIn}>
                                                    {item.img && <img height="434" width="555" className="w-full" src={item.img} alt="tab" />}
                                                </m.div>
                                                <m.div className="col-12 col-md-6 col-xl-5 offset-xl-1" {...fadeInLeft}>
                                                
                                                <Blockquote
                                                className="xs:my-0"
                                                theme="blockquote-style-02"
                                                title={item.blogquote && (
                                                <p className="font-serif font-regular text-[#0F3443] text-[20px] leading-[30px] tracking-[0px] mb-[10px] md:mt-[10px] sm:mb-[30px] xs:mt-[-10px]">
                                                {item.blogquote} 
                                                </p>
                                                )} >
                                                </Blockquote>
                                                </m.div>
                                            </Row>
                                        </Col>
                                    </Row>
                                </Container>
                            </m.section>

                            <m.section className="mt-[-85px] md:py-[95px] sm:py-[80px] xs:py-[50px]" {...fadeIn}>
                                <Container>

                                    <Row>
                                        <Col lg={4} className="px-[15px] md:mb-[15px]">
                                            {item.subtitle2 && <h3 className="w-[100%] font-serif font-semibold text-[#D36135] text-[20px] xs:text-[15px] leading-[32px] xs:leading-[22px] tracking-[0px] mb-[15px] sm:mb-[30px] xs:mb-[15px] text-justify">{item.subtitle2}</h3>}
                                            {item.content && <p className="text-[#0F3443] font-light mb-[10px] w-[100%] md:w-full xs:mb-[15px] text-justify leading-0 xs:leading-[22px]">{item.content}</p>}
                                            {item.subcontent && <p className="text-[#0F3443] font-light mb-[10px] w-[100%] md:w-full xs:mb-[15px] text-justify leading-0 xs:leading-[22px]">{item.subcontent}</p>}
                                            {item.subcontent1 && <p className="text-[#0F3443] font-light mb-[10px] w-[100%] md:w-full xs:mb-[15px] text-justify leading-0 xs:leading-[22px]">{item.subcontent1}</p>}

                                        </Col>

                                        <Col lg={4} className="px-[15px] md:mb-[15px]">
                                            {item.subtitle3 && <h3 className="w-[100%] font-serif font-semibold text-[#D36135] text-[20px] xs:text-[15px] leading-[32px] xs:leading-[22px] tracking-[0px] mb-[15px] sm:mb-[30px] xs:mb-[15px] text-justify">{item.subtitle3}</h3>}
                                            {item.subcontent2 && <p className="text-[#0F3443] font-light mb-[10px] w-[100%] md:w-full xs:mb-[15px] text-justify leading-0 xs:leading-[22px]">{item.subcontent2}</p>}
                                            {item.subcontent3 && <p className="text-[#0F3443] font-light mb-[10px] w-[100%] md:w-full xs:mb-[15px] text-justify leading-0 xs:leading-[22px]">{item.subcontent3}</p>}
                                            {item.subcontent4 && <p className="text-[#0F3443] font-light mb-[10px] w-[100%] md:w-full xs:mb-[15px] text-justify leading-0 xs:leading-[22px]">{item.subcontent4}</p>}

                                        </Col>

                                        <Col lg={4} className="px-[15px] md:mb-[15px]">
                                            {item.subtitle4 && <h3 className="w-[100%] font-serif font-semibold text-[#D36135] text-[20px] xs:text-[15px] leading-[32px] xs:leading-[22px] tracking-[0px] mb-[15px] sm:mb-[30px] xs:mb-[15px] text-justify">{item.subtitle4}</h3>}
                                            {item.subcontent5 && <p className="text-[#0F3443] font-light mb-[10px] w-[100%] md:w-full xs:mb-[15px] text-justify leading-0 xs:leading-[22px]">{item.subcontent5}</p>}
                                            {item.subcontent6 && <p className="text-[#0F3443] font-light mb-[10px] w-[100%] md:w-full xs:mb-[15px] text-justify leading-0 xs:leading-[22px]">{item.subcontent6}</p>}
                                            {item.subcontent7 && <p className="text-[#0F3443] font-light mb-[10px] w-[100%] md:w-full xs:mb-[15px] text-justify leading-0 xs:leading-[22px]">{item.subcontent7}</p>}
                                        </Col>
                                    </Row>
                                </Container>
                            </m.section>

                            {/* <m.div className='row items-center' {...{ ...fadeIn, transition: { duration: 0.9 }, viewport: { once: false } }}>
                                <Col md={6} className="sm:mb-[40px]">
                                    {item.img && <img height="434" width="555" className="w-full" src={item.img} alt="tab" />}
                                </Col>
                                <Col lg={{ offset: 1, span: 5 }} md={6} className="md:px-[15px] sm:px-[15px] sm:text-start xs:text-center">
                                    {item.subtitle && <h2 className="text-darkgray mb-[15px] text-[30px] font-serif font-regular tracking-[1px] uppercase inline-block leading-[20px]">{item.subtitle}</h2>}
                                    {item.title && <h2 className="font-serif font-semibold text-[#D36135] text-[20px] leading-[35px] tracking-[0px] mb-[10px] sm:mb-[30px]">{item.title}</h2>}
                                    {item.content && <p className="mb-[10px] w-[85%] md:w-full xs:mb-[15px]">{item.content}</p>}
                                    {item.subcontent && <p className="mb-[10px] w-[85%] md:w-full xs:mb-[15px]">{item.subcontent}</p>}
                                    {item.subsubcontent && <p className="mb-[10px] w-[85%] md:w-full xs:mb-[15px]">{item.subsubcontent}</p>}
                                    {item.subsubsubcontent && <p className="mb-[10px] w-[85%] md:w-full xs:mb-[15px]">{item.subsubsubcontent}</p>}
                                    {item.subsubtitle && <h3 className="font-serif font-semibold text-[#D36135] text-[20px] leading-[35px] tracking-[0px] mb-[10px] sm:mb-[30px]">{item.subsubtitle}</h3>}
                                    {item.subsubsubsubcontent && <p className="mb-[10px] w-[85%] md:w-full xs:mb-[15px]">{item.subsubsubsubcontent}</p>}
                                    {item.subsubsubsubsubcontent && <p className="mb-[10px] w-[85%] md:w-full xs:mb-[15px]">{item.subsubsubsubsubcontent}</p>}
                                    {item.subsubsubsubsubsubcontent && <p className="mb-[10px] w-[85%] md:w-full xs:mb-[15px]">{item.subsubsubsubsubsubcontent}</p>}
                                    {(item.buttonLink && item.buttonTitle) && <Buttons ariaLabel="tab button" className="btn-fill rounded-none font-medium font-serif tracking-[1px] uppercase btn-fancy md:mb-[15px] xs:mb-0 xs:mt-[20px]" size="md" themeColor="#232323" color="#ffffff" to={item.buttonLink ? item.buttonLink : "#"} title={item.buttonTitle} />}
                                </Col>
                            </m.div> */}
                        </Tab>
                    )
                })
            }
        </Tabs>
    )
}

Tab01.defaultProps = {
    data: TabData01
}

Tab01.propTypes = {
    data: PropTypes.arrayOf(
        PropTypes.exact({
            tabTitle: PropTypes.string,
            subtitle: PropTypes.string,
            title: PropTypes.string,
            img: PropTypes.string,
            content: PropTypes.string,
            buttonLink: PropTypes.string,
            buttonTitle: PropTypes.string,
            tabicons: PropTypes.string
        })
    ),
}


export default memo(Tab01)