import React, { memo } from 'react'
import { fadeIn } from "../../../Functions/GlobalAnimations"
import { Col, Container, Row } from "react-bootstrap"
import { m } from "framer-motion"
import { TextAnime } from '../../../Components/FancyText/FancyText'
import InteractiveBanners04 from '../../../Components/InteractiveBanners/InteractiveBanners04'


const InteractiveBannersData04 = [
    {
        title: "Insect Abundance",
        content:
            "Population dynamics of a natural ecosystem. The abundance of insects can have cascading effects on other organisms, influencing resource availability, competition for food, and predator-prey relationships. By examining insect abundance, one can gain insights into the overall population structure and health of various species within the ecosystem, which in turn, contributes to a more comprehensive understanding of the ecological dynamics that affect the broader community of organisms sharing the same habitat.",
        img: "/assets/img/webp/abundance.webp",
        // icon: "line-icon-Add-Window",
    },
    {
        title: "Insect Activity",
        content: "Insect activity serves as a dynamic metric, clarifying the complex ecological interactions amongst different insects and environmental factors. Insects exhibit diverse activity throughout the day, and their presence spans the 24-hour cycle. Different species have distinct patterns of activity, essential for various ecological processes. This includes processes like pollination, predation, and competition, which can directly impact the availability of resources and habitats for other organisms. By understanding the dynamics of insect activity, you gain insights into how these interactions shape the ecosystem, influencing biodiversity, food systems, and overall ecological health.",
        img: "/assets/img/webp/activity.webp",
        // icon: "line-icon-Add-Bag",
    },
    {
        title: "Insect Diversity",
        content: "Insect diversity reflects the richness and abundance of different species coexisting within the habitat. A diverse ecosystem is often more resilient to environmental changes, as different species may have varied responses to disturbances. The presence of diverse species also contributes to ecological stability and balance, as each species plays a unique role in the ecosystem's functioning. Therefore, the level of diversity can influence the availability of resources, interactions among species, and overall ecosystem health, which, in turn, impacts the survival of other organisms within the ecosystem.",
        img: "/assets/img/webp/diversity.webp",
        // icon: "line-icon-Android-Store",
    },
    {
        title: "Insect Biomass",
        content: "The measurement of insect biomass provides an estimate of the amount of available food within an ecosystem and is indicative of the ecosystem's overall food availability for various insect eating organisms, such as carnivorous invertebrates, amphibians, birds, fish, reptiles, bats, and small mammals. Changes in insect biomass can impact the abundance and distribution of prey species, affecting the organisms that rely on insects as a food source. Consequently, this measurement contributes to assessing the ecosystem's health and its ability to sustain diverse populations of organisms.",
        img: "/assets/img/webp/biomass.webp",
        // icon: "line-icon-Cursor-Click2",
    },
]


const FaunaMetrics = () => {

    return (
        <>
            <section
                id="about"
                className="bg-white overflow-hidden xxl:pt-[0px] mxl:pt-[0px] xl:pt-[120px]  sm:py-[80px] xs:py-[0px]">
                <Container>
                    <Row className=" justify-center">
                        <m.div className="xs:mb-[20px] xl:mb-[40px]" {...fadeIn}>
                            <div className="font-serif text-center font-semibold mxl:text-[70px] xl:text-[65px] tracking-[1px]">
                                <h3 className="block text-gradient bg-gradient-to-r from-[#174E64] via-[#326765] to-[#91CAC8] p-0 -tracking-[1px] mb-[10px] sm:mb-0 mxl:leading-[90px] xl:leading-[90px] xs:leading-[25px] xs:text-[20px] uppercase">
                                    Achieve High-Integrity<br></br> Biodiversity Data <br></br>with simple metrics: </h3>
                            </div>
                            <Row className="justify-center">
                                <Col className="text-center uppercase font-bold text-[#326765] mxl:text-[75px] xl:text-[75px] xs:text-[15px] mxl:pb-[100px] xs:pb-[15px] xs:pt-[10px] pl-0 xs:pl-[50px] xl:pt-[10px] xl:pb-[45px]">
                                    Insect
                                    <TextAnime
                                        duration={4000}
                                        className="font-normal animated-text xl:pl-[25px] xl:text-[75px] mxl:pl-[20px] xs:pl-[5px] xs:text-[15px] uppercase"
                                        animation="slide"
                                        data={["Abundance", "Activity", "Diversity", "Biomass"]} />
                                </Col>
                            </Row>
                        </m.div>
                        <m.div className="text-center xl:w-[85%] mxl:mt-0 xl:mt-0 lg:mt-0 md:mt-0 xs:mt-[-10px]" {...{ ...fadeIn, transition: { delay: 0.5 } }}>
                            <p className="xxl:text-[30px] mxl:text-[30px] xl:text-[30px] xs:text-[15px] xxl:leading-[35px] mxl:leading-[35px] xs:leading-[20px] font-light xl:leading-[36px] md:w-full">
                                In the context of biodiversity assessment, the measurements of
                                insect activity, abundance, diversity, and biomass form the
                                basis for assessing the overall diversity, health, and quality
                                of a natural ecosystem.
                            </p>

                        </m.div>

                    </Row>
                </Container>
            </section>

            <section className="bg-white xxl:pt-[100px] mxl:pt-[100px] xl:pt-[100px] xs:pt-[0px] xxl:pb-[100px] mxl:pb-[100px] xl:pb-[100px] xs:pb-[20px] xs:px-2 justify-center">

                <Container className="xs:px-[15px]">
                    <p className="text-[#174E64] mxl:pb-[25px] xl:pb-[30px] mxl:pl-[6rem] xl:pl-[4rem] xs:pl-[0rem] xl:text-left xs:text-center mxl:text-[25px] xl:text-[25px] xs:text-[12px] xs:leading-[20px] font-regular xl:leading-[36px] md:w-full">
                        <br></br>
                        Click on the cards below to learn more!
                    </p>
                    <InteractiveBanners04
                        className=""
                        grid="row-cols-12 xxl:gap-y-[5rem] mxl:gap-y-[5rem] mxl:gap-x-[8rem] mxl:gap-x-[8rem] xl:gap-y-[6rem] xs:gap-y-[0rem] gap-x-[10rem] sm:justify-center xxl:pl-[6rem] mxl:pl-[6rem] xs:pl-[0rem] xl:pl-[4rem]"
                        overlay={["#0F3443", "#174E64", "#326765", "#499795", "#91CAC8"]}
                        data={InteractiveBannersData04}
                        animation={fadeIn}
                    />
                </Container>

            </section>
        </>
    )
}


export default memo(FaunaMetrics)