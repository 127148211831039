import React, { memo } from 'react'
import { fadeIn } from "../../../Functions/GlobalAnimations"
import { Col, Container, Row } from "react-bootstrap"
import Clients from "../../../Components/Clients/Clients"
import { ClientData02 } from "../Data/Data"

const FaunaAllies = () => {

    return (
        <>
            <section className="bg-white mxl:pt-[100px] xl:pt-[10px] xs:pt-[70px]">
                <Container>
                    <Row className="justify-center">
                        <Col
                            xl={6}
                            xs={10}
                            className="justify-center">
                            <div className="xxl:pb-[70px] mxl:pb-[70px] xl:pt-[150px] xl:pb-[40px] xs:pt-[0px] xs:pb-[20px] uppercase text-darkgray text-center font-light xs:text-[10px] xs:tracking-[0.5px] xl:leading-[50px] xs:leading-[10px]">
                                <span className="font-semibold text-gradient bg-gradient-to-r from-[#D36135] to-[#F2C05A] xxl:text-[65px] mxl:text-[65px] xl:text-[80px] xl:tracking-[-4px] xs:text-[20px] xs:leading-[43px] xs:tracking-[-1px]">
                                    Nature Allies</span>
                                <Col className="justify-center">
                                    <span className=" xxl:text-[20px] mxl:text-[20px] xl:text-[20px] xs:text-[8px] uppercase">
                                    Committed to Sustainable Solutions</span>
                                </Col>
                            </div>

                        </Col>
                    </Row>
                </Container>
            </section>

            <section className="bg-white xl:pt-[10px] xxl:pb-[150px] mxl:pb-[150px] xl:pb-[50px] mxl:mt-0 mxl:mb-0 xl:mb-0 lg:mb-0 md:mb-0 xl:mt-0 lg:mt-0 md:mt-0 xs:mt-[-60px] xs:mb-[-30px] xs:pt-[0px] xs:pb-[0px] overflow-hidden xs:w-[100%]">
                <Container fluid>
                    <Row className="justify-center">

                        <Col
                            lg={8}
                            xl={10}
                            md={10}
                            xs={12}
                            className="relative">
                            <Clients
                                theme="client-logo-style-03"
                                className="swiper-navigation-04 swiper-navigation-light"
                                data={ClientData02}
                                animation={fadeIn}
                                carousel={true}
                                carouselOption={{
                                    slidesPerView: 3,
                                    loop: true,
                                    // spaceBetween: 80,
                                    autoplay: { delay: 3000, disableOnInteraction: false },
                                    // navigation: true,
                                    breakpoints: {
                                        1200: { slidesPerView: 4 },
                                        992: { slidesPerView: 3 },
                                        768: { slidesPerView: 3 }
                                    }
                                }}
                            />
                        </Col>
                    </Row>
                </Container>
            </section>
        </>
    )
}


export default memo(FaunaAllies)