import React, { memo } from 'react'
import { Col, Container, Row } from "react-bootstrap"
import Videos from "../../../Components/Videos/Videos"

const FaunaTestimonials3 = () => {

    return (

        <section className=" bg-white xs:mt-[1rem] xs:mb-[0rem]">

            <Videos
                className="z-[0] bg-white"
                src="/assets/video/insectSwarm.mp4"
                loop={true}
                fullscreen={false}
                poster="/assets/video/insectSwarm.mp4"
                overlay={["#DA7D58", "#D36135", "#F2C05A", "#F4A4BF", "#EB5C8B"]}
                opacity={0.8}
            >

                <Container>
                    <Row className="justify-center z-[1] relative xxl:pt-[50px] mxl:pt-[50px] xl:pt-[25px] xs:pt-[20px]">
                        <div className={'col-12 flex justify-content-center  '}>
                            <img
                                src="/assets/img/png/customers/zooCph.png"
                                alt="hej"
                                className="h-[3rem]" />
                        </div>
                        <Col
                            lg={8}
                            xl={12}
                            md={10}
                            xs={10}
                            className="text-center z-[1] relative pt-[5%] pb-[5%]">
                            <p className="xxl:text-[30px] mxl:text-[30px] xl:text-[25px] md:text-[18px] sm:text-[12px] xs:text-[10px] mxl:leading-[40px] xl:leading-[35px] md:leading-[25px] sm:leading-[20px] xs:leading-[15px] font-regular font-serif text-white">
                                <span className="xl:text-[25px] md:text-[18px] sm:text-[12px] xs:text-[10px] font-semibold ">
                                    "Faunaphotonics’ sensor is a promising technology that could
                                    become an important tool for quantifying both biomass and
                                    diversity. It represents a significant initial step towards
                                    measuring biomass in a more automated manner.</span> 
                                    <br></br> <br></br>

                                We have been involved since the beginning, using the sensors
                                as a complement to more traditional monitoring methods.
                                We are pleased to see how FaunaPhotonics sensor technology
                                is a great supplement to the traditional ways of monitoring
                                nature. The technology delivers good, usable data in an
                                easily accessible manner. Their support and service are
                                readily available."
                            </p>
                        </Col>
                    </Row>
                </Container>
            </Videos>
        </section>

    )
}


export default memo(FaunaTestimonials3)