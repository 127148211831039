import React, { memo } from 'react'
import { Col, Navbar } from 'react-bootstrap'
import { Link } from 'react-router-dom'

const FaunaHeaderNav = () => {

    return (
        <Navbar.Collapse className='col-auto col-lg-8 px-lg-0  justify-center'>
            <ul className='navbar-nav alt-font text-white'>
                <li className='nav-item cursor-pointer'>
                    <Link
                        className='inner-link nav-link'
                        to={'/'}>Home</Link>
                </li>
                <li className='nav-item cursor-pointer'>
                    <Link
                        className='inner-link nav-link'
                        to={'/knowledge'}>Knowledge</Link>
                </li>
                <li className='nav-item cursor-pointer'>
                    <Link
                        className='inner-link nav-link'
                        to={'/#solutions'}>Solutions</Link>
                </li>
                <li className='nav-item cursor-pointer'>
                    <Link
                        className='inner-link nav-link'
                        to={'/contact'}>Contact</Link>
                </li>

                {/*

                            // Gamle nav
                            <li className='nav-item cursor-pointer'>
                                <ScrollTo
                                    to='home'
                                    offset={0}
                                    delay={0}
                                    smooth={true}
                                    duration={800}
                                    className='inner-link nav-link'>Home!</ScrollTo>
                            </li>


                            <li className="nav-item">
                  <ScrollTo to="about" offset={80} delay={0} spy={true} smooth={true} duration={800} className="inner-link nav-link">About</ScrollTo>
                </li>

                <li className='nav-item cursor-pointer'>
                                <ScrollTo
                                    to='solutions'
                                    offset={80}
                                    delay={0}
                                    spy={true}
                                    smooth={true}
                                    duration={800}
                                    className='inner-link nav-link'>Solutions</ScrollTo>
                            </li>


                            <li className='nav-item cursor-pointer'>
                                <ScrollTo
                                    to='contact'
                                    offset={80}
                                    delay={0}
                                    spy={true}
                                    smooth={true}
                                    duration={800}
                                    className='inner-link nav-link'>Contact</ScrollTo>
                            </li>*/}
            </ul>
        </Navbar.Collapse>)
}
export default memo(FaunaHeaderNav)