import React, {useEffect} from "react";

import {scroller} from 'react-scroll'

import FaunaHero from "../Components/FaunaHero";
import FaunaFooter from "../Components/FaunaFooter";
import FaunaHeader from "../Components/FaunaHeader";
import FaunaCredits from "../Components/FaunaCredits";
import FaunaContact from "../Components/FaunaContact";

const FaunaHome = (props) => {

    useEffect(() => {
        if (window.location.hash) {
            console.log(window.location.hash)
            scroller.scrollTo(window.location.hash.replace("#",""), {
                duration: 800,
                delay: 0,
                smooth: 'easeInOutQuart'
            })
        }
    }, [])


    return (
        <div style={props.style}>
            <FaunaHeader/>
            <FaunaHero/>
            <FaunaCredits/>
            <FaunaFooter/>
        </div>
    )
}

export default FaunaHome;
