import React, { Suspense, useEffect, useState } from 'react'

import { Routes, Route, useLocation } from 'react-router-dom'
import retina from 'retinajs'
import { AnimatePresence } from 'framer-motion'
import GlobalContext from './Context/Context'
import ScrollToTopButton from './Components/ScrollToTop'
import { initializeTagManager } from './Functions/gtm'
// Fauna
import FaunaHome from './Pages/Fauna/Pages/Home'
import FaunaCredits from './Pages/Fauna/Pages/Credits'
import FaunaPrivacy from './Pages/Fauna/Pages/Legal/Privacy'
import FaunaTerms from './Pages/Fauna/Pages/Legal/Terms'
import FaunaHeader from './Pages/Fauna/Components/FaunaHeader'
import FaunaContact from './Pages/Fauna/Components/FaunaContact'
import FaunaMetrics from './Pages/Fauna/Components/FaunaMetrics'
import FaunaSolutions from './Pages/Fauna/Components/FaunaSolutions'

import Knowledge from './Pages/Fauna/Pages/Knowledge'
import Biodiversity from './Pages/Fauna/Pages/Posts/1.Biodiversity'
import IncreaseBio from './Pages/Fauna/Pages/Posts/2.IncreaseBio'
import CorporateSus from './Pages/Fauna/Pages/Posts/3.CorporateSus'
import BioHumans from './Pages/Fauna/Pages/Posts/4.BioHumans'



function App() {
    const [headerHeight, setHeaderHeight] = useState(0)
    const [footerHeight, setFooterHeight] = useState(0)
    const [isModalOpen, setIsModalOpen] = useState(false)
    const [customModal, setCustomModal] = useState({
        el: null,
        isOpen: false,
    })
    const location = useLocation()

    useEffect(() => {
        // Google tag manager
        initializeTagManager()
        // RetinaJS
        window.addEventListener('load', retina(document.querySelectorAll('img')))
    }, [])


    useEffect(() => {
        setTimeout(() => {
            import('./Functions/Utilities').then(module => {
                module.SetHeaderMenuPos()
                module.setDocumentFullHeight()
            })
        }, 1000)
    }, [location])

    useEffect(() => {
        if (isModalOpen === true) {
            document.querySelector('body').classList.add('overflow-hidden')
        } else {
            document.querySelector('body').classList.remove('overflow-hidden')
        }
    }, [isModalOpen])

    // Get the current location and set the window to top
    useEffect(() => {
        window.scrollTo({
            top: 0,
            left: 0,
            behavior: 'instant',
        })
        setFooterHeight(0)
        setCustomModal({
            ...customModal,
            el: null,
            isOpen: false,
        })

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [location])

    return (
        <GlobalContext.Provider
            value={{
                headerHeight,
                setHeaderHeight,
                footerHeight,
                setFooterHeight,
                isModalOpen,
                setIsModalOpen,
                customModal,
                setCustomModal,
            }}
        >
            <div
                className='App'
                style={{ '--header-height': `${headerHeight}px` }}>
                {
                    <main style={{ marginTop: headerHeight, marginBottom: footerHeight }}>
                        <ScrollToTopButton />
                        <FaunaHeader />
                        <AnimatePresence exitBeforeEnter>
                            <Suspense fallback={<></>}>
                                <Routes>
                                    <Route
                                        path='/'
                                        element={<FaunaHome />} />
                                    <Route
                                        path='/knowledge'
                                        element={<Knowledge />} />
                                    <Route
                                        path='/knowledge/biodiversity-the-backbone-of-ecosystem-services'   //Important for SEO...
                                        element={<Biodiversity />} />
                                    <Route
                                        path='/knowledge/how-can-an-increase-in-biodiversity-lead-to-an-increase-in-ecosystem-stability'  
                                        element={<IncreaseBio />} />
                                        <Route
                                        path='/knowledge/what-does-csr-stand-for-and-why-it-is-important'   
                                        element={<CorporateSus />} />
                                        <Route
                                        path='/knowledge/how-could-the-loss-of-biodiversity-affect-humans'  
                                        element={<BioHumans />} />
                                    <Route
                                        path='/legal/terms'
                                        element={<FaunaTerms />} />
                                    <Route
                                        path='/legal/privacy'
                                        element={<FaunaPrivacy />} />
                                    <Route
                                        path='/standard-terms-and-conditions-of-faunaphotonics'
                                        element={<FaunaTerms />} />
                                        <Route
                                        path='/photo-and-video-credits'
                                        element={<FaunaCredits />} />
                                    <Route
                                        path="/legal/privacy"
                                        element={<FaunaPrivacy />} />
                                    <Route
                                        path='/contact'
                                        element={<FaunaContact />} />
                                    <Route
                                        path='/metrics'
                                        element={<FaunaMetrics />} />
                                    <Route
                                        path='/solutions'
                                        element={<FaunaSolutions />} />
                                </Routes>
                            </Suspense>
                        </AnimatePresence>
                    </main>
                }
            </div>
        </GlobalContext.Provider>
    )
}

export default App