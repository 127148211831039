import React, { memo } from 'react'
import { Col, Container, Row } from "react-bootstrap"
import { m } from "framer-motion"
import { fadeIn } from "../../../Functions/GlobalAnimations";




const FaunaSensor = () => {
    return (

        <section className="bg-white overflow-visible p-0 relative" {...fadeIn}>
            <Container fluid>
                <Row>
                    <Col
                        xl={7}
                        lg={6}
                        className="cover-background md:h-[500px] sm:h-[350px]"
                        style={{
                            backgroundImage: `url('/assets/img/webp/sensorInfield.webp') `,
                        }}>

                    </Col>
                    <Col
                        xl={5}
                        lg={6}
                        className="bg-[#0F3443] py-40 px-[9%] xxl:p-[5%] mxl:p-[5%] xl:p-[6%] lg:p-[6%] md:p-[10%] sm:py-[15%] sm:px-[7%] text-center">
                        <m.span {...fadeIn} className="font-serif font-medium text-gradient bg-gradient-to-r from-[#F2C05A] to-[#D36135] tracking-[2px] xl:text-[25px] xxl:text-[30px] mxl:text-[30px] xs:text-[14px] uppercase block mb-[35px]">
                            Innovation at the Forefront:
                        </m.span>
                        <m.h2 {...{
                            ...fadeIn,
                            transition: { delay: 0.4 }
                        }} className="text-center heading-4 xxl:text-[50px] mxl:text-[50px] xxl:tracking-[2px] mxl:tracking-[2px] xxl:leading-[75px] mxl:leading-[75px] xs:text-[25px] font-serif font-semibold text-white -tracking-[.5px] mb-12 w-[90%] sm:w-full">
                            Redefining <br></br>Biodiversity Measures <br></br> with Advanced Insect Monitoring
                            {/* <span className="text-center font-light mxl:text-[35px] xl:text-[30px] xs:text-[25px] text-[#F2C05A] "></span> */}
                        </m.h2>


                        <m.p {...{
                            ...fadeIn,
                            transition: { delay: 0.6 }
                        }} className="text-lg xxl:text-[25px] mxl:text-[25px] xl:text-[20px] xxl:leading-[45px] mxl:leading-[45px] xxl:tracking-[2px] mxl:tracking-[2px] font-light text-white leading-[38px] w-[90%] lg:w-full md:w-[80%] sm:w-full md:text-xmd">
                            Introducing the eVolito sensor system. A paradigm shift
                            in environmental monitoring. Our sensor register
                            the sky-to-ground electrical field and detects disruptions
                            caused by insects in flight. We leverage over a decade of
                            research on insect wingbeat frequencies. Our AI-driven 
                            analytics platform  translates this information into precise and
                            valuable insights about the insect communities and behaviour
                            in a habitat. Our non-disruptive sensor ensures unbiased
                            insect detection featuring a simple and streamlined design
                            for easy installation and is solar-powered for year-round
                            operation.
                            <br></br><br></br>
                            <span className="font-serif font-medium text-gradient bg-gradient-to-r from-[#F2C05A] to-[#D36135] tracking-[2px] xl:text-[22px] xxl:text-[25px] mxl:text-[25px] xs:text-[14px] xs:tracking-[0px] uppercase block">
                                Elevate your environmental intelligence <br></br>with our sensor technology
                            </span><br></br><span className="xxl:text-[25px] mxl:text-[25px] xl:text-[22px] font-regular xxl:tracking-[2px] mxl:tracking-[2px]">
                                Disclosing precision, sustainability, and innovation. </span>

                        </m.p>

                    </Col>
                </Row>
            </Container>
        </section>

    )
}


export default memo(FaunaSensor)