import React, { useState, memo } from 'react'
import { m } from "framer-motion"


// Libraries

import { Col, Container, NavLink, Navbar, Row, } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import * as cheerio from 'cheerio'


// Components

import { fadeIn } from '../../../../Functions/GlobalAnimations'
import Blockquote from '../../../../Components/Blockquote/Blockquote'
import { TiltBox } from '../../../../Components/FancyText/FancyText'
import Overlap from '../../../../Components/Overlap/Overlap'
import Tab01 from '../../../../Components/Tab/Tab01'
import { Swiper, SwiperSlide } from "swiper/react";

// Data

import { blogData } from '../../../../Components/Blogs/BlogData'



const TabData01 = [
  {
    tabTitle: '01',
    subtitle1: 'Habitat Fragmentation',
    tabicons: "",
    title: 'Severing the lifelines of biodiversity',
    img: "/assets/img/blog/fragmentation.jpg",
    blogquote: 'Habitat destruction and fragmentation are major drivers of biodiversity loss, and have significantly altered over 50% of Earths land area due to human activities. This alteration leads to the loss of natural habitats for countless species, driven primarily by deforestation, urbanization, and agricultural expansion.',

    subtitle2: '',
    content: 'Habitat fragmentation, a critical yet often overlooked consequence of human activity, further impairs the threat to global biodiversity. This process divides large, contiguous habitats into smaller, isolated patches, disrupting ecosystem functions and diminishing biological diversity within them.',
    subcontent: 'Fragmentation typically occurs due to various forms of land development, including urban expansion, agriculture, road construction, and logging. These activities change the landscape, creating barriers that restrict the movement of species and limit their access to essential resources like food, water, and mates. For many animals, especially larger mammals, these barriers prevent them from roaming across their natural range, which is vital not only for finding resources but also for maintaining genetic diversity within populations. When populations are genetically isolated, they become less resilient to diseases and environmental changes, increasing their risk of extinction.',
    subcontent1: '',


    subtitle3: 'Insects, which are crucial for pollination, soil formation, and as a food source for other wildlife, are particularly vulnerable to habitat fragmentation. ',
    subcontent2: 'Many insect species rely on specific plants for survival, and when these plants are isolated or removed from their environment, the insects lose their food sources and breeding grounds. Furthermore, fragmented habitats often create "edge effects," where the conditions at the margins of these fragments are significantly different from those in the interior, typically leading to drier, warmer, and windier conditions that can be inhospitable to many species.',
    subcontent3: '',
    subcontent4: '',

    subtitle4: '',
    subcontent5: 'Mitigating the effects of habitat fragmentation involves strategic planning and conservation efforts aimed at preserving connectivity between habitats. This can be achieved through the creation of wildlife corridors and the use of land bridges and underpasses that help maintain ecological flows and allow species to move freely between habitat patches. Additionally, establishing protected areas and regulating land-use around these areas can prevent further fragmentation and help sustain the ecological integrity of larger landscapes.',
    subcontent6: 'Restoration efforts are also crucial. Replanting native vegetation and reintroducing native species can help restore ecological balance and provide alternative habitats for displaced species. These efforts, combined with continuous monitoring and research, are essential for mitigating the impacts of habitat fragmentation.',
    subcontent7: '',

    // buttonTitle: "Discover Litho",
    // buttonLink: "/"
  },
  {
    tabTitle: '02',
    subtitle1: 'Industrial Agriculture',
    tabicons: "",
    title: 'A double-edged sword for biodiversity',
    img: "/assets/img/blog/agriculture.jpg",
    blogquote: 'In our ongoing exploration of the threats to biodiversity, industrial agriculture emerges as a significant concern. While agriculture is indispensable for feeding the global population, the methods employed in industrial farming often undermine the very ecosystems we depend on.',

    subtitle2: '',
    content: 'The UNEP (UN Environment Programme) has identified that 86% of endangered species are threatened due to industrial agriculture, which highlights the urgent need for more sustainable farming practices. This alarming statistic underscores the critical impact of our current agricultural methods on biodiversity, emphasizing the necessity for a fundamental shift towards practices that support both food production and environmental health.',
    subcontent: 'Monoculture, the practice of growing a single crop over a wide area for consecutive seasons, is a hallmark of industrial agriculture. This approach simplifies farming and increases efficiency but at a significant ecological cost. By planting only one type of crop, the diversity of the local flora is drastically reduced, which in turn affects the fauna dependent on a variety of plants for food, shelter, and breeding habitats. Insects, especially pollinators like native bees and butterflies, find fewer resources, leading to diminished populations and less biodiversity.',
    subcontent1: '',


    subtitle3: '',
    subcontent2: 'The impact of monocultures is further exacerbated by the extensive use of synthetic pesticides and fertilizers. These chemicals are designed to increase crop yields by killing pests and enhancing soil nutrients. However, they often have unintended consequences on non-targeted species. Pesticides can be toxic to a wide range of beneficial insects, not just the pests they aim to control. For example, neonicotinoids, a common class of insecticides, have been linked to significant declines in bird populations. These chemicals disrupt the nervous systems of birds and many other animals, leading to disorientation, impaired reproduction, and death.',
    subcontent3: '',
    subcontent4: '',

    subtitle4: '',
    subcontent5: 'Moreover, the runoff from fields treated with fertilizers and pesticides contaminates local waterways, leading to nutrient pollution. This can cause algal blooms and eutrophication, where excessive nutrients lead to oxygen poor waters, adversely affecting fish and other aquatic life. The resultant dead zones in rivers and coastal areas are devoid of life, a direct contradiction to the biodiversity needed for healthy ecosystems.',
    subcontent6: '',
    subcontent7: '',
    // buttonTitle: "Discover Litho",
    // buttonLink: "/"
  },
  {
    tabTitle: '03',
    subtitle1: 'Deforestation',
    tabicons: "",
    title: 'Clearing the path toward biodiversity collapse',
    img: "/assets/img/blog/deforestation.jpg",

    blogquote: 'Following climate change, deforestation stands as another imposing threat to global biodiversity, with profound implications for ecosystems worldwide. The destruction of forests disrupts the vulnerable balance of nature, directly impacting the species and the broader ecological networks that sustain life.',

    subtitle2: '',
    content: 'Deforestation, largely driven by agricultural expansion, logging, and infrastructure development, leads to the loss of approximately 10 million hectares of forest annually. This habitat destruction directly threatens biodiversity, as forests are home to 80% of terrestrial species.​',
    subcontent: 'Forests are often described as the lungs of our planet, they play a critical role in absorbing carbon dioxide and producing oxygen, integral to combating climate change. However, the rampant clearing of forests for agriculture, timber, and urban development eradicates these critical ecosystems at an alarming rate. This not only contributes to the rise in atmospheric CO2 levels but also leads to the loss of habitats for countless species.',
    subcontent1: '',


    subtitle3: '',
    subcontent2: 'Moreover, deforestation alters the habitat that provides food and shelter for countless species. As trees are felled, the complex web of life that depends on them collapses. Insects, which are critical pollinators and a fundamental part of the food web, lose their habitats and food sources, leading to declines in their populations. This has a knock-on effect on the species that feed on them, including birds, bats, and small mammals, many of which are crucial for agricultural pest control.',
    subcontent3: 'The impact of deforestation extends beyond the immediate area. It affects water cycles and soil fertility and increases the likelihood of floods and landslides, which can have disastrous consequences for human communities. Additionally, the fragmentation of forest areas creates smaller, isolated patches of habitats that are unable to support viable populations of wildlife, leading to genetic bottlenecks and reduced resilience in the face of environmental changes.',
    subcontent4: '',

    subtitle4: 'Deforestation in tropical regions',
    subcontent5: 'In tropical regions, which harbor the highest biodiversity on Earth, the effects of deforestation are particularly devastating. The Amazon rainforest has seen an unprecedented rate of deforestation. This vast area is home to millions of species, including insects, birds, and mammals, many of which are endemic and cannot be found anywhere else on the planet. The removal of forests in such regions leads to irreversible losses in biodiversity and disrupts ecological processes, such as seed dispersal and pollination, which are vital for the regeneration of the forest. UNEP (UN Environmental Programme) reports that the Amazon rainforest has lost 17% of its area in the last 50 years, significantly impacting species that depend on this ecosystem.',
    subcontent6: 'Moreover, deforestation alters the habitat that provides food and shelter for countless species. As trees are felled, the complex web of life that depends on them collapses. Insects, which are critical pollinators and a fundamental part of the food web, lose their habitats and food sources, leading to declines in their populations. This has a knock-on effect on the species that feed on them, including birds, bats, and small mammals, many of which are crucial for agricultural pest control.',
    subcontent7: 'The impact of deforestation extends beyond the immediate area. It affects water cycles and soil fertility and increases the likelihood of floods and landslides, which can have disastrous consequences for human communities. Additionally, the fragmentation of forest areas creates smaller, isolated patches of habitats that are unable to support viable populations of wildlife, leading to genetic bottlenecks and reduced resilience in the face of environmental changes.',
  },
  {
    tabTitle: '04',
    subtitle1: 'Pollution',
    tabicons: "",
    title: 'The silent killer of biodiversity',
    img: "/assets/img/blog/pollution.jpg",
    blogquote: 'As we delve deeper into the threats that undermine biodiversity, pollution stands out as a pervasive force capable of transforming vibrant ecosystems into lifeless wastelands. Pollution from agricultural runoff, industrial waste, plastic debris, and noise pollution poses severe risks to all life and human well-being. ',

    subtitle2: '',
    content: 'Chemical pollutants, including pesticides, heavy metals, and synthetic compounds, permeate our air, soil, and water, fundamentally altering natural ecosystems and posing immediate and long-lasting effects on a wide range of organisms and biodiversity globally.',
    subcontent: 'For example, agricultural runoff loaded with fertilizers and pesticides seeps into waterways, leading to nutrient overloads in aquatic ecosystems. This often results in eutrophication, causing algal blooms which, while a natural phenomenon, can become massive and toxic due to excessive nutrient pollution. These blooms deplete oxygen in the water, creating dead zones where aquatic life cannot survive.',
    subcontent1: '',


    subtitle3: 'The European Environment Agency (EEA) reports that over 60% of European rivers, lakes, and coastal waters are affected by nutrient pollution, underlining the severity of pollution caused by nutrient overloads into our natural environments. ',
    subcontent2: 'On land, pollution affects soil health and plant life, which are foundational to terrestrial biodiversity. Soil contaminants such as heavy metals not only degrade soil quality but also poison plants and the insects that rely on them for sustenance. This disruption has cascading effects on food availability and reproductive success across a myriad of species, ultimately reducing biodiversity and ecological resilience.',
    subcontent3: 'The impact on marine environments is equally concerning. Chemicals that persist in the environment, such as PCBs and DDT, accumulate in the marine food web, leading to poisoning and reproductive issues in top predators like sharks and whales. Similarly, microplastics have infiltrated the deepest parts of our oceans, consumed by a wide array of marine species, from plankton and fish to other groups of animals that rely on those as a food source (e.g. birds), thus integrating toxic substances into the food chain that ultimately leads back to human consumption.',
    subcontent4: '',

    subtitle4: 'The European Union reports an alarming rate of 150,000 to 500,000 tons of plastic waste entering European seas each year. Plastic debris threatens marine life, with over 90% of seabirds found to have ingested plastic.',
    subcontent5: 'These facts and examples highlight the pervasive and multifaceted threat that pollution poses to biodiversity globally, emphasizing the need for comprehensive measures to mitigate pollution and protect natural ecosystems. ',
    subcontent6: '',
    subcontent7: '',
  },

  {
    tabTitle: '05',
    subtitle1: 'Invasive Species',
    tabicons: "",
    title: 'Unintended consequences on local biodiversity',
    img: "/assets/img/blog/invasive.jpg",
    blogquote: 'The introduction of invasive  (non-native) species to new environments, whether inadvertently or deliberately, often results in significant disruptions to local ecosystems. They are reported to be responsible for 58% of local species extinctions in recent centuries. These invasive species can prey on, bring foreign diseases, become too dominant over time outcompeting native flora and fauna and leading to declines or even extinction of indigenous species.',

    subtitle2: '',
    content: 'One notable example is the American gray squirrel, introduced to Europe in the 19th century. The gray squirrel has since displaced the native red squirrel across much of Europe through competition and by carrying a virus that is fatal to red squirrels but not to grays, leading to a significant decline in red squirrel populations.',
    subcontent: 'Invasive species thrive in new territories where they often have no natural predators. This advantage allows them to multiply rapidly, overwhelming local species and ecosystems. Not only are they a key driver of local species extinction but they cost the global economy an estimated $1.4 trillion annually in damages and control costs (National Academies Press)​.',
    subcontent1: 'The effects of invasive species are not limited to land. Aquatic environments are also highly susceptible. The introduction of zebra mussels in North America has had severe impacts on waterways. These mussels multiply so prolifically that they clog water intake pipes and outcompete native mussels, leading to significant ecological and economic damage.',


    subtitle3: 'How to manage invasive species?',
    subcontent2: 'The challenge with managing invasive species is significant and requires a multifaceted approach. Early detection and rapid response are critical in managing these species. Monitoring systems are crucial for early detection of changes in insect populations that could indicate the presence of an invasive species. ',
    subcontent3: 'Management can also include mechanical or biological control methods. Biological control involves introducing a natural predator of the invasive species into the environment, which must be done carefully to avoid further imbalances. Mechanical controls, such as physical removal, can be labor intensive and costly but are often necessary to manage populations of invasive species.',
    subcontent4: 'Education and public awareness are also key components in managing the threat of invasive species. Informing people about the risks associated with transporting plants and animals between regions is crucial. Policies and regulations that prevent the import of potentially invasive species or that mandate the inspection and cleaning of equipment and vehicles that move between regions can also be effective.',

    subtitle4: '',
    subcontent5: '',
    subcontent6: '',
    subcontent7: '',
  },
  {
    tabTitle: '06',
    subtitle1: 'Overexploitation of Species',
    tabicons: "",
    title: 'Pushing biodiversity to the brink',
    img: "/assets/img/blog/fishing.jpg",
    blogquote: 'As we continue to examine the factors contributing to the decline in biodiversity, overexploitation of species through human activities such as fishing, hunting, and poaching stands out as a critical issue. These practices not only threaten individual species but also destabilize entire ecosystems, with profound implications for biodiversity.',

    subtitle2: 'Data from the National Academies Press informs that there has been a 68% average decline in population sizes of mammals, birds, amphibians, reptiles, and fish between 1970 and 2016 due to overexploitation and habitat loss​. ',
    content: 'Overfishing is one of the most pressing examples of how excessive exploitation can lead to severe declines in species populations. Commercial fishing operations, often equipped with vast nets and sophisticated tracking technologies, can deplete fish stocks faster than they can replenish. This not only impacts the targeted fish species but also the marine predators and other creatures that rely on these fish for food. The collapse of fish populations in many of the worlds oceans is a stark reminder of the need for regulated, sustainable fishing practices that adhere to scientific guidelines to ensure species survival and ecosystem health. ',
    subcontent: '',
    subcontent1: '',


    subtitle3: 'The impact of hunting and poaching on global biodiversity',
    subcontent2: 'On land, hunting and poaching are equally detrimental. While hunting can be part of traditional community practices that typically align with ecological balances, commercial hunting and poaching often are not. Elephants, rhinos, and tigers, for instance, have been hunted to near extinction in some areas for their tusks, horns, and pelts, valued highly on the black market. The loss of these key species has cascading effects on the ecosystem, leading to overpopulation of some species and underpopulation of others, which can alter vegetation and even change the physical environment, such as river courses due to changes in grazing patterns.',
    subcontent3: 'Poaching, driven by illegal wildlife trade, not only threatens species with extinction but also undermines conservation efforts, costing local communities their heritage and the potential for ecotourism, which can be a sustainable source of revenue. The loss of iconic species due to poaching diminishes biodiversity and disrupts ecosystems, which are crucial for global ecological balance and the well-being of all species, including humans.',
    subcontent4: '',

    subtitle4: 'There is noticeable evidence that threats such as overexploitation of species, including overfishing, hunting, and logging, directly threatens many species with extinction.',
    subcontent5: '',
    subcontent6: '',
    subcontent7: '',
  },
  {
    tabTitle: '07',
    subtitle1: 'Climate Change',
    tabicons: "",
    title: 'A pervasive threat to biodiversity',
    img: "/assets/img/blog/climatechange.jpg",
    blogquote: 'Climate change affects biodiversity from the highest mountains to the deepest oceans, influencing the distribution, behavior, and survival of species across the globe. Warming temperatures and shifting weather patterns have a profound impact on habitats and the species that depend on them. ',

    subtitle2: '',
    content: 'In terrestrial environments, climate change alters rainfall patterns and increases the frequency and intensity of wildfires, disrupting traditional plant and animal life cycles. For example, extreme heat and prolonged droughts compromise the ability of plants to thrive and reproduce, leading to a reduction in available food sources for insects and higher animals, which can cascade through the food chain causing widespread ecological repercussions.',
    subcontent: '',
    subcontent1: '',


    subtitle3: 'The dual threat to terrestrial biodiversity and human economies',
    subcontent2: 'In terrestrial ecosystems, climate change presents a significant threat to biodiversity. Rising temperatures and altered precipitation patterns can lead to disrupting the balance of ecosystems for species adapted to specific temperature ranges may struggle to survive in hotter or drier conditions, leading to population declines or local extinctions. Additionally, shifts in flowering and migration times can desynchronize key ecological interactions, such as pollination and seed dispersal. These changes not only endanger individual species but also have cascading effects on entire ecosystems, potentially leading to declines in ecosystem services like water purification and carbon sequestration.',
    subcontent3: 'In aquatic systems, warming waters and acidifying oceans due to increased CO2 levels pose a significant threat to marine life. Coral reefs are particularly vulnerable. The bleaching of coral reefs disrupts marine ecosystems that support up to a quarter of all ocean species at some point in their life cycle. This not only affects the species directly involved but also impacts fishing communities and economies dependent on marine biodiversity for tourism and food.',
    subcontent4: '',

    subtitle4: '',
    subcontent5: 'Moreover, climate change acts synergistically with other threats to biodiversity, such as habitat destruction and pollution. For instance, as temperatures rise, invasive species and pests find new territories more hospitable, outcompeting native species and altering the ecological balance. This is seen in the shifting distributions of insects, including pollinators, upon which our global food supply depends.',
    subcontent6: 'Ultimately, the loss of biodiversity due to climate change can have profound implications for both natural ecosystems and human well-being. Ecosystem services, such as pollination, water purification, flood protection, and carbon sequestration, are all compromised as biodiversity declines. This loss not only poses an existential risk to the natural beauty and wildlife we strive to protect but also to agriculture, medicine, and the overall resilience of communities to climate impacts.',
    subcontent7: '',
  },
  {
    tabTitle: '08',
    subtitle1: 'Global Warming',
    tabicons: "",
    title: 'Escalating risks to biodiversity through climate extremes',
    img: "/assets/img/blog/globalwarming.jpg",
    blogquote: 'Global warming, a profound planetary concern, significantly magnifies the threats to biodiversity by altering the natural order of ecosystems and increasing the frequency and intensity of climate related disasters. Rising global temperatures contribute to a range of catastrophic environmental changes, from prolonged droughts that dry up water sources to intense wildfires that consume vast tracts of forest.',

    subtitle2: '',
    content: 'Catastrophic environmental events lead to habitat destruction and significant shifts in the availability of crucial resources for wildlife. The 2019-2020 Australian bushfires, a stark example, resulted in the loss of over a billion animals and extensive habitat destruction, demonstrating how warming induced disasters can lead to abrupt and massive biodiversity loss.',
    subcontent: 'Droughts, similarly, have far reaching impacts beyond the immediate scarcity of water. They affect plant life, the foundational producers in most ecosystems, which in turn affects every species that depends on them for shelter, food, and oxygen. For insects, particularly those that are pollinators or part of complex food webs, the loss of plant life can lead to drastic declines in population and even local extinctions, which ripple through the food chain affecting birds, reptiles, mammals, and ultimately, human agricultural activities.',
    subcontent1: '',


    subtitle3: '',
    subcontent2: 'The warming of the planet also leads to less obvious but equally destructive changes in ecosystems, such as the alteration of migration patterns and breeding cycles of various species. Warmer temperatures can cause species to start their migration earlier or extend their presence in a region, which can lead to mismatches in ecological timing. For example, if insects emerge before flowering plants due to warmer springs, it can lead to a lack of pollination, which affects plant reproduction and subsequently the entire ecosystem that relies on these plants.',
    subcontent3: 'Moreover, global warming is changing the geographic ranges of species, forcing them to move to cooler areas, typically towards higher altitudes or latitudes. This shift can lead to the introduction of species into new areas where they may become invasive, further threatening local biodiversity. It also leaves behind ecosystems without their keystone species, the critical players that help define the entire ecological community.',
    subcontent4: '',

    subtitle4: '',
    subcontent5: '',
    subcontent6: '',
    subcontent7: '',
  },

]

const TextSliderData02 = [
  {
    img: "/assets/img/blog/foodSec.jpg",
    title: 'Impact on',
    subtitle: 'Food Security',
    content: 'Biodiversity loss threatens global food security, with studies showing that it could lead to a decline in crop yields by up to 30% by 2050. This not only affects agricultural productivity but also poses risks to supply chains, potentially disrupting food production for companies reliant on agricultural inputs. Furthermore, it jeopardizes the stability of ecosystems that support vital agricultural processes.',

  },
  {
    img: "/assets/img/blog/pollination1.jpg",
    title: 'Pollination',
    subtitle: 'Services',
    content: "Pollinators contribute over $200 billion to global crop production annually, yet their populations are declining due to biodiversity loss. For businesses in sectors such as agriculture, food and beverage, apparel and pharmaceuticals, this decline in pollination services poses significant risks to supply chain resilience, productivity and profitability.",

  },
  {
    img: "/assets/img/blog/medicine1.jpg",
    title: "Medicinal",
    subtitle: "Resources",
    content: 'Biodiversity loss jeopardizes the discovery of new pharmaceuticals, with estimates suggesting that up to 50% of current medications are derived from natural sources. As companies in the pharmaceutical industry face challenges in drug discovery and development, preserving biodiversity becomes essential for maintaining a pipeline of life-saving treatments.',

  },
  {
    img: "/assets/img/blog/ecoService1.jpg",
    title: "Ecosystem",
    subtitle: "Services",
    content: "Biodiverse ecosystems provide invaluable services worth an estimated $125 trillion per year, including water purification, climate regulation, and disease control. For businesses operating in sectors such as tourism, construction, and energy, the degradation of these ecosystem services could lead to increased operational costs and regulatory risks.",

  },
  {
    img: "/assets/img/blog/eco.jpg",
    title: "Resilience to",
    subtitle: "Environmental Change",
    content: "Biodiversity-rich ecosystems are up to four times more resilient to extreme climate events, such as hurricanes and droughts, compared to less diverse ecosystems. Investing in biodiversity conservation measures can enhance corporate resiliency to climate-related risks and safeguard long-term business continuity.                                      ",

  },
]

// Filter the blog data category wise
const blogCleanData = blogData.filter((item) => item.blogType === "clean");

const metadata = {
  "id": 1,
  "editor": {
    "uuid": "5bb7c896-58f2-4371-bda4-20d7fc95decd",
    "firstname": "Line",
    "lastname": "Kræmer",
    "email": "likr@faunaphotonics.com",
    "initials": "likr"
  },
  "title": "Biodiversity: The Backbone of Ecosystem Services",
  "url": null,
  "image": null,
  "slug": "biodiversity-the-backbone-of-ecosystem-services",
  "created_at": "2024-05-14T07:33:54.000000Z",
  "updated_at": "2024-05-21T20:39:51.000000Z",
  "deleted_at": null
}

const scroll = (h) => {
  const sections = document.querySelectorAll('h2')
  const target = sections[h]
  target.scrollIntoView({
    behavior: 'smooth',
    block: 'start',
  })
}


const Biodiversity = ({ post, props, style }) => {
  const [data, stripped, content, setData,] = useState(post)
  const swiperRef = React.useRef(null)

  const [header] = useState(post)

  const $ = cheerio.load(stripped)
  const h2 = $('h2')
  const toc = h2.map((contents) => {
    return contents.children[0]?.children[0]?.data
  })

  const scroll = (h) => {
    const sections = document.querySelectorAll('h2')
    const target = sections[h]
    target.scrollIntoView({
      behavior: 'smooth',
      block: 'start',
    })
  }


  const [activeSlide, setActiveSlide] = useState(0)

  const [submitted, setSubmitted] = useState(false)

  return (

    <div>
      {/* Section Start */}
      <section className=" w-full mxl:h-[800px] xl:h-[500px] sm:h-[400px] xs:h-[200px] cover-background relative"
        style={{ backgroundImage: "url('/assets/img/blog/skyline.jpg')" }}>
      </section>
      {/* Seaction End */}

      {/* Section Start */}
      <section className=" bg-white pb-0 sm:py-[50px] xs:overflow-hidden sm:mt-[-250px] xs:mt-[-140px] mxl:mt-[-100px] xl:mt-[-100px]">
        <Container className="xs:p-0">
          <Row className="justify-center z-10">
            <Overlap className="!px-0">
              <Col lg={10} className="font-serif text-center bg-[#D36135] text-white py-[7rem] sm:py-[35px] xs:py-[35px] xs:px-[15px] relative mx-auto opacity-[95%] xs:pt-[-200px] xs:mb-[20px] "
                style={{ backgroundImage: "url('/assets/img/webp/blog-post-layout-02-img-pattern.webp')" }}>
                <div className="w-[1px] h-[90px] sm:h-[50px] xs:h-[45px] bg-white mx-auto absolute top-0 left-1/2 -translate-x-1/2 -translate-y-1/2"></div>
                <div className="uppercase text-md font-medium mb-[25px] font-serif">
                  <div className='text-white bg-black'></div>
                  <Link aria-label="link" to={'/solutions'} className="text-white inline-block xs:text-[12px]">Solutions</Link>
                  <span className="mx-[20px] w-[1px] h-[10px] bg-white inline-block"></span>
                  <Link aria-label="link" to={'/metrics'} className="inline-block text-white xs:text-[12px]">Metrics</Link>
                  <span className="mx-[20px] w-[1px] h-[10px] bg-white inline-block"></span>
                  <Link aria-label="link" to={'/contact'} className="inline-block text-white xs:text-[12px]">Contact</Link>
                </div>
                <h1 className="font-semibold w-[90%] mx-auto md:w-[70%] sm:w-[90%] xs:w-[90%] text-[44px] leading-[50px] sm:text-[20px] xs:text-[20px] xs:leading-[30px] xs:pt-[-40px] xs:pb-[30px] xs:mb-[-10px]">
                  Biodiversity: The Backbone of Ecosystem Health
                </h1>
                <div className="inline-block text-[#ffffffb3]  mt-[5px] xs:text-[13px]">
                  By FaunaPhotonics 22. May 2024
                </div>
              </Col>
            </Overlap>
          </Row>
        </Container>
      </section>
      {/* Seaction End */}



      {/* Section Start */}
      <section className="bg-white py-[100px] lg:py-[90px] md:py-[75px] sm:py-[50px] xs:py-[30px]">
        <Container>
          <Row className="justify-center">
            <Col
              xs={12}
              sm={12}
              md={10}
              lg={10}
              xl={10}
              mxl={10}>
              <div className="row">
                <Col className="md:mb-[15px] pb-[50px] xs:pb-[15px] md:w-[50%] sm:w-full xs:w-full">
                  <h3 className="text-justify font-serif text-[#0F3443] w-[80%] font-medium lg:w-[90%] md:w-full mxl:text-[30px] xl:text-[30px] lg:text-[28px] md:text-[22px] sm:text-[20px] xs:text-[15px] mxl:leading-[40px] xl:leading-[50px] lg:leading-[38px] md:leading-[32px] sm:leading-[25px] xs:leading-[20px] mxl:tracking-[0px]">
                    Biodiversity is crucial for the health of our planet and human survival.
                  </h3>
                </Col>
                <Col className="md:w-[50%] sm:w-full xs:w-full xs:pb-[20px]">
                  <p className="text-[#0F3443] font-light text-justify w-[95%] xxl:text-[22px] mxl:text-[22px] xl:text-[20px] lg:text-[16px] md:text-[14px] sm:text-[12px] xs:text-[10px] xxl:leading-[35px] mxl:leading-[35px] xl:leading-[30px] lg:leading-[25px] md:leading-[25px] sm:leading-[18px] xs:leading-[15px]">
                    Biodiversity supports essential services like pollination, climate regulation,
                    and nutrient cycling. Diverse ecosystems also offer cultural, recreational,
                    and economic benefits, making them resilient against environmental changes.
                    Protecting biodiversity ensures the natural balance that sustains life on Earth.
                  </p>
                </Col>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
      {/* Seaction End */}

      {/* Section Start */}
      <section className="py-[10px] lg:py-[10px] md:py-[10px] sm:py-[20px] xs:py-[0px]">
        <Container>
          <Row className="justify-center">
            <m.div className="col-12 col-md-6 bg-lightgray bg-no-repeat bg-right-bottom px-[70px] md:px-[40px] mxl:py-[60px] xl:py-[50px] lg:py-[50px] md:py-[30px] xs:py-10 lg:px-[5.5rem]" {...{ ...fadeIn, transition: { delay: 0.1 } }} >
              <Blockquote
                animation={fadeIn}
                theme="blockquote-style-04"
                // icon="fas fa-quote-left text-basecolor"
                subtitle="Biodiversity: The Foundation of Life"
                subtitleextra="Preserving Our Lifeline 
                - Nature's Role in Promoting Human Well-being"
                content="
                Over the past decades, increasing consumption levels, among other factors, 
                have strained the biosphere's provisioning services. This strain has led 
                to severe land and water degradation, habitat fragmentation, shifts, and 
                loss, alongside an alarming decline in biodiversity. These environmental 
                stressors suggest we may have exceeded safe thresholds in vital natural 
                systems, which form the foundation for sustaining life and ecological balance globally.">

              </Blockquote>
            </m.div>
            <m.div className="col col-md-6 bg-cover bg-center sm:h-[450px] xs:h-[300px]" style={{ backgroundImage: "url(/assets/img/blog/handonTree.jpg)" }} {...{ ...fadeIn, transition: { delay: 0.3 } }}></m.div>
          </Row>
        </Container>
      </section>
      {/* Seaction End */}

      {/* Section Start */}
      <m.section className="bg-white xxl:py-[140px] mxl:py-[140px] xl:py-[130px] lg:py-[120px] md:py-[95px] sm:py-[40px] xs:py-[30px]">
        <Container>
          <Row>
            <Col className="justify-center">
              <Col>
                <div className="bg-white">
                  <p className="text-justify mxl:mb-[50px] xl:mb-[50px] lg:mb-[40px] md:mb-[30px] sm:mb-[30px] xs:mb-[15px] xxl:leading-[45px] mxl:text-[30px] xl:text-[28px] md:text-[22px] sm:text-[20px] xs:text-[12px] mxl:leading-[45px] xl:leading-[40px] md:leading-[30px] sm:leading-[30px] xs:leading-[20px] font-light text-[#0F3443]">
                    Essential natural systems, including ecosystems, climate systems,
                    water cycles, nutrient cycles, and biodiversity, play a fundamental
                    role in regulating Earth's environment, supporting biological
                    diversity, providing ecosystem services, and ensuring the overall
                    health and functioning of the planet.
                  </p>
                </div>
                <div className="bg-white mxl:mb-[70px] xl:mb-[70px] lg:mb-[60px] md:mb-[50px] sm:mb-[40px] xs:mb-[15px]">
                  <p className="text-justify xxl:text-[30px] xxl:leading-[45px] mxl:text-[30px] xl:text-[28px] md:text-[22px] sm:text-[20px] xs:text-[12px] mxl:leading-[45px] xl:leading-[40px] md:leading-[30px] sm:leading-[30px] xs:leading-[20px] font-light text-[#0F3443]">
                    Major disturbances in essential planetary systems have unprecedented
                    consequences, jeopardizing the survival of countless species and
                    disrupting the fundamental ecological balance crucial for human
                    well-being. Healthy planetary systems are integral to human
                    well-being and the survival of all living organisms.
                  </p>
                </div>
                <div className="bg-white">
                  <h3 className="text-center font-serif font-medium text-[#0F3443] mxl:mb-[90px] xl:mb-[70px] lg:mb-[60px] md:mb-[50px] sm:mb-[40px] xs:mb-[15px] xxl:text-[50px] mxl:text-[50px] xl:text-[50px] lg:text-[45px] md:text-[32px] sm:text-[25px] xs:text-[20px] xs:tracking-[1px] xs:leading-[22px] -tracking-[-1px]">
                    This post delves into <span className="text-gradient bg-gradient-to-tr from-[#D36135] to-[#DA7D58] font-semibold">
                      Biodiversity </span>
                  </h3>
                  <p className="text-center font-regular text-[#0F3443] mxl:mb-[70px] xl:mb-[70px] lg:mb-[60px] md:mb-[50px] sm:mb-[40px] xs:mb-[15px] xxl:text-[25px] xxl:leading-[45px] mxl:text-[30px] xl:text-[28px] md:text-[22px] sm:text-[20px] xs:text-[12px] mxl:leading-[45px] xl:leading-[40px] md:leading-[30px] sm:leading-[30px] xs:leading-[20px]">
                    A vital component essential for a healthy planet
                    capable of sustaining all living organisms, including humans.
                  </p>
                </div>
                <div className="bg-white mxl:mb-[70px] xl:mb-[70px] lg:mb-[60px] md:mb-[50px] sm:mb-[45px] xs:mb-[20px]">
                  <p className="font-light text-[#0F3443] text-justify xxl:text-[30px] xxl:leading-[45px] mxl:text-[30px] xl:text-[28px] md:text-[22px] sm:text-[20px] xs:text-[12px] mxl:leading-[45px] xl:leading-[40px] md:leading-[30px] sm:leading-[30px] xs:leading-[20px] ">
                    In today's rapidly evolving world, biodiversity is at a tipping point.
                    From dense rainforests to vast ocean depths, the delicate balance of
                    life supporting our planet is under immense pressure. According to the Intergovernmental Science-Policy Platform on
                    Biodiversity and Ecosystem Services (IPBES), an estimated 1 million
                    plant and animal species are threatened with extinction, many
                    within decades, if current trends continue.
                  </p>
                </div>
                <div className="bg-white mxl:mb-[50px] xl:mb-[50px] lg:mb-[40px] md:mb-[40px] sm:mb-[30px] xs:mb-[15px]">
                  <p className="font-regular text-[#0F3443] text-justify xxl:text-[30px] xxl:leading-[45px] mxl:text-[30px] xl:text-[28px] md:text-[22px] sm:text-[20px] xs:text-[12px] mxl:leading-[45px] xl:leading-[40px] md:leading-[30px] sm:leading-[30px] xs:leading-[20px] border-l-[7px] border-[#D36135] xl:border-l-[7px] lg:border-l-[5px] md:border-l-[5px] xs:border-l-[2px] pl-4 pr-4">
                    The interconnected and vital web of life is increasingly diminishing
                    and even lost in many places on Earth. Diversity within species,
                    between species and of indispensable ecosystems – which humans and
                    all life depend on, are deteriorating faster than ever recorded
                    in human history.
                  </p>
                </div>
              </Col>
            </Col>
          </Row>
        </Container>
      </m.section>
      {/* Seaction End */}

      {/* Section Start */}
      <section
        className="bg-[#D36135] overflow-hidden mxl:mt-[-20px] mxl:py-[80px] xl:py-[100px] lg:py-[75px] md:py-[50px] sm:py-[35px] xs:py-[20px] xs:px-[5px]"
        style={{ backgroundImage: "url('/assets/img/webp/blog-post-layout-02-img-pattern.webp')" }}>
        <Container>
          <Row className="justify-center ">
            <Col xl={12} md={8} lg={7} className="px-md-0 text-center w-full xl:w-[85%]">
              <p className="text-uppercase font-serif font-light text-white inline-block pb-[50px] sm:pb-[35px] xs:pb-[20px] mxl:text-[38px] xl:text-[30px] lg:text-[30px] md:text-[28px] sm:text-[20px] xs:text-[15px] mxl:leading-[50px] xl:leading-[50px] lg:leading-[45px] sm:leading-[35px] xs:leading-[25px] xs:px-[10px] xs:font-medium  mxl:tracking-[3px]">
                The main drivers of the erosion of the very
                foundations of our health, livelihood, & economics
              </p>
              <p className="font-serif text-uppercase text-[#0F3443] font-semibold text-center mxl:text-[38px] xl:text-[30px] lg:text-[30px] md:text-[28px] sm:text-[20px] xs:text-[15px] mxl:leading-[50px] xl:leading-[45px] sm:leading-[35px] xs:leading-[25px] mxl:tracking-[3px] xs:tracking-[1px] xs:px-[10px] xs:font-medium">
                are ironically caused by human activities
              </p>
            </Col>
          </Row>
        </Container>
      </section>
      {/* Seaction End */}

      {/* Section Start */}
      <m.section className="justify-center bg-white overflow-hidden mxl:mt-[170px] xl:mt-[100px] lg:mt-[100px] md:mt-[60px] sm:mt-[70px] xs:mt-[40px]">
        <Container fluid>
          <h2
            className="bg-gradient-to-r font-serif title font-bold text-center mxl:text-[110px] xl:text-[80px] lg:text-[70px] md:text-[50px] sm:text-[45px] xs:text-[30px] mxl:leading-[120px] xl:leading-[100px] lg:leading-[80px] md:leading-[65px] sm:leading-[55px] xs:leading-[40px] -tracking-[-1px] uppercase xs:-tracking-[-1px]"
            style={{
              backgroundImage: "url(/assets/img/blog/biodiversityBg.jpeg)",
              backgroundClip: "text",
              WebkitTextFillColor: "transparent",
            }}>
            What does
            <br></br>
            Biodiversity Mean?
          </h2>
        </Container>
      </m.section>
      {/* Section End */}


      {/* Section Start */}
      <section className="bg-white overflow-hidden mxl:my-[50px] xl:my-[50px] lg:my-[50px] md:my-[40px] sm:my-[30px] xs:my-[20px]">
        <Container>
          <Row>
            <Col className="justify-center">
              <Col>
                <div className="bg-white">
                  <p className="font-regular text-[#0F3443] text-justify mxl:mb-[50px] xl:mb-[50px] lg:mb-[50px] md:mb-[50px] sm:mb-[40px] xs:mb-[30px] xxl:leading-[45px] mxl:text-[30px] xl:text-[28px] md:text-[22px] sm:text-[20px] xs:text-[12px] mxl:leading-[45px] xl:leading-[40px] md:leading-[30px] sm:leading-[30px] xs:leading-[20px] border-l-[7px] border-[#D36135] xl:border-l-[7px] lg:border-l-[5px] md:border-l-[5px] xs:border-l-[2px] pl-4 pr-4">
                    Biodiversity is a term that describes the abundance and variety
                    of species, genes, and the coexistence of interacting groups of
                    species within a shared ecosystem. This encompasses a wide range,
                    from local habitats to the global biosphere, which includes all
                    ecosystems and biomes, including terrestrial, aquatic, and
                    atmospheric environments, where life exists and interacts with
                    the physical environment.
                  </p>
                </div>
              </Col>
              <Col className="">
                <div className="bg-white">
                  <p className="font-light text-[#0F3443] text-justify mxl:mb-[50px] xl:mb-[50px] lg:mb-[50px] md:mb-[30px] sm:mb-[30px] xs:mb-[0px] xxl:leading-[45px] mxl:text-[30px] xl:text-[28px] md:text-[22px] sm:text-[20px] xs:text-[12px] mxl:leading-[45px] xl:leading-[40px] md:leading-[30px] sm:leading-[30px] xs:leading-[20px]">
                    Likewise, the loss of biodiversity refers to a decrease in the
                    abundance and variety of species, genes, and the assembly of
                    biological communities in a particular area. A reduction in the
                    variety of living organisms has cascading and significant
                    consequences, potentially leading to a complete breakdown in
                    the overall functioning of an ecosystem.
                  </p>
                </div>
              </Col>
            </Col>
          </Row>
        </Container>
      </section>
      {/* Section End */}


      {/* Section Start */}
      <section className="cover-background py-[40px] xl:py-[60px] lg:py-[60px] md:py-[35px] sm:py-[50px] xs:px-[5px]"
        style={{ backgroundImage: "url(/assets/img/blog/bioBg.jpg)" }}>
        <Container>
          <Row className="justify-center">
            <m.div className="col-sn-10 col-11">
              <Row className="justify-center flex landscape:lg:justify-center">
                <Col xl={7} lg={8} className=" bg-white drop-shadow-xl shadow-darkgray mxl:p-[3.2rem] xl:p-[3rem] xs:px-[3rem]">
                  <h3 className="font-serif text-[#D36135] font-semibold text-center mxl:text-[28px] xl:text-[30px] lg:text-[30px] md:text-[28px] sm:text-[22px] xs:text-[16px] mxl:tracking-[1.2px]">
                    Biodiversity: The Foundation of Life
                  </h3>
                  <p className="text-justify mb-[25px] text-[20px] xl:text-[22px] lg:text-[20px] md:text:[20px] sm:text-[15px] xs:text-[12px] leading-[30px] xl:leading-[32px] lg:leading-[30p] md:leading-[28px] sm:leading-[25px] xs:leading-[20px]">
                    Biodiversity, from tiny microbes to iconic species, forms the foundation of
                    thriving ecosystems, offering essential services like clean water, food,
                    and climate regulation. Protecting and valuing biodiversity isn't just
                    about preserving nature; it's crucial for economic well-being as well.
                    <br></br><br></br>
                    Understanding the profound impact of biodiversity on economic prosperity
                    is advised to encourage individuals, including those with a strong focus
                    on financial matters, to recognize its indispensable significance.
                    Biodiversity financing stands as a vital mechanism in fostering the
                    shift towards sustainable practices, ensuring a healthier planet and
                    sustaining economic resilience.
                  </p>
                </Col>
              </Row>
            </m.div>
          </Row>
        </Container>
      </section>
      {/* Seaction End */}

      {/* Seaction Start */}
      <m.section
        className="xl:mt-[85px] xxl:py-[100px] mxl:py-[100px] xxl:mt-[50px] mxl:mt-[50px] xl:py-[100px] lg:py-[100px] md:py-[95px] sm:py-[80px] xs:py-[50px] bg-[#D36135] overflow-hidden xs:px-[5px] mt-20"
        {...fadeIn}
        style={{ backgroundImage: "url('/assets/img/webp/blog-post-layout-02-img-pattern.webp')" }}>

        <Container>
          <Row className="justify-center">
            <Col xl={6} md={8} lg={7} className="px-md-0">
              <p className="font-bold text-[#fff] tracking-[1px]  text-center font-serif xxl:text-[40px] mxl:text-[40px] xl:text-[40px] md:text-[35px] xs:text-[30px]">
                WANT TO KNOW MORE?
              </p>
              <p className="font-bold text-darkgray tracking-[1px] my-16 text-center font-serif xl:leading-[55px] xxl:text-[40px] mxl:text-[40px] xxl:leading-[55px] mxl:leading-[55px] xl:text-[40px] xs:text-[30px] xs:leading-[40px]">
                REACH OUT TO OUR BIODIVERSITY EXPERTS TODAY
              </p>

              <Link
                className="uppercase inner-link nav-link font-bold text-[#fff] tracking-[1px]  text-center font-serif xxl:text-[40px] mxl:text-[40px] xl:text-[40px] md:text-[35px] xs:text-[30px]"
                to={'/contact'}>Click Here!
              </Link>
            </Col>
          </Row>
        </Container>
      </m.section>
      {/* Seaction End */}

      {/* Section Start */}
      <m.section className="justify-center bg-white overflow-hidden mxl:mt-[170px] xl:mt-[100px] lg:mt-[100px] md:mt-[60px] sm:mt-[70px] xs:mt-[40px]">
        <Container fluid>
          <h2
            className="bg-gradient-to-r font-serif title font-bold text-center mxl:text-[110px] xl:text-[80px] lg:text-[70px] md:text-[50px] sm:text-[45px] xs:text-[28px] mxl:leading-[120px] xl:leading-[100px] lg:leading-[80px] md:leading-[65px] sm:leading-[55px] xs:leading-[35px] -tracking-[-1px] uppercase xs:-tracking-[-1px]"
            style={{
              backgroundImage: "url(/assets/img/blog/asphalt.jpg)",
              backgroundClip: "text",
              WebkitTextFillColor: "transparent",
            }}>
            Why does Biodiversity
            <br></br>
            Matter in a Modern World?
          </h2>
        </Container>
      </m.section>
      {/* Section End */}


      {/* Section Start */}
      <section className="bg-white overflow-hidden mxl:my-[50px] xl:my-[50px] lg:my-[50px] md:my-[40px] sm:my-[30px] xs:my-[20px]">
        <Container>
          <Row>
            <Col className="justify-center">
              <Col>
                <div className="bg-white">
                  <p className="text-justify mxl:mb-[50px] xl:mb-[50px] lg:mb-[40px] md:mb-[30px] sm:mb-[30px] xs:mb-[15px] xxl:leading-[45px] mxl:text-[30px] xl:text-[28px] md:text-[22px] sm:text-[20px] xs:text-[12px] mxl:leading-[45px] xl:leading-[40px] md:leading-[30px] sm:leading-[30px] xs:leading-[20px] font-light text-[#0F3443]">
                    Despite growing awareness of the importance of biodiversity, human activities
                    continue to degrade ecosystems at an alarming rate. As populations expand and
                    economies grow, the demand for resources increases, placing additional strain
                    on already stressed ecosystems.
                    This has led to a decline in biodiversity, with species loss occurring at
                    unprecedented levels. The consequences of this loss is far-reaching, affecting
                    everything from food security to climate regulation.
                  </p>
                </div>
              </Col>
              <Col>
                <div className="bg-white mxl:mb-[70px] xl:mb-[70px] lg:mb-[60px] md:mb-[50px] sm:mb-[40px] xs:mb-[15px]">
                  <p className="text-justify xxl:text-[30px] xxl:leading-[45px] mxl:text-[30px] xl:text-[28px] md:text-[22px] sm:text-[20px] xs:text-[12px] mxl:leading-[45px] xl:leading-[40px] md:leading-[30px] sm:leading-[30px] xs:leading-[20px] font-light text-[#0F3443]">
                    It's crucial to grasp the direct impact of biodiversity loss on both our current
                    quality of life and, most significantly, the survival prospects for our and future
                    generations. Biodiversity loss not only diminishes the richness of our ecosystems
                    but it imperils essential services vital for human, environmental and economic
                    well-being.
                  </p>
                </div>
              </Col>
              <Col>
                <div className="bg-white mxl:mb-[70px] xl:mb-[70px] lg:mb-[60px] md:mb-[50px] sm:mb-[40px] xs:mb-[15px]">
                  <p className="text-center font-serif font-light text-[#0F3443] mxl:text-[45px] xl:text-[45px] lg:text-[42px] md:text-[32px] sm:text-[28px] xs:text-[15px] xs:font-regular mxl:leading-[65px] xl:leading-[60px] lg:leading-[60px] md:leading-[45px] xs:leading-[22px] xs:tracking-[0px] mxl:tracking-[2px]">
                    Understanding this interconnectedness is <span className="mxl:tracking-[2px] text-gradient bg-gradient-to-tr from-[#D36135] to-[#DA7D58] font-semibold">essential </span>
                    for addressing the urgent need to <span className="text-gradient bg-gradient-to-tr from-[#D36135] to-[#DA7D58] font-semibold">safeguard </span>
                    biodiversity and <span className="mxl:tracking-[2px] text-gradient bg-gradient-to-tr from-[#D36135] to-[#DA7D58] font-semibold">secure </span>
                    a sustainable future.
                  </p>
                </div>
              </Col>
              <Col>
                <div className="bg-white mxl:mb-[70px] xl:mb-[70px] lg:mb-[60px] md:mb-[50px] sm:mb-[40px] xs:mb-[15px]">
                  <p className="text-justify xxl:text-[30px] mxl:text-[30px] xl:text-[28px] md:text-[22px] sm:text-[20px] xs:text-[12px] mxl:leading-[45px] xl:leading-[40px] md:leading-[30px] sm:leading-[30px] xs:leading-[20px] font-light text-[#0F3443] border-l-[7px] border-[#D36135] xl:border-l-[7px] lg:border-l-[5px] md:border-l-[5px] xs:border-l-[2px] pl-4 pr-4">
                    A turning point is motivating world - and corporate leaders to prioritize
                    sustainability initiatives and contribute to positive environmental outcomes
                    by emphasizing the role of biodiversity conservation in mitigating risks,
                    enhancing resilience, and fostering innovation. The following section
                    highlights the actual economic and operational impacts of biodiversity loss,
                    and stresses the importance of integrating sustainability practices into
                    business operations.
                  </p>
                </div>
              </Col>
            </Col>
          </Row>
        </Container>
      </section>
      {/* Section End */}

      {/* Section Start */}
      <section className="xxl:mt-[10px] mxl:mt-[10px] xl:mt-[20px] lg:mt-[0px] md:mt-[0px] xs:mt-[30px] mb-[50px] relative">
        <Container fluid>
          <Swiper
            ref={swiperRef}
            slidesPerView="auto"
            speed={1000}
            pagination={{ clickable: true }}
            breakpoints={{
              992: {
                slidesPerView: "auto",
                spaceBetween: 0
              }
            }}
            loop={true}
            modules={false}
            autoplay={false}
            keyboard={{ enabled: true, onlyInViewport: true }}
            centeredSlides={true}
            className="SingleProjectPage05 relative black-move"
          >
            {TextSliderData02.map((item, i) => {
              return (
                <SwiperSlide key={i} className="xxl:w-[25%] mxl:w-[35%] w-[55%] lg:w-5/6 md:w-full xs:w-full">
                  <div className="relative">
                    <img height="" width="" src={item.img} alt="" />
                    <div
                      className="absolute bottom-0 right-0 mxl:w-[70%] xl:w-[60%] lg:w-[60%] md:w-[60%] sm:w-[60%] xs:w-[60%] xxl:h-4/5 mxl:h-4/5 xl:h-4/5 lg:h-4/5  md:h-4/5 xs:h-full flex justify-center items-center bg-white opacity-[95%] p-4"
                      style={{ backgroundClip: 'content-box' }}
                    >
                      <Row>
                        <Col className="justify-center">
                          <Col>
                            <div className="sm:pr-[10px] sm:pl-[20px] sm:mr-[50px] sm:mt-[-25px] xs:pr-[10px] xs:pl-[8px] xs:mr-[15px] xs:mt-[-20px] xxl:mt-[-85px] mxl:mt-[-120px] mxl:pr-[30px] mxl:mr-[0px] xl:mt-[-50px] lg:mt-[-40px] lg:mr-[75px] mr-[40px] pl-[30px] mt-[-100px] md:mt-[-25px] md:mr-[75px] ">
                              <h2 className="uppercase font-serif text-[#0F3443] font-regular xxl:text-[28px] mxl:text-[25px] xl:text-[25px] lg:text-[25px] xs:text-[12px] tracking-[1.5px] xs:tracking-[0px] xxl:mt-[100px] mxl:mt-[100px] xl:mt-[40px] lg:mt-[10px] md:mt-[20px] xs:mt-[10px]">{item.title}</h2>
                              <h2 className="uppercase font-serif text-[#D36135] font-semibold xxl:text-[28px] mxl:text-[25px] xl:text-[25px] lg:text-[25px] xs:text-[12px] tracking-[1.5px] xs:tracking-[0px] mxl:mt-[-55px] xl:mt-[-50px] lg:mt-[-40px] md:mt-[-35px] sm:mt-[-35px] xs:mt-[-47px]">{item.subtitle}</h2>
                              <p className="text-justify font-serif text-[#0F3443] font-light xxl:text-[20px] mxl:text-[15px] xl:text-[14px] lg:text-[15px] md:text-[15px] sm:text-[12px] xs:text-[6px] xxl:leading-[20px] mxl:leading-[20px] xl:leading-[20px] lg:leading-[22px] md:leading-[20px] sm:leading-[14px] xs:leading-[6px] xxl:mt-[18px] mxl:mt-[-35px] xl:mt-[-30px] lg:mt-[0px] md:mt-[-15px] sm:mt-[-20px] xs:mt-[-30px]">{item.content}</p>
                            </div>
                          </Col>
                        </Col>
                      </Row>
                    </div>
                  </div>
                </SwiperSlide>
              );
            })}
          </Swiper>
        </Container>
        {
          <>
            <div
              onClick={() => swiperRef.current.swiper.slidePrev()}
              className="absolute top-1/2 -translate-y-1/2 left-[40px] z-[1] cursor-pointer slider-navigation-style-07 swiper-button-previous-nav xs:left-[10px]"
            >
              <i className="feather-arrow-left text-[15px] text-black font-normal shadow-[0_0_30px_rgba(0,0,0,0.08)] bg-white flex justify-center items-center rounded-full h-[40px] w-[40px] text-center sm:h-[40px] sm:w-[40px]"></i>
            </div>
            <div
              onClick={() => swiperRef.current.swiper.slideNext()}
              className="absolute top-1/2 -translate-y-1/2 right-[40px] z-[1] cursor-pointer slider-navigation-style-07 swiper-button-next-nav xs:right-[10px]"
            >
              <i className="feather-arrow-right text-[15px] text-black font-normal shadow-[0_0_30px_rgba(0,0,0,0.08)] bg-white flex justify-center items-center rounded-full h-[40px] w-[40px] text-center sm:h-[40px] sm:w-[40px]"></i>
            </div>
          </>
        }
      </section>
      {/* Section End */}



      {/* Section Start */}
      <section className="bg-white overflow-hidden mxl:my-[50px] xl:my-[50px] lg:my-[50px] md:my-[40px] sm:my-[30px] xs:mt-[10px]">
        <Container>
          <Row>
            <Col className="justify-center">
              <Col>
                <div className="bg-white">
                  <p className="text-justify mxl:mb-[50px] xl:mb-[50px] lg:mb-[40px] md:mb-[30px] sm:mb-[30px] xs:mb-[15px] xxl:leading-[45px] mxl:text-[30px] xl:text-[28px] md:text-[22px] sm:text-[20px] xs:text-[12px] mxl:leading-[45px] xl:leading-[40px] md:leading-[30px] sm:leading-[30px] xs:leading-[20px] font-light text-[#0F3443]">
                    The consequences of biodiversity loss for humans are profound, affecting everything
                    from food security and health to economic stability and cultural heritage. As these
                    impacts underscore the critical importance of maintaining biodiversity, it becomes
                    essential to understand the root causes driving this decline. To address the issue
                    effectively, we must delve into the actual threats to biodiversity, exploring the
                    various factors that contribute to the alarming reduction in species and ecosystems
                    worldwide.
                  </p>
                </div>
              </Col>
            </Col>
          </Row>
        </Container>
      </section>
      {/* Section End */}


      {/* Section Start */}
      <m.section className="justify-center bg-white overflow-hidden mxl:mt-[170px] xl:mt-[100px] lg:mt-[100px] md:mt-[60px] sm:mt-[70px] xs:mt-[20px] xs:mb-[60px]">
        <Container fluid>
          <h2
            className="bg-gradient-to-r font-serif title font-bold text-center mxl:text-[110px] xl:text-[80px] lg:text-[70px] md:text-[50px] sm:text-[45px] xs:text-[28px] mxl:leading-[120px] xl:leading-[100px] lg:leading-[80px] md:leading-[65px] sm:leading-[55px] xs:leading-[35px] -tracking-[-1px] uppercase xs:-tracking-[-1px]"
            style={{
              backgroundImage: "url(/assets/img/blog/biodiversityBg.jpeg)",
              backgroundClip: "text",
              WebkitTextFillColor: "transparent",
            }}>
            What Are
            <br></br>
            8 Primary Threats to Biodiversity?
          </h2>
        </Container>
      </m.section>
      {/* Section End */}

      {/* //use text-slider-01 scss for navlink/nav item adjustment and use block-quote-02 scss for adjustment of text */}
      {/* Section Start */}
      <m.section className="bg-white mxl:mt-[75px] xl:mt-[80px] lg:mt-[0px] md:mt-[0px] xs:mt-[10px] xs:items-left xs:align-left xs:justify-left lg:py-[120px] md:py-[95px] sm:py-[80px] xs:py-[50px] xs:px-[5px]"  {...fadeIn}>
        <Container>
          <Tab01 data={TabData01} />
        </Container>
      </m.section>
      {/* Seaction End */}

      {/* Section Start */}
      <m.section className="bg-white mxl:mt-[150px] xl:mt-[80px] lg:mt-[-50px] md:mt-[-150px] xs:mt-[-80px]">
        <Container>
          <Row className="row-cols-1 justify-center">
            <Col xl={8} lg={10} className="text-center">
              <div className="mxl:w-[120px] xl:w-[120px] lg:w-[100px] md:w-[90px] xs:w-[60px]  mxl:h-[4px] xl:h-[4px] lg:h-[4px] md:h-[4px] xs:h-[2px]  bg-[#D36135] transform rotate-90 xxl:mb-[70px] mxl:mb-[70px] xl:mt-[-40px] xl:mb-[70px] lg:my-[70px] md:my-[60px] xs:my-[30px] inline-block">
              </div>
              <p className="xxl:text-[55px] mxl:text-[55px] xl:text-[45px] lg:text-[45px] md:text-[40px] xs:text-[25px] xxl:leading-[70px] mxl:leading-[70px] xl:leading-[65px] lg:leading-[65px] md:leading-[65px] xs:leading-[38px] font-serif font-medium text-[#0F3443] tracking-[1px]">
                Can we <span className="text-gradient bg-gradient-to-tr from-[#D36135] to-[#DA7D58] font-semibold">
                  afford </span>to <span className="text-gradient bg-gradient-to-tr from-[#D36135] to-[#DA7D58] font-semibold">
                  wait? </span></p>
            </Col>
          </Row>
        </Container>
      </m.section>
      {/* Section End */}

      {/* Section Start */}
      <m.section className="mxl:mt-[100px] xl:mt-[60px] lg:mt-[60px] md:mt-[0px] xs:mt-[50px]">
        <Container>
          <Row>
            <Col className="justify-center">
              <Col>
                <div className="bg-white mxl:mt-0 xl:mt-0 lg:mt-0 md:mt-0 sm:mt-0 mxl:pt-[30px] xl:pt-[60px] lg:pt-[40px] xs:mt-[-50px] text-justify xs:text-center">
                  <p className="font-serif text-darkgray  font-light  mxl:text-[25px] xl:text-[25px] lg:text-[20px] md:text-[22px] sm:text-[20px] xs:text-[15px] mxl:leading-[38px] xl:leading-[38px] lg:leading-[35px] sm:leading-[30px] xs:leading-[22px] mxl:tracking-[0px]">
                    Embracing transformative change is imperative if we are to conserve,
                    restore, and sustainably use nature.
                  </p>
                </div>
                <div className="bg-white mxl:pt-[80px] xl:pt-[60px] lg:pt-[40px] xs:pt-[20px] text-center">
                  <p className="font-serif text-[#D36135] font-medium  mxl:text-[29px] xl:text-[28px] xs:text-center lg:text-[25px] md:text-[24px] sm:text-[20px] xs:text-[18px] mxl:leading-[38px] xl:leading-[38px] lg:leading-[35px] sm:leading-[30px] xs:leading-[25px] mxl:tracking-[2px]">
                    A crucial aspect of achieving numerous global goals!
                  </p>
                </div>
                <div className="bg-white mxl:pt-[80px] xl:pt-[60px] lg:pt-[40px] xs:pt-[20px] text-justify xs:text-center ">
                  <p className="font-serif text-darkgray font-light mxl:text-[25px] xl:text-[25px] lg:text-[20px] md:text-[22px] sm:text-[20px] xs:text-[15px] mxl:leading-[38px] xl:leading-[38px] lg:leading-[35px] sm:leading-[30px] xs:leading-[22px] mxl:tracking-[0px]">
                    Transformative change entails a fundamental reconstruction across
                    technological, economic, and social spheres, including reevaluating
                    paradigms, goals, and values. This shift is not just desirable but
                    essential for our survival and well-being. It's time for all sectors,
                    including the corporate world and those who may not yet fully appreciate
                    the urgency, to actively engage in this transformative process.
                  </p>
                </div>
                <div className="bg-white mxl:pt-[80px] xl:pt-[60px] lg:pt-[40px] xs:pt-[20px] text-center">
                  <p className="font-serif text-[#0F3443] font-medium mxl:text-[29px] xl:text-[28px] xs:text-center lg:text-[25px] md:text-[24px] sm:text-[20px] xs:text-[18px] mxl:leading-[45px] xl:leading-[38px] lg:leading-[35px] sm:leading-[30px] xs:leading-[25px] mxl:tracking-[1px]">
                    It's important to recognize that there's still time to enact meaningful
                    change, but action must start now at every level, from local to global.
                  </p>
                </div>
              </Col>
            </Col>
          </Row>
        </Container>
      </m.section>
      {/* Seaction End */}

      {/* Section Start */}
      <m.section className="bg-white mxl:mt-[150px] xl:mt-[200px] lg:mt-[110px] md:mt-[90px] sm:mt-[25px] xs:mt-[40px]">
        <Container>
          <Row className="row-cols-1 justify-center">
            <Col className="text-center">
              <div className="mxl:w-[120px] xl:w-[100px] lg:w-[100px] md:w-[90px] sm:w-[80px] xs:w-[60px]  mxl:h-[4px] xl:h-[4px] lg:h-[4px] md:h-[4px] sm:h-[2px] xs:h-[2px]  bg-[#D36135] transform rotate-90 xxl:mb-[70px] mxl:mb-[70px] xl:mt-[-40px] xl:mb-[70px] lg:my-[70px] md:my-[60px] xs:my-[30px] inline-block">
              </div>
              <p className="xxl:text-[55px] mxl:text-[45px] xl:text-[45px] lg:text-[40px] md:text-[35px] sm:text-[25px] xs:text-[18px] mxl:leading-[70px] xl:leading-[65px] lg:leading-[60px] md:leading-[55px] sm:leading-[42px] xs:leading-[30px] font-serif font-medium text-[#0F3443] mxl:tracking-[1.5px]">
                Ultimately, the  <span className="text-gradient bg-gradient-to-tr from-[#D36135] to-[#DA7D58] font-semibold">fate of biodiversity </span>rests in our hands. By taking <span className="text-gradient bg-gradient-to-tr from-[#D36135] to-[#DA7D58] font-semibold">
                  action now </span>to protect & preserve natural ecosystems, we can ensure <span className="text-gradient bg-gradient-to-tr from-[#D36135] to-[#DA7D58] font-semibold"><br></br>a healthy planet for future generations.</span>
              </p>
            </Col>
          </Row>
        </Container>
      </m.section>
      {/* Section End */}

      {/* Section Start */}
      <m.section className="mt-[500px] xl:mt-[500px] lg:mt-[400px] md:mt-[200px] sm:mt-[100px] xs:mt-[200px]" {...fadeIn}>
        <Container>
          <Row className="">
            <Col className="mb-[2%]">
              <p className="font-serif text-dark text-left text-[15px] font-light mb-[-150px] lg:mb-[15px]">Sources</p>
            </Col>
          </Row>
          <Row>
            <Col lg={6} className="px-[15px] md:mb-[15px] leading-[10px] font-serif font-light text-[8px] ">
              <div className=" p-[10px] sm:p-[30px]">
                <li>https://www.chathamhouse.org/2023/04/threats-biodiversity </li>
                <li>https://www.theguardian.com/environment/2021/oct/14/five-biggest-threats-natural-world-how-we-can-stop-them-aoe </li>
                <li>https://newswire.caes.uga.edu/story/10362/economics-of-pollination.html#:~:text=Pollinators%20are%20responsible%20for%20assisting,the%20country's%20top%2010%20producers. </li>
                <li>https://www.weforum.org/agenda/2020/11/wwf-living-planet-report-2020-biodiversity-threat/ </li>
                <li>https://www.ifc.org/en/stories/2023/biodiversity-finance-interview-with-irina-likhachova </li>
                <li>https://www.un.org/sustainabledevelopment/blog/2019/05/nature-decline-unprecedented-report/ </li>
                <li>https://www.ncbi.nlm.nih.gov/pmc/articles/PMC3061248/</li>
                <li>https://ccaps.umn.edu/story/6-reasons-why-biodiversity-conservation-important </li>
                <li>https://www.nature.com/articles/s41893-020-0582-x </li>
                <li>https://www.ipbes.net/news/Media-Release-Global-Assessment </li>
                <li>https://www.sciencedirect.com/science/article/abs/pii/S0959378014000685 </li>
                <li>https://www.britannica.com/science/biodiversity-loss </li>
                <li>https://www.ncbi.nlm.nih.gov/pmc/articles/PMC9300022/ </li>
                <li>https://www.unep.org/news-and-stories/story/safer-use-chemicals-can-help-protect-biodiversity </li>


              </div>
            </Col>
            <Col lg={6} className="px-[15px] md:mb-[15px] leading-[10px] font-serif font-light text-[8px] ">
              <div className=" p-[10px] sm:p-[30px]">
                <li>https://www.un.org/sustainabledevelopment/blog/2019/05/nature-decline-unprecedented-report/ </li>
                <li>EEA Report No 5/2012 - "Eutrophication and nutrient pollution in the Baltic Sea."</li>
                <li>EEA Report No 2/2018 - "European waters - assessment of status and pressures."</li>
                <li>EEA Report No 10/2016 - "European forest ecosystems."</li>
                <li>EEA Report No 1/2020 - "State of nature in the EU." </li>
                <li>European Commission - "Ground-level ozone."</li>
                <li>European Environment Agency (EEA) - "Air pollution impacts on biodiversity." </li>
                <li>EEA - "Hazardous substances in Europe's fresh and marine waters." </li>
                <li>European Commission - "Pesticides and water pollution." </li>
                <li>EEA Report No 18/2019 - "Marine litter." </li>
                <li>European Union - "Seabirds and plastic ingestion." </li>
                <li>EMSA - "European Maritime Safety Agency pollution response." </li>
                <li>EEA - "Soil pollution in Europe." </li>
                <li>EEA Report No 17/2018 - "Soil contamination: the invisible threat." </li>
                <li>Photo credit: Photo by Joel Cross on https://unsplash.com/ </li>
              </div>
            </Col>
          </Row>
        </Container>
      </m.section>
      {/* Section End */}

    </div >

    //            

    //             
    //               



    //
  )
}

export default memo(Biodiversity)


