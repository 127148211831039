import React, {memo} from 'react'
import {Container, Row, Col} from "react-bootstrap"
import {m} from "framer-motion";
import {fadeIn} from '../../../Functions/GlobalAnimations';

const FaunaInsects = () => {

    return (
        <>
            <section className="bg-white overflow-visible p-0 relative" {...fadeIn}>
                <Container fluid>
                    <Row>

                        <Col
                            xl={5}
                            lg={6}
                            className="xl:w-[50%] mxl:text-[25px] xs:w-full  bg-[#0F3443] py-40 px-[9%] xl:p-[6%] lg:p-[6%] md:p-[10%] sm:py-[15%] sm:px-[10%]">
                            <m.span {...fadeIn} className="font-serif font-medium text-gradient bg-gradient-to-r from-[#F2C05A] to-[#D36135] tracking-[2px] uppercase block mb-[35px]">
                                FACTS ABOUT INSECTS
                            </m.span>
                            <m.h2 {...{
                                ...fadeIn,
                                transition: {delay: 0.4}
                            }} className="heading-4 uppercase xs:text-[25px] font-serif font-semibold text-white -tracking-[.5px] mb-12  w-[90%] sm:w-full xs:leading-[40px]">
                                Insects as a proxy for<br></br>
                                <span className="font-light xs:text-[25px]  xl:text-[40px]  border-b-2 border-[#F2C05A]">
                  ecosystem health</span></m.h2>
                            <m.p {...{
                                ...fadeIn,
                                transition: {delay: 0.6}
                            }} className=" text-white font-light xl:text-[25px] xs:text-[15px] leading-[38px] xs:leading-[25px] xl:w-[90%]  xs:w-full">
                                Insects make up the largest and most diverse group of land-living animals on Earth,
                                with over a million identified species and estimates suggesting
                                millions more yet to be discovered.
                                This vast diversity spans various ecological niches and includes
                                species adapted to virtually every non-marine environment on the planet.
                                <br></br><br></br>
                                Monitoring insect populations provides a comprehensive snapshot of
                                ecosystem health, offering precise measurements of the overall biodiversity
                                status in a habitat and enabling targeted conservation improvement strategies.
                                In addition, insects respond rapidly to disturbances, making them effective
                                early indicators of environmental changes. Their ubiquity, diversity,
                                and responsiveness make insects indispensable tools for assessing
                                and preserving biodiversity health.
                            </m.p>
                        </Col>
                        <Col
                            xl={7}
                            lg={6}
                            className="cover-background xl:w-[50%] xs:w-full xs:h-[350px]"
                            style={{
                                backgroundImage: `url('/assets/img/webp/insectsProxy.webp') `,
                            }}>

                        </Col>
                    </Row>
                </Container>
            </section>

            <section className="bg-white overflow-visible p-0 xs:pt-[30px] relative" {...fadeIn}>
                <Container fluid>
                    <Row>
                        <Col
                            xl={7}
                            lg={6}
                            className="cover-background xl:w-[50%] xs:h-[350px] xs:w-full"
                            style={{
                                backgroundImage: `url('/assets/img/webp/insectsProxy2.webp') `,
                            }}>

                        </Col>
                        <Col
                            xl={5}
                            lg={6}
                            className="xl:w-[50%] mxl:text-[25px] xs:w-full bg-[#0F3443] py-40 px-[9%] xl:p-[6%] sm:px-[10%]">
                            <m.span {...fadeIn} className="font-serif font-medium text-gradient bg-gradient-to-r from-[#F2C05A] to-[#D36135] tracking-[2px] uppercase block mb-[35px] xs:pt-[25px]">
                                FACTS ABOUT INSECTS
                            </m.span>
                            <m.h2 {...{
                                ...fadeIn,
                                transition: {delay: 0.4}
                            }} className="heading-4 xs:text-[25px] uppercase font-serif font-semibold text-white -tracking-[.5px] mb-12  w-[90%] sm:w-full xs:leading-[40px]">
                                Guardians of<br></br>
                                <span className="font-light xs:text-[25px] xl:text-[40px] border-b-2 border-[#F2C05A]">
                our ecosystems</span></m.h2>
                            <m.p {...{
                                ...fadeIn,
                                transition: {delay: 0.6}
                            }} className=" text-white font-light xl:text-[25px] xs:text-[15px] leading-[38px] xs:leading-[25px] mb-[42px] w-[90%] lg:w-full md:w-[80%] sm:w-full">
                                Insects play essential roles in ecosystems as pollinators, organic 
                                material decomposition, and as a part of intricate food systems. 
                                Their pollination activities are vital for the reproduction of 
                                many flowering plants, and their role in breaking down organic 
                                matter contributes to nutrient cycling and soil health.
                                <br></br><br></br>
                                Insects exhibit remarkable adaptability to various environmental 
                                conditions and have rapid reproduction rates. Their ability to 
                                evolve quickly in response to changing environments and their 
                                resilience in the face of environmental challenges have 
                                contributed to their evolutionary success over hundreds of 
                                millions of years.
                            </m.p>
                        </Col>
                    </Row>
                </Container>
            </section>
        </>
    )
}


export default memo(FaunaInsects)