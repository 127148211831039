import React, { memo } from 'react'
import { Col, Container, Row } from "react-bootstrap"
import { fadeIn } from "../../../Functions/GlobalAnimations"
import Buttons from "../../../Components/Button/Buttons"
import { m } from 'framer-motion';

const ProcessStep = React.lazy(() => import('../../../Components/ProcessStep/ProcessStep'))


const ProcessStepData04 = [
    {
        title: "Tailored Data Subscription",
        content: "After jointly conducting a remote habitat assessment with your input, we determine the appropriate number of data subscriptions tailored to your specific project and objectives. Each data subscription is composed of multiple data collection points (in-field sensors) and site locations.",
    },
    {
        title: "Sensor Installation",
        content: "Sensors are shipped to your project sites along with an easy-to-understand installation guide. Our sensors are hassel-free, requiring no tools.  All installations withstand any IKEA test! All you have to do scan a QR code to start streaming data.",
    },
    {
        title: "Continuos Data Flow",
        content: "Your sensors consistently monitor flying insects in all types of weather. Simply relax and let our automated, non-invasive, and unbiased in-field sensor do its job.",
    },
    {
        title: "Customer Portal & API",
        content: "Data is consistently uploaded to the cloud and analyzed by our advanced AI/ML analytics platform. Edge computing is used for remote areas. You and your team can easily obtain a quick overview of the state of biodiversity at all of your sites through our customer portal. We offer the ability to integrate our dashboard as an API in your preferred software. ",
    },
    {
        title: "Data-Driven Reporting",
        content: "You can download data from your sites directly in the customer portal at any time. At the end of the year, you can obtain a customized report summarizing key insights and developments relevant for compliance to TNFD & CSRD standards.",
    },
]


const FaunaJourney = () => {

    return (<>
        <section className="bg-white overflow-visible p-0 relative" {...fadeIn}>
            <Container fluid>
                <Row>
                    <Col
                        xl={7}
                        lg={6}
                        className="cover-background md:h-[500px] sm:h-[350px]"
                        style={{
                            backgroundImage: `url('/assets/img/webp/stateofNature.webp') `,
                        }}>

                    </Col>
                    <Col
                        xl={5}
                        lg={6}
                        className="bg-[#0F3443] py-40 px-[9%] xl:p-[6%] lg:p-[6%] md:p-[10%] sm:py-[15%] sm:px-[10%]">
                        <m.span {...fadeIn} className="font-serif font-medium text-gradient bg-gradient-to-r from-[#F2C05A] to-[#D36135] tracking-[2px] mxl:text-[20px] xs:text-[10px] uppercase block mb-[35px]">
                            the ideal assessment tool
                        </m.span>
                        <m.h2 {...{
                            ...fadeIn,
                            transition: { delay: 0.4 }
                        }}
                            className="heading-4 mxl:text-[50px] xs:text-[20px] mxl:leading-[60px] xs:leading-[25px] font-serif font-semibold text-white -tracking-[.5px] mt-12 xs:mt-[-30px] mb-12 xs:mb-[20px] w-[90%] sm:w-full">
                            Obtain State of Nature Insights with insects
                            <br></br>
                            <span className="font-light xl:text-[30px] xs:text-[18px] text-[#F2C05A] ">
                                as a reliable measure of biodiversity
                            </span>
                        </m.h2>
                        <m.p {...{
                            ...fadeIn,
                            transition: { delay: 0.6 }
                        }} className="text-justify text-lg mxl:text-[25px] xs:font-light xs:text-[12px] mxl:leading-[40px] xs:leading-[20px] text-white leading-[38px] mb-[42px] xs:mb-[20px] w-[90%] lg:w-full md:w-[80%] sm:w-full md:text-xmd">
                            Insects are the bottom of Nature’s food chain making them the
                            ideal indicator of overall natural ecosystem diversity, health
                            and quality. Comprehensive biodiversity monitoring provides
                            invaluable insights into the effectiveness of conservation and
                            restoration efforts, enabling informed decision-making and
                            contributing to the overall health and sustainability of an
                            ecosystem. If your organization is committed to improving its
                            biodiversity impact,  we can help you plan and execute an
                            affordable and robust, continuous monitoring program that will
                            assess and optimize the outcomes of your ecosystem initiatives at scale.

                        </m.p>
                        <m.div {...{ ...fadeIn, transition: { delay: 0.8 } }}><Buttons
                            ariaLabel="link for conatct modern page"
                            to="/page/contact-modern"
                            className="font-medium font-serif uppercase btn-link after:h-[2px] md:text-md md:mb-[15px] text-[#F2C05A] hover:text-white tracking-[1px]"
                            size="xl"
                            color="#fff"
                            title="Get in touch with us" /></m.div>
                    </Col>
                </Row>
            </Container>
        </section>
        <section className="bg-white xxl:pt-[150px] mxl:pt-[150px] xl:pt-[80px] pb-[8%] border-b border-[#ededed] overflow-hidden sm:pt-[50px] sm:pb-32">
            <Container>
                <Row className="justify-center xs:mb-16">
                    <Col
                        lg={6}
                        sm={7}
                        className="text-center xxl:mb-[100px] mxl:mb-[100px] font-serif mb-[60px] md:mb-[35px] sm:mb-16 xs:mb-0">
                        <span className="font-semibold xxl:text-[20px] mxl:text-[20px] uppercase text-gradient bg-gradient-to-r from-[#E29779] via-[#F2C05A] to-[#D36135] block tracking-[1px] mb-[20px] sm:mb-[10px]">
                            A Simple & Transparent Process</span>
                        <h2 className="font-semibold  xxl:text-[40px] mxl:text-[40px] xxl:leading-[40px] mxl:leading-[40px] text-gradient bg-gradient-to-r from-[#D36135] to-[#F2C05A] xl:text-[45px] xl:tracking-[-1px] xl:leading-[65px] xs:text-[30px] xs:leading-[43px] xs:tracking-[-1px]">
                            Our End-To-End Solution 
                            <br></br>
                            <span className="font-light lowercase xl:text-[45px] xs:text-[30px] ">
                            for Biodiversity Monitoring
                            </span> </h2>
                    </Col>
                </Row>
                <ProcessStep
                    grid="row-cols-1 row-cols-sm-2 row-cols-lg-5 gap-y-20"
                    theme="process-step-style-04"
                    data={ProcessStepData04}
                    animation={fadeIn} />
            </Container>
        </section>
    </>
    )
}


export default memo(FaunaJourney)