import React, { memo } from 'react'
import Header, { HeaderNav, SearchBar } from '../../../Components/Header/Header'
import { Col, Navbar } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import { Link as ScrollTo } from 'react-scroll'
import SocialIcons from '../../../Components/SocialIcon/SocialIcons'
import FaunaHeaderNav from './FaunaHeaderNav'

const SocialIconsData = [
    {
        color: '#828282',
        link: 'https://www.linkedin.com/company/faunaphotonics',
        icon: 'fab fa-linkedin fa-xl',
    },
]

const FaunaHeader = () => {

    return (
        <Header
            id={'home'}
            topSpace={{ desktop: false, md: true }}
            type='always-fixed'
            className='one-page-navigation'>

            <HeaderNav
                theme='dark'
                fluid='lg'
                containerClass='sm:px-0'
                className='py-[0px]'>
                <Col className='col-auto col-sm-6 col-lg-2 me-auto ps-lg-0'>
                    <Link
                        aria-label='header logo'
                        className='flex items-center my-[20px] md:my-[18px] mx-0 ml-[10px]'
                        to='/'>
                        <Navbar.Brand className='inline-block p-0 m-0'>
                            <img
                                className='default-logo mxl:h-[3rem] xl:h-[3rem] xs:h-0'
                                width='111'
                                height='36'
                                loading='lazy'
                                src='/assets/img/horizontalWhite.svg'
                                data-rjs='/assets/img/horizontalWhite.svg'
                                alt='logo' />

                            <img
                                className='alt-logo xl:h-0 xs:h-0'
                                width='111'
                                height='36'
                                loading='lazy'
                                src='/assets/img/horizontalWhite.svg'
                                data-rjs='/assets/img/horizontalWhite.svg'
                                alt='logo' />

                            <img
                                className='mobile-logo xl:h-0 xs:h-[2rem]'
                                width='111'
                                height='36'
                                loading='lazy'
                                src='/assets/img/horizontalWhite.svg'
                                data-rjs='/assets/img/horizontalWhite.svg'
                                alt='logo' />

                        </Navbar.Brand>
                    </Link>
                    <Link
                        aria-label='header subpage'
                        className='flex items-center my-[20px] md:my-[18px] mx-0 ml-[10px]'
                        to='/'>
                    </Link>
                </Col>
                <Col
                    lg={{ order: 2 }}
                    xs={{ order: 3 }}
                    className='px-lg-0 px-0 md:contents'>
                    <Navbar.Toggle className=' md:ml-[17px] sm:ml-0 md:px-[25px] border-0'>
                        <span className='navbar-toggler-line'></span>
                        <span className='navbar-toggler-line'></span>
                        <span className='navbar-toggler-line'></span>
                        <span className='navbar-toggler-line'></span>
                    </Navbar.Toggle>
                    <FaunaHeaderNav />
                </Col>
                {/* <Col className="col-auto col-lg-2 text-right pe-0">
                    <SearchBar className="pr-0 xs:pl-[15px]" />
                </Col> */}
                <Col
                    lg={{ order: 3 }}
                    xs={{ order: 2 }}
                    className='justify-end col-auto col-lg-3 text-end pe-0 font-size-0 !pl-[90px] lg:!pl-[50px] md:!pl-0'>

                    <SocialIcons
                        theme='social-icon-style-01'
                        size='md'
                        className='pl-0 pr-0 xs:pl-[15px]'
                        iconColor='light'
                        data={SocialIconsData} />

                </Col>

            </HeaderNav>
        </Header>)
}
export default memo(FaunaHeader)