import React, { useEffect, useState, memo } from 'react'
import { useParams } from 'react-router-dom'
import { Col, Container, Row } from 'react-bootstrap'
import BlogClean from '../../../Components/Blogs/BlogClean'
import Biodiversity from '../Pages/Posts/1.Biodiversity'
import IncreaseBio from '../Pages/Posts/2.IncreaseBio'
import CorporateSus from '../Pages/Posts/3.CorporateSus'
import BioHumans from '../Pages/Posts/4.BioHumans'

const postsArray = [
    {
        "id": 1,
        "editor": {
            "uuid": "5bb7c896-58f2-4371-bda4-20d7fc95decd",
            "firstname": "Line",
            "lastname": "Kræmer",
            "email": "likr@faunaphotonics.com",
            "initials": "likr"
        },
        "title": "Biodiversity: The Backbone of Ecosystem Services",
        "url": "url('/assets/img/blog/skyline.jpg')",
        "image": "/assets/img/blog/skyline.jpg",
        "slug": "biodiversity-the-backbone-of-ecosystem-services",
        "created_at": "2024-05-14T07:33:54.000000Z",
        "updated_at": "2024-05-21T20:39:51.000000Z",
        "deleted_at": null
    },
    {
        "id": 2,
        "editor": {
            "uuid": "5bb7c896-58f2-4371-bda4-20d7fc95decd",
            "firstname": "Line",
            "lastname": "Kræmer",
            "email": "likr@faunaphotonics.com",
            "initials": "likr"
        },
        "title": "How can an Increase in Biodiversity Lead to an Increase in Ecosystem Stability?",
        "url": "url('/assets/img/blog/blog2Banner.jpg')",
        "image": "/assets/img/blog/blog2Banner.jpg",
        "slug": "how-can-an-increase-in-biodiversity-lead-to-an-increase-in-ecosystem-stability",
        "created_at": "2024-06-14T07:33:54.000000Z",
        "updated_at": "2024-06-14T07:33:54.000000Z",
        "deleted_at": null
    },
    {
        "id": 3,
        "editor": {
            "uuid": "5bb7c896-58f2-4371-bda4-20d7fc95decd",
            "firstname": "Line",
            "lastname": "Kræmer",
            "email": "likr@faunaphotonics.com",
            "initials": "likr"
        },
        "title": "What does CSR stand for? And Why it's Important",
        "url": "url('/assets/img/blog/blog3Banner.jpg')",
        "image": "/assets/img/blog/blog3Banner.jpg",
        "slug": "what-does-csr-stand-for-and-why-it-is-important",
        "created_at": "2024-06-14T07:33:54.000000Z",
        "updated_at": "2024-06-14T07:33:54.000000Z",
        "deleted_at": null
    },

    {
        "id": 4,
        "editor": {
            "uuid": "5bb7c896-58f2-4371-bda4-20d7fc95decd",
            "firstname": "Line",
            "lastname": "Kræmer",
            "email": "likr@faunaphotonics.com",
            "initials": "likr"
        },
        "title": "How Could the Loss of Biodiversity Affect Humans?",
        "url": "url('/assets/img/blog/blog4Banner.jpg')",
        "image": "/assets/img/blog/blog4Banner.jpg",
        "slug": "how-could-the-loss-of-biodiversity-affect-humans",
        "created_at": "2024-06-14T07:33:54.000000Z",
        "updated_at": "2024-06-14T07:33:54.000000Z",
        "deleted_at": null
    },

    // Next posts....
    // {
    //     "id": 2,
    //     "editor": {
    //         "uuid": "5bb7c896-58f2-4371-bda4-20d7fc95decd",
    //         "firstname": "Line",
    //         "lastname": "Kræmer",
    //         "email": "likr@faunaphotonics.com",
    //         "initials": "likr"
    //     },
    //     "title": "Biodiversity: The Backbone of Ecosystem Services",
    //     "url": null,
    //     "image": null,
    //     "slug": "biodiversity-the-backbone-of-ecosystem-services",
    //     "created_at": "2024-05-14T07:33:54.000000Z",
    //     "updated_at": "2024-05-21T20:39:51.000000Z",
    //     "deleted_at": null
    // },
]

const FaunaBlog = (props) => {
    const { slug } = useParams()
    const app_site = 'https://app.faunaphotonics.com/posts'
    const [blogposts, setBlogposts] = useState([])
    const [post, setPost] = useState(null)

    return (
        <>
            <section
                className='overflow-hidden bg-white relative pt-[-100px] px-[11%] xl:px-[2%] pb-[130px] lg:px-0 lg:pb-[90px] md:pb-[75px] sm:pb-[50px]'>
                <Container
                    fluid
                    className='xs:px-0 justify-center'>
                    {post && <Biodiversity post={post} />}
                    <BlogClean
                        link='/knowledge/'
                        data={postsArray}
                        // pagination={true}
                        grid='grid grid-3col xl-grid-3col lg-grid-3col md-grid-2col sm-grid-2col xs-grid-1col gutter-double-extra-large'
                    />
                </Container>
            </section>

        </>
    )
}


export default memo(FaunaBlog)
