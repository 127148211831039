import React, {memo} from 'react'
import {Col, Container, Row} from "react-bootstrap"
import {Link} from "react-router-dom"
import FooterMenu, {Footer} from "../../../Components/Footers/Footer"
import SocialIcons from "../../../Components/SocialIcon/SocialIcons"
import {PropTypes} from "prop-types"

import {FooterData} from "../Data/Data"
const PageFooterData = [...FooterData];

const iconData = [
    {
        color: "#828282",
        link: "https://www.linkedin.com/company/faunaphotonics",
        icon: "fab fa-linkedin"
    },
]
const FaunaFooter = () => {
    return (<Footer
        parallax={{desktop: true, md: false}}
        theme={'dark'}
        className={`text-[#828282] bg-fauna-blue`}>
        <div className="pt-[6%] lg:pt-[8%] footer-menu xs:py-[50px]">
            <Container>
                <Row className="justify-between md:text-start gap-y-[60px]">
                    <Col
                        lg={{span: 3, order: 0}}
                        md={6}
                        sm={{span: 6, order: 1}}>
                        <Link
                            aria-label="link"
                            to="/"
                            className="mb-[30px] inline-block xs:mb-[20px]">
                            <img
                                src={"/assets/img/horizontalWhite.svg"}
                                alt="logo"
                                width="200"/>
                        </Link>
                        <p className="subtitle font-serif font-light w-[85%] lg:w-full md:w-[70%] sm:w-full text-[20px] leading-[36px] -tracking-[0.5px] xs:w-[90%] mt-[-20px]">Making
                            Biodiversity Measurable.</p>
                    </Col>
                    <FooterMenu
                        className="justify-between"
                        data={PageFooterData}
                        lg={{span: "auto", order: 0}}
                        sm={{span: 6, order: 3}}
                        titleClass="uppercase tracking-[2px]"/>
                    <Col
                        lg={{span: "auto", order: 0}}
                        md={6}
                        sm={{span: 6, order: 2}}
                        className="instagram-col justify-end">
                        <span className="font-serif tracking-[2px] uppercase portrait:text-center landscape:text-start font-medium block text-themecolor mb-[30px] md:my-[15px] portrait:md:text-start">Follow us</span>
                        <SocialIcons
                            theme="social-icon-style-01"
                            className="portrait:justify-center landscape:justify-start flex portrait:md:justify-start"
                            size="xl"
                            iconColor={"light"}
                            data={iconData}/>
                    </Col>
                </Row>
            </Container>
        </div>
        <div className="py-[6%] xs:pt-0 xs:p-0 footer-details">
            <Container>
                <Row>
                    <Col
                        sm={{span: 12, order: 2}}
                        md={{span: 6, order: 1}}
                        xs={{order: 2}}
                        className="text-start flex sm:justify-center md:text-end md:mb-4 xs:text-center xs:justify-start">
                        <p>&copy; {new Date().getFullYear()} FaunaPhotonics A/S </p>
                    </Col>
                </Row>
            </Container>
        </div>
    </Footer>)
}

FaunaFooter.defaultProps = {
    data: FooterData,
    logo: "/assets/img/webp/logo.webp"
}

FaunaFooter.propTypes = {
    className: PropTypes.string,
    logo: PropTypes.string
}


export default memo(FaunaFooter)