import React, { memo } from 'react'
import { Container, Row, Col } from "react-bootstrap"
import { fadeIn } from "../../../Functions/GlobalAnimations"
import Clients from '../../../Components/Clients/Clients'


const ClientData05 = [
    {
        img: '/assets/img/png/CSRD.png',
        link: '#'
    },
    {
        img: '/assets/img/png/GRI.png',
        target: "_self",
        link: '#'
    },
    {
        img: '/assets/img/png/SBTN.png',
        target: "_self",
        link: '#'
    },
    {
        img: '/assets/img/png/TNFD.png',
        target: "_self",
        link: '#'
    }
]



const FaunaPrepare = () => {

    return (<section className="bg-white text-center grid-rows-3 grid-cols-2 xxl:py-[200px] mxl:py-[200px] xl:py-[120px] xs:py-[30px]">
        <Container>
        <Row className="justify-center">
            <h2 className="xs:pb-[0px] mxl:py-[50px] text-center text-gradient bg-gradient-to-r from-[#174E64] via-[#326765] to-[#91CAC8] font-semibold uppercase xl:tracking-[-1px] xs:tracking-[0px] xxl:text-[60px] mxl:text-[60px] xl:text-[65px] xs:text-[30px] xl:leading-[100px]  xs:leading-[40px]">
                Prepare your organization
            </h2>
            <span className="lowercase font-medium xxl:pb-[50px] mxl:pb-[50px] text-[#326765] xxl:text-[40px] mxl:text-[40px] xl:text-[40px] xs:text-[20px] xs:leading-[25px] tracking-[0px]">
                for upcoming Reporting Requirements</span>
               
                <Col xl={10} xs={11} className="text-left xs:text-center xl:mb-[60px] xs:mb-[10px]">
            <p className="xl:pt-[50px] xs:pt-[20px] xxl:pb-[60px] mxl:pb-[60px] xxl:text-[25px] mxl:text-[25px] xl:text-[30px] xs:text-[15px] xs:leading-[20px] font-light leading-[36px] md:w-full xs:mb-[20px] xl:pb-[0px]">
            Stay ahead of recent, relevant reporting standards and 
            legislation, which require large corporations to disclose 
            their nature-related risks and impacts. Take proactive 
            measures to avoid any potential operational setbacks, 
            including compliance to TNFD, CSRD, GRI and SBTN.
            </p>
            </Col>
</Row>



            <Clients grid="row-cols-1 row-cols-lg-2 row-cols-md-2 row-cols-sm-2  xl:mt-[15px] lg:mt-20 md:mt-0 xs:mt-8" theme="client-logo-style-05" data={ClientData05} animation={fadeIn} />
        </Container>
    </section>
    )
}


export default memo(FaunaPrepare)