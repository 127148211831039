import React, {useEffect} from "react";

import {scroller} from 'react-scroll'

import FaunaHero from "../Components/FaunaHero";
import FaunaFooter from "../Components/FaunaFooter";
import FaunaSolutions from "../Components/FaunaSolutions";
import FaunaAllies from "../Components/FaunaAllies";
import FaunaHeader from "../Components/FaunaHeader";
import FaunaElevate from "../Components/FaunaElevate";
import FaunaJourney from "../Components/FaunaJourney";
import FaunaPrepare from "../Components/FaunaPrepare";
import FaunaMetrics from "../Components/FaunaMetrics";
import FaunaTestimonials from "../Components/FaunaTestimonials";
import FaunaTestimonials2 from "../Components/FaunaTestimonials2";
import FaunaTestimonials3 from "../Components/FaunaTestimonials3";
import FaunaInsects from "../Components/FaunaInsects";
import FaunaContact from "../Components/FaunaContact";
import FaunaSensor from "../Components/FaunaSensor";



const FaunaHome = (props) => {

    useEffect(() => {
        if (window.location.hash) {
            console.log(window.location.hash)
            scroller.scrollTo(window.location.hash.replace("#",""), {
                duration: 800,
                delay: 0,
                smooth: 'easeInOutQuart'
            })
        }
    }, [])


    return (
        <div style={props.style}>
            {/*<FaunaHeader/>*/}
            <FaunaHero/>
            <FaunaSolutions/>
            <FaunaAllies/>
            <FaunaMetrics/>
            <FaunaTestimonials3/>
            <FaunaJourney/>
            <FaunaSensor/>
            <FaunaTestimonials/>
            <FaunaElevate/>
            <FaunaPrepare/>
            <FaunaTestimonials2/>
            <FaunaInsects/>
            <FaunaContact/>
            <FaunaFooter/>
         
        </div>
    )
}

export default FaunaHome;
