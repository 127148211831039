import React, { memo, useState } from 'react'
import { fadeIn } from '../../../Functions/GlobalAnimations'
import { Col, Container, Row } from 'react-bootstrap'
import { Form, Formik } from 'formik'
import { resetForm, sendEmail } from '../../../Functions/Utilities'
import { Input, TextArea } from '../../../Components/Form/Form'
import Buttons from '../../../Components/Button/Buttons'
import { AnimatePresence, m } from 'framer-motion'
import MessageBox from '../../../Components/MessageBox/MessageBox'
import { ContactFormStyle02Schema } from '../../../Components/Form/FormSchema'

const FaunaContact = () => {

    const [submitted, setSubmitted] = useState(false)

    return (
        <m.section
            id='contact'
            className='py-[130px] bg-cover bg-no-repeat overflow-hidden relative bg-center lg:py-[90px] md:py-[75px] sm:py-[50px] xs:py-0 xs:border-y'
            style={{
                backgroundImage:
                    'url(\'/assets/img/webp/contactBg.webp\')',
            }}
            {...fadeIn}
        >
            <Container className='xs:px-0'>
                <m.div className='row justify-center' {...{ ...fadeIn, transition: { delay: 0.6 } }}>
                    <Col
                        xl={7}
                        lg={8}
                        md={10}>
                        <div className='px-28 py-20 rounded-md shadow-[0_0_30px_rgba(0,0,0,0.08)] bg-white sm:p-20 xs:rounded-none xs:px-[3.5rem] xs:py-24'>
                  <span className='mb-[15px] font-medium text-center	text-[#8bb867] text-md font-serif uppercase block sm:mb-[10px]'>
                    Don't hesitate to reach out
                  </span>
                            <h2 className='heading-5 w-[80%] mb-[40px] font-bold text-center	tracking-[-1px] text-black font-serif uppercase mx-auto xs:w-full'>
                                Fill out the form and we'll be in touch soon!
                            </h2>
                            <Formik
                                initialValues={{ name: '', email: '', comment: '', phone: '' }}
                                validationSchema={ContactFormStyle02Schema}
                                onSubmit={async (values, actions) => {
                                    actions.setSubmitting(true)
                                    const response = await sendEmail(values)
                                    if (response.mail_sent === true) {
                                        resetForm(actions)
                                        setSubmitted(true)
                                    }
                                }}
                            >
                                {({ isSubmitting, status }) => {

                                    return (
                                        <Form className='mb-[30px]'>
                                            <Row>
                                                <Col>
                                                    <Input
                                                        showErrorMsg={false}
                                                        type='text'
                                                        name='name'
                                                        labelClass='mb-[25px]'
                                                        className='rounded-[5px] py-[15px] px-[10px] w-[15rem] border-[1px] border-solid border-[#dfdfdf] overflow-hidden'
                                                        placeholder='Your name' />
                                                </Col>
                                                <Col>
                                                    <Input
                                                        showErrorMsg={false}
                                                        type='text'
                                                        name='phone'
                                                        labelClass='mb-[25px]'
                                                        className='rounded-[5px] py-[15px] px-[20px] w-[15rem] sm:w-[18rem] border-[1px] border-solid border-[#dfdfdf] overflow-hidden'
                                                        placeholder='Your phone (optional)' />
                                                </Col>
                                            </Row>
                                            <Input
                                                showErrorMsg={false}
                                                type='email'
                                                name='email'
                                                labelClass='mb-[25px]'
                                                className='rounded-[5px] py-[15px] px-[20px] w-full border-[1px] border-solid border-[#dfdfdf] overflow-hidden'
                                                placeholder='Your email address' />
                                            <TextArea
                                                rows={5}
                                                name='comment'
                                                labelClass='mb-[25px]'
                                                className='rounded-[5px] py-[15px] px-[20px] w-full border-[1px] border-solid border-[#dfdfdf] overflow-hidden'
                                                placeholder='Your message' />
                                            {!submitted && <Buttons
                                                disabled={isSubmitting}
                                                type='submit'
                                                className={`btn-fill text-sm leading-none font-medium tracking-[1px] py-[13px] border-0 px-[32px] rounded-[20px] w-full uppercase mb-[5px]${isSubmitting ? ' loading' : ''}`}
                                                themeColor='#8bb867'
                                                color='#fff'
                                                size='lg'
                                                title={isSubmitting ? 'Sending...' : 'Get in touch with us'}
                                            />}
                                            <AnimatePresence>
                                                {submitted && (<m.div
                                                    initial={{ opacity: 0 }}
                                                    animate={{ opacity: 1 }}
                                                    exit={{ opacity: 0 }}><MessageBox
                                                    className='mt-[20px] text-center py-[10px] rounded-[20px]'
                                                    theme='message-box01'
                                                    variant='success'
                                                    message={<span>Thank you for reaching out!<br />We will get back to you as soon as possible.</span>} />
                                                </m.div>)}
                                            </AnimatePresence>
                                        </Form>
                                    )
                                }}
                            </Formik>
                            {/* <p className="w-[80%] text-xs leading-6 mx-auto xs:w-full text-center"> We are committed to
                                protecting your privacy. We will never collect information about you without your
                                explicit consent.</p> */}
                        </div>
                    </Col>
                </m.div>
            </Container>
        </m.section>
    )
}


export default memo(FaunaContact)