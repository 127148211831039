
export const data = [
    {
        icon: "feather-life-buoy",
        title: "Digital branding",
    },
    {
        icon: "feather-briefcase",
        title: "BRAND STRATEGY",
    },
    {
        icon: "feather-globe",
        title: "WEBSITE DESIGNING",
    },
    {
        icon: "feather-shopping-bag",
        title: "ECOMMERCE TOOL",
    },
];
export const serviceData6 = [
    {
        img: "/assets/img/webp/habitatAssessment.webp",
        title: "Habitat Assessment",
        content: "We provide remote habitat assessments by our biodiversity specialists. We assess the habitat condition, connectivity to the surrounding landscape and potential influencing environmental variables."
    },
    {
        img: "/assets/img/webp/designPlanning.webp",
        title: "Design Planning",
        content: " In collaboration with our customers, we help plan and design the biodiversity monitoring set-up to collect the best possible data outcomes for your biodiversity objectives."
    },
    {
        img: "/assets/img/webp/eVolito.webp",
        title: " Biodiversity Monitoring",
        content: "We help you measure, set and track your biodiversity targets. We collect baseline data, benchmark against natural habitats for target setting, and track the performance of your biodiversity initiatives. This data can support your ESG reporting, and comply with various sustainability frameworks."
    },
    {
        img: "/assets/img/webp/dashboardMetrics.webp",
        title: "Biodiversity Metrics",
        content: "We measure the state of biodiversity by using insects as a proxy. We provide metrics on Insect Abundance, Insect Activity, Insect Biomass and Insect Diversity."
    },
    {
        img: "/assets/img/webp/dashboard.webp",
        title: "Biodiversity Intelligence",
        content: "We utilize AI and ML to gather and deliver data insights on the state of nature from your sites, accessible through our tailored biodiversity intelligence platform. Receive regular and easy-to-grasp status updates at your monitored sites via our interactive dashboard."
    },
    {
        img: "/assets/img/webp/report.webp",
        title: "Sustainability Reporting",
        content: "Our data insights and comprehensive end-of-year report enable you to comply with multiple sustainability reporting requirements (TNFD, CSRD, GRI, SBTN)."
    }

]
export const HeaderSocialIconsData = [
    {
        color: "#828282",
        link: "https://www.linkedin.com/company/faunaphotonics",
        icon: "fab fa-linkedin"
    },
    // {
    //   color: "#828282",
    //   link: "https://www.instagram.com/",
    //   icon: "fab fa-instagram"
    // },
    // {
    //   color: "#828282",
    //   link: "https://twitter.com/",
    //   icon: "fab fa-twitter"
    // }
]
export const pricingTable = [
    {
        icon: "line-icon-Boy",
        title: "STANDARD",
        price: "$800",
        term: "PER MONTH",
        plans: ['Limited hours', 'Limited projects'],
        buttonTitle: "Get started",
        buttonLink: "/page/pricing-packages",
    },
    {
        icon: "line-icon-Bee",
        title: "ADVANCED",
        price: "$1200",
        term: "PER MONTH",
        plans: ['Unlimited hours', 'Unlimited projects', 'Unlimited variations'],
        buttonTitle: "Get started",
        buttonLink: "/page/pricing-packages",
        popular: {
            isPopular: true
        }
    }
]
export const ProcessStepData01 = [
    {
        icon: "icon-pencil",
        title: "Tailored Data Subscription",
        content: "After conducting a remote habitat assessment and considering your input, we determine the appropriate number of data subscriptions tailored to your specific project and objectives. Each data subscription comprises multiple data points (in-field sensors) and locations.",
        subcontent: ""
    },
    {
        icon: "icon-gears",
        title: "Sensor Installation",
        content: "One sensor is assigned to each data point and is strategically placed in the designated area where the company intends to measure biodiversity.",
        // subcontent: "Our sensors continuously collect data, and are easy to install, non-invasive and hazard-free."
    },
    {
        icon: "icon-linegraph",
        title: "Continuos Data Flow",
        content: "Your sensors consistently monitor flying insects in all types of weather. Simply relax and let our automated, non-invasive, and unbiased in-field sensor do its job. ",
        subcontent: ""
    },
    {
        icon: "line-icon-MaleFemale",
        title: "Customer Portal & API",
        content: "Data is consistently uploaded to the cloud and analyzed by our advanced AI and Machine Learning software. You and your team can easily obtain a quick overview of the state of biodiversity at your sites through our customer portal. We provide the option to integrate our dashboard as an API into your preferred software.",
        subcontent: ""
    },
    {
        icon: "icon-newspaper",
        title: "End-of-Year Report",
        content: "You can download data from your sites directly in the customer portal at any time. At the end of the year, you can obtain a customized End-of-Year report summarizing key insights and developments regarding the state of biodiversity at your sites.",
        subcontent: ""
    }
]


export const IconWithTextData_05 = [
    {
        icon: "line-icon-Vector-5",
        title: "Good design",
    },
    {
        icon: "line-icon-Police",
        title: "Inspiring ideas",
    },
    {
        icon: "line-icon-Bee",
        title: "Innovative code",
    },
    {
        icon: "line-icon-Medal-2",
        title: "Best quality",
    },
    {
        icon: "line-icon-Idea-5",
        title: "Brand analysis",
    },
]

export const IconWithTextData_08 = [
    {
        icon: "line-icon-Cursor-Click2",
        title: "Powerfull options",
        content: "Lorem ipsum dolor consectet eiusmod tempor incididunt",
    },
    {
        icon: "line-icon-Sand-watch2",
        title: "Unlimited variations",
        content: "Lorem ipsum dolor consectet eiusmod tempor incididunt",
    },
    {
        icon: "line-icon-Idea-5",
        title: "Effective strategy",
        content: "Lorem ipsum dolor consectet eiusmod tempor incididunt ",
    },
]

export const CustomIconWithTextData1 = [
    {
        img: "/assets/img/webp/tnfd.webp",
        // title: "TNFD",
        // content: "Lorem https://tnfd.global/recommendations-of-the-tnfd/getting-started-with-tnfd/ dolor sit amet sectetura dipisicing"
    },
    {
        img: "/assets/img/webp/csrd.webp",
        // title: "CSRD",
        // content: "https://finance.ec.europa.eu/capital-markets-union-and-financial-markets/company-reporting-and-auditing/company-reporting/corporate-sustainability-reporting_en",

    },
    {
        img: "/assets/img/webp/gri.webp",
        // title: "GRI",
        // content: "https://www.globalreporting.org/"
    },
    {
        img: "/assets/img/webp/sbtn.webp",
        // title: "SBTN",
        // content: "https://sciencebasedtargetsnetwork.org/how-it-works/the-first-science-based-targets-for-nature/ sit amet sectetura dipisicing"
    },


]

export const CustomIconWithTextData2 = [

    {
        icon: "line-icon-Navigation-LeftWindow",
        title: "Amazing layouts",
        content: "Lorem ipsum is simply dummy text of the printing typesetting lorem ipsum been text."
    },
    {
        icon: "line-icon-Cursor-Click2",
        title: "No coding required",
        content: "Lorem ipsum is simply dummy text of the printing typesetting lorem ipsum been text."
    },
    {
        icon: "line-icon-Like-2",
        title: "Easy to customize",
        content: "Lorem ipsum is simply dummy text of the printing typesetting lorem ipsum been text."
    },
    {
        icon: "line-icon-Talk-Man",
        title: "Customer satisfaction",
        content: "Lorem ipsum is simply dummy text of the printing typesetting lorem ipsum been text."
    },
    {
        icon: "line-icon-Paper-Plane",
        title: "Blazing performance",
        content: "Lorem ipsum is simply dummy text of the printing typesetting lorem ipsum been text."
    },
    {
        icon: "line-icon-Heart",
        title: "Huge icon collection",
        content: "Lorem ipsum is simply dummy text of the printing typesetting lorem ipsum been text."
    }

]

export const ClientData02 = [
    {
        img: '/assets/img/png/customers/neslé.png',
        // target: "_self",
        link: 'https://https://nestle.com'
    },
    {
        img: '/assets/img/png/customers/coop.png',
        // target: "_self",
        link: 'https://info.coop.dk/ansvarlighed/'
    },
    {
        img: '/assets/img/png/customers/inhort.png',
        // target: "_self",
        link: 'https://inhort.pl/#'
    },
    {
        img: '/assets/img/png/customers/bicocca.png',
        // target: "_self",
        link: 'https://en.unimib.it'
    },
    {
        img: '/assets/img/png/customers/aarhusUni.png',
        // target: "_self",
        link: 'https://international.au.dk'
    },
    {
        img: `/assets/img/png/customers/clever.png`,
        // target: "_self",
        link: 'https://clever.dk/om-clever'
    },
    {
        img: `/assets/img/png/customers/projar.png`,
        // target: "_self",
        link: 'https://projargroup.com/thegroup/?lang=en'
    },
    {
        img: `/assets/img/png/customers/EnBW.png`,
        // target: "_self",
        link: 'https://enbw.com/company/'
    },
    {
        img: '/assets/img/webp/rema-1000.webp',
        // target: "_self",
        link: 'https://rema1000.dk'
    },
    {
        img: '/assets/img/png/customers/bavarianNordic.png',
        // target: "_self",
        link: 'https://bavarian-nordic.com'
    },
    {
        img: '/assets/img/png/customers/zooCph.png',
        // target: "_self",
        link: 'https://zoo.dk'
    },
    {
        img: '/assets/img/png/customers/ncc.png',
        // target: "_self",
        link: 'https://ncc.com'
    },
    {
        img: '/assets/img/png/customers/inVivo.png',
        // target: "_self",
        link: 'https://invivo-group.com/en'
    },
    {
        img: '/assets/img/png/customers/hedeDanmark.png',
        // target: "_self",
        link: 'https://hededanmark.com'
    },
    {
        img: '/assets/img/png/customers/generalMills.png',
        // target: "_self",
        link: 'https://generalmills.com'
    },
    {
        img: '/assets/img/png/customers/betterEnergy.png',
        // target: "_self",
        link: 'https://betterenergy.com'
    },
    {
        img: '/assets/img/png/customers/artengluck.png',
        // target: "_self",
        link: 'https://artenglueck-naturschutz.de/home/'
    },
    {
        img: '/assets/img/png/customers/stichtingWageningen.png',
        // target: "_self",
        link: 'https://wur.nl/en.htm'
    },
    {
        img: '/assets/img/png/customers/slu.png',
        // target: "_self",
        link: 'https://slu.se/en/'
    },
    {
        img: '/assets/img/png/customers/RWE.png',
        // target: "_self",
        link: 'https://www.rwe.com/en/'
    },
    {
        img: '/assets/img/png/customers/BIG.png',
        // target: "_self",
        link: 'https://big.dk/'
    },
]

export const FooterData = [
    {
        title: "Address",
        submenu: [
            {
                title: "FaunaPhotonics A/S",
            },
            {
                title: "Støberigade 14, st.",
            },
            {
                title: "DK-2450 Copenhagen SV",
            },
            {
                title: "VAT: DK-35642986",
            },
            
        ]
    },
    {
        title: "Legal",
        submenu: [
            {
                title: "Privacy",
                link: "/legal/privacy"
            },
            {
                title: "Terms & Conditions",
                link: "/legal/terms"
            },
            {
                title: "Credits (coming soon)",
                // link: "/credits"
            },
        ]
    },
    {
        title: "Inquiries",
        submenu: [
            {
                title: "General",
                link: "mailto:info@faunaphotonics.com"
            },
            {
                title: "Partnerships",
                link: "mailto:commercial@faunaphotonics.com"
            },
        ]
    },
]

export const RotateBoxData = [
    {
        img: "/assets/img/webp/factsboxLep.webp",
        title: "FACTS ABOUT INSECTS",
        // subtitle: "about insects",
        icon: "line-icon-Bee",
        btnLink: "#",
        btnTitle: "Discover more",
        content: "A significant number of insects have evolved the ability to fly, making them the only invertebrates capable of sustained flight. This adaptation has played a crucial role in their widespread distribution, colonization of diverse habitats, and their role as pollinators in many ecosystems.",
        subcontent: "Insects undergo a unique process of metamorphosis, transitioning through distinct developmental stages such as egg, larva, pupa, and adult. This metamorphic ability contributes to their adaptability and allows them to exploit different resources and habitats throughout their life cycle."
    },
    // {
    //     img: "https://via.placeholder.com/720x828",
    //     title: "Fairness",
    //     subtitle: "Starting from $90",
    //     icon: "line-icon-Crown",
    //     btnLink: "#",
    //     btnTitle: "Discover more",
    //     content: "Lorem ipsum dolor sit amet consectetur do eiusmod tempor incididunt labore ut enim"
    // },
    // {
    //     img: "https://via.placeholder.com/720x828",
    //     title: "Herbal",
    //     subtitle: "Starting from $75",
    //     icon: "line-icon-Daylight",
    //     btnLink: "#",
    //     btnTitle: "Discover more",
    //     content: "Lorem ipsum dolor sit amet consectetur do eiusmod tempor incididunt labore ut enim"
    // }
]


export const InteractiveBannersData03 = [
    {
        title: "Assess",
        subtitle: "Custom shortcodes",
        img: "https://via.placeholder.com/800x920",
        imgLink: "/page/pricing-packages",
        btnTitle: "LEARN MORE",
        btnLink: "/page/contact-classic",
        titleLink: "#",
        icon: "fas fa-arrow-right"
    },
    {
        title: "Plan",
        subtitle: "Modern framework",
        img: "https://via.placeholder.com/800x920",
        imgLink: "/page/pricing-packages",
        btnTitle: "LEARN MORE",
        titleLink: "#",
        btnLink: "/page/contact-classic",
        icon: "fas fa-arrow-right"
    },
    {
        title: "Measure",
        subtitle: "Different layout",
        img: "https://via.placeholder.com/800x920",
        imgLink: "/page/pricing-packages",
        btnTitle: "LEARN MORE",
        titleLink: "#",
        btnLink: "/page/contact-classic",
        icon: "fas fa-arrow-right"
    },
    {
        title: "Report",
        subtitle: "True responsiveness",
        img: "https://via.placeholder.com/800x920",
        imgLink: "/page/pricing-packages",
        btnTitle: "LEARN MORE",
        titleLink: "#",
        btnLink: "/page/contact-classic",
        icon: "fas fa-arrow-right"
    },
    {
        title: "Disclose",
        subtitle: "True responsiveness",
        img: "https://via.placeholder.com/800x920",
        imgLink: "/page/pricing-packages",
        btnTitle: "LEARN MORE",
        titleLink: "#",
        btnLink: "/page/contact-classic",
        icon: "fas fa-arrow-right"
    }
]

