import React, { useEffect } from 'react'
import FaunaHeroBlog from '../Components/FaunaHeroBlog'
import FaunaHeroKnowledge from '../Components/FaunaHeroKnowledge'
import FaunaFooter from '../Components/FaunaFooter'
import FaunaBlog from '../Components/FaunaBlog'
import Biodiversity from '../Pages/Posts/1.Biodiversity'
import IncreaseBio from '../Pages/Posts/2.IncreaseBio'
import CorporateSus from '../Pages/Posts/3.CorporateSus'
import BioHumans from '../Pages/Posts/4.BioHumans'



const FaunaKnowledge = () => {

    return (
        <div>
            <FaunaHeroKnowledge />
            <FaunaBlog />
            <FaunaFooter />
        </div>
    )
}

export default FaunaKnowledge
