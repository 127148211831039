import React, { useState, memo } from 'react'
import { m } from "framer-motion"


// Libraries

import { Col, Container, Row } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import * as cheerio from 'cheerio'
import { Swiper, SwiperSlide } from "swiper/react"



// Components

import { fadeIn} from '../../../../Functions/GlobalAnimations'
import Blockquote from '../../../../Components/Blockquote/Blockquote'
import Overlap from '../../../../Components/Overlap/Overlap'
import Tab06 from '../../../../Components/Tab/Tab06'


// Data

import { blogData } from '../../../../Components/Blogs/BlogData'


const TabData06 = [
  {
    tabTitle: 'Resource Consumption',
    img: '/assets/img/blog/resourceCon.jpg',
    title: 'Reducing Resource Consumption',
    content: 'Implementing energy-efficient practices, reducing water usage, and minimizing waste production can significantly lessen the environmental footprint of a company',
    subcontent: 'Adopting energy-efficient practices, such as using solar power, LED lighting, and optimizing HVAC systems, reduces electricity usage and lowers greenhouse gas emissions, which benefits ecosystems. Water conservation methods, like low-flow fixtures and water recycling systems, help maintain local water supplies vital for biodiversity.',  
    subcontent1:'Minimizing waste production through recycling, composting, and reducing single-use plastics prevents habitat destruction and pollution. Promoting biodiversity not only enhances ecosystem health but also strengthens the sustainability reputation of a company, while attracting eco-conscious consumers and investors.',
  },
  {
    tabTitle: 'Conservation Projects',
    img: '/assets/img/blog/conservationPro.jpg',
    title: 'Supporting Conservation Projects',
    content: 'Investing in or partnering with conservation projects can directly contribute to the protection and restoration of biodiversity. By investing in or partnering with these initiatives, companies can directly contribute to the preservation of natural habitats and endangered species. Such efforts can include funding reforestation projects, protecting marine ecosystems, and supporting wildlife reserves.',
    subcontent: 'These actions help maintain ecological balance and ensure the survival of diverse species. Additionally, companies that engage in conservation projects can enhance their public image, demonstrating a commitment to sustainability and attracting customers and investors who value environmental stewardship. This not only benefits the planet but also strengthens the market position and brand loyalty of a company.',
    subcontent1: '',
  },
  {
    tabTitle: 'Sustainable Sourcing',
    img: '/assets/img/blog/sustainableSou.jpg',
    title: 'Sustainable Sourcing',
    content: 'Ensuring that raw materials are sourced sustainably can help maintain healthy ecosystems and support biodiversity.',
    subcontent: 'Practices such as using certified sustainable timber, supporting fair trade agricultural products, and sourcing seafood from sustainable fisheries help preserve diverse species and their habitats. ',
    subcontent1: 'Sustainable sourcing also promotes ethical supply chains, benefiting local communities and ecosystems. Additionally, companies that prioritize sustainable sourcing can enhance their brand reputation, appeal to eco-conscious consumers, and mitigate risks associated with environmental degradation. This approach supports long-term business viability and environmental health.',
  },
  {
    tabTitle: 'Bio-Friendly Practices',
    img: '/assets/img/blog/bioPrac.jpg',
    title: 'Biodiversity-Friendly Practices',
    content: 'Incorporating green spaces, such as gardens and parks, into corporate campuses can enhance local biodiversity and provide recreational spaces for employees.',
    subcontent: 'Effective biodiversity conservation often requires collaboration between businesses, governments, and NGOs. Such partnerships can pool resources, expertise, and influence to achieve greater impact.',
    subcontent1: 'These partnerships can pool resources, expertise, and influence to achieve greater impact. Additionally, implementing biodiversity-friendly practices can boost employee well-being and productivity, while also showcasing a company’s commitment to environmental stewardship, appealing to eco-conscious consumers and investors.',
  },
]

const TextSliderData02 = [
  {
    img: "/assets/img/blog/beetlePol.jpg",
    // title: 'Impact on',
    subtitle: 'Pollination',
    content: 'Many crops rely on insects like bees for pollination. Growing a single crop on the same soil for consecutive seasons leads pollinators with fewer resources, which leads to a decline in bee population. Without these pollinators, food production would plummet, leading to reduced food security and increased malnutrition.',

  },
  {
    img: "/assets/img/blog/fox.jpg",
    // title: 'Pollination',
    subtitle: 'Disease Prevention & Control',
    content: "The loss of biodiversity increases the risk of diseases, particularly those transmitted from animals to humans (zoonotic diseases). As ecosystems degrade, humans and wildlife come into closer contact, facilitating the spillover of pathogens.",
    subcontent: "Studies have shown that when natural habitats are fragmented by human activities, wildlife is forced into closer proximity with human settlements.",
    subcontent1: "This increased interaction raises the likelihood of disease transmission. For example, a study published in Frontiers in Veterinary Science highlights how habitat fragmentation can enhance the risk of rabies spillover from wildlife to domestic animals and humans.",

  },
  {
    img: "/assets/img/blog/tick.jpg",
    // title: "Medicinal",
    subtitle: "Zoonotic Spillover",
    content: 'When natural habitats are destroyed, species that carry diseases are forced into closer proximity with humans. This increases the likelihood of diseases like COVID-19, which is believed to have originated in animals, spreading to humans.',
    subcontent: 'In North America, the reduction of predators like wolves has led to an explosion in deer and mice populations, which are primary hosts for Lyme disease-carrying ticks.',
    subcontent1: 'Additionally, habitat loss and changes in land use have been linked to increased incidences of West Nile Virus, as mosquito populations thrive in altered environments.',

  },
  {
    img: "/assets/img/blog/ivdrop.jpg",
    // title: "Ecosystem",
    subtitle: "Medicinal Resources",
    content: "Biodiversity is a crucial source of medicinal compounds, with many modern drugs derived from natural sources. For instance, penicillin, the first widely used antibiotic, was derived from the Penicillium mold and revolutionized medicine by effectively treating bacterial infections.",
    subcontent: "Since its discovery, penicillin has saved countless lives. Annually, antibiotics like penicillin are estimated to save over 200,000 lives in the United States alone and millions globally​​. Additionally, penicillin has played a crucial role in extending life expectancy and improving overall human health by preventing deaths from bacterial infections such as pneumonia, scarlet fever, and various other bacterial diseases.",

  },
]

// Filter the blog data category wise
const blogCleanData = blogData.filter((item) => item.blogType === "clean");

const metadata = {
  "id": 4,
  "editor": {
    "uuid": "5bb7c896-58f2-4371-bda4-20d7fc95decd",
    "firstname": "Line",
    "lastname": "Kræmer",
    "email": "likr@faunaphotonics.com",
    "initials": "likr"
  },
  "title": "What Does CSR Stand For? And Why It Is Important",
  "url": null,
  "image": null,
  "slug": "what-does-csr-stand-for-and-why-it-is-important",
  "created_at": "2024-06-14T07:33:54.000000Z",
  "updated_at": "2024-06-14T07:33:54.000000Z",
  "deleted_at": null
}

const scroll = (h) => {
  const sections = document.querySelectorAll('h2')
  const target = sections[h]
  target.scrollIntoView({
    behavior: 'smooth',
    block: 'start',
  })
}


const BioHumans = ({ post, props, style }) => {
  const [data, stripped, content, setData,] = useState(post)
  const swiperRef = React.useRef(null)

  const [header] = useState(post)

  const $ = cheerio.load(stripped)
  const h2 = $('h2')
  const toc = h2.map((contents) => {
    return contents.children[0]?.children[0]?.data
  })

  const scroll = (h) => {
    const sections = document.querySelectorAll('h2')
    const target = sections[h]
    target.scrollIntoView({
      behavior: 'smooth',
      block: 'start',
    })
  }


  const [activeSlide, setActiveSlide] = useState(0)

  const [submitted, setSubmitted] = useState(false)

  return (

    <div>

      <section className="w-full xxl:h-[900px] mxl:h-[800px] xl:h-[500px] sm:h-[210px] xs:h-[200px] cover-background relative"
        style={{ backgroundImage: "url('/assets/img/blog/blog4Banner.jpg')" }}>
      </section>

      {/* Section Start */}
      <section className=" bg-white pb-0 sm:py-[50px] xs:overflow-hidden mxl:mt-[0px] xl:mt-[100px] sm:mt-[-90px] xs:mt-[-100px]">
        <Container className="xs:p-0">
          <Row className="justify-center z-10">
            <Overlap className="!px-0">
              <Col lg={10} className="font-serif text-center bg-[#6D597A] text-[#fff] mxl:py-[7rem] xl:py-[4.5rem] lg:py-[4.5rem] md:py-[4.5rem] sm:py-[3rem] xs:py-[2.5rem] xs:px-[15px] mxl:w-full relative mx-auto opacity-[95%] xs:pt-[-200px] xs:mb-[20px]"
                style={{ backgroundImage: "url('/assets/img/webp/blog-post-layout-02-img-pattern.webp')" }}>
                <div className="w-[1px] h-[90px] xs:h-[40px] bg-white mx-auto absolute top-0 left-1/2 -translate-x-1/2 -translate-y-1/2"></div>
                <div className="uppercase text-md font-medium mb-[25px] font-serif">
                  <div className='text-white bg-black'></div>
                  <Link aria-label="link" to={'/solutions'} className="text-[#fff] inline-block xs:text-[12px]">Solutions</Link>
                  <span className="mx-[20px] w-[1px] h-[10px] bg-white inline-block"></span>
                  <Link aria-label="link" to={'/metrics'} className="inline-block text-[#fff] xs:text-[12px]">Metrics</Link>
                  <span className="mx-[20px] w-[1px] h-[10px] bg-white inline-block"></span>
                  <Link aria-label="link" to={'/contact'} className="inline-block text-[#fff] xs:text-[12px]">Contact</Link>
                </div>
                <h1 className="font-semibold w-full mxl:mb-[15px] xl:mb-[5px] lg:mb-[3px] xs:mb-[-20px] mxl:tracking-[1px] md:w-full xs:w-full xxl:text-[44px] mxl:text-[44px] xl:text-[38px] lg:text-[32px] md:text-[30px]  xs:text-[15px]">
                  How Could the loss of Biodiversity
                </h1>
                <h1 className="font-semibold xs:mb-[-20px] w-full mxl:tracking-[1px] md:w-full xs:w-full xxl:text-[44px] mxl:text-[44px] xl:text-[38px] lg:text-[32px] md:text-[30px] xs:text-[15px]">
                  Affect Humans?
                </h1>
                <div className="inline-block text-[#fff] mt-[25px] xs:text-[13px]">
                  By FaunaPhotonics xx. July 2024
                </div>
              </Col>
            </Overlap>
          </Row>
        </Container>
      </section>
      {/* Seaction End */}

      {/* Section Start */}
      <m.section className="bg-white mxl:mt-[140px] xl:mt-[100px] lg:mt-[100px] md:mt-[30px] sm:mt-[40px] xs:mt-[20px]">
        <Container>
          <Row>
            <Col className="justify-center">
              <Col>
                <div className="bg-white">
                  <p className="text-justify font-light text-[#0F3443] mxl:text-[30px] xl:text-[28px] md:text-[22px] sm:text-[20px] xs:text-[12px] mxl:leading-[45px] xl:leading-[40px] md:leading-[30px] sm:leading-[30px] xs:leading-[20px] border-l-[7px] border-[#6D597A] xl:border-l-[7px] lg:border-l-[5px] md:border-l-[5px] xs:border-l-[4px] pl-4 pr-4">
                    Did you know that over one million species are currently facing
                    extinction due to human activities? In a recent UN report, biodiversity
                    is declining faster than at any other time in human history, threatening
                    the stability of ecosystems worldwide.
                  </p>
                </div>
              </Col>
            </Col>
          </Row>
        </Container>
      </m.section>
      {/* Seaction End */}

      {/* Section Start */}
      <m.section className="bg-white overflow-hidden mxl:mt-[50px] xl:mt-[50px] lg:mt-[50px] md:mt-[50px] xs:mt-[20px]">
        <Container>
          <Row className="justify-center">
            <p className="text-[#0F3443] text-uppercase mxl:mb-[50px] xl:mb-[50px] lg:mb-[50px] md:mb-[25px] xs:mb-[20px] text-center font-serif font-semibold tracking-[3px] mxl:text-[50px] xl:text-[40px] lg:text-[35px] md:text-[25px] sm:text-[20px] xs:text-[12px] xs:tracking-[1px] mxl:leading-[60px] xl:leading-[50px] md:leading-[35px] sm:leading-[30px] xs:leading-[20px]">
              This article explores the profound<br></br> impact of <span className="text-gradient bg-gradient-to-tr from-[#897198] to-[#6D597A] font-semibold">human health, </span><br></br>the <span className="text-gradient bg-gradient-to-tr from-[#897198] to-[#6D597A] font-semibold">economy </span>, & <span className="text-gradient bg-gradient-to-tr from-[#897198] to-[#6D597A] font-semibold">society </span> as a whole
            </p>
            <Col>
              <div className="bg-white">
                <p className="text-justify font-light text-[#0F3443] mxl:text-[30px] xl:text-[28px] md:text-[22px] sm:text-[20px] xs:text-[12px] mxl:leading-[45px] xl:leading-[40px] lg:leading-[35px] md:leading-[30px] sm:leading-[30px] xs:leading-[20px]">
                  Emphasis on the interconnectedness of human well-being and biodiversity,
                  illustrating how the decline in biodiversity directly affects our lives.
                </p>
              </div>
            </Col>
          </Row>
        </Container>
      </m.section>
      {/* Section End */}

      {/* Section Start */}
      <m.section className="justify-center bg-white overflow-hidden mxl:mt-[100px] xl:mt-[100px] lg:mt-[100px] md:mt-[50px] xs:mt-[20px]">
        <Container fluid>
          <h2
            className="bg-gradient-to-r font-serif title font-bold text-center uppercase mxl:text-[130px] xl:text-[80px] lg:text-[70px] md:text-[50px] sm:text-[35px] xs:text-[20px] mxl:leading-[100px] xl:leading-[80px] lg:leading-[80px] md:leading-[60px] sm:leading-[100px] xs:leading-[30px] -tracking-[-1px] xs:-tracking-[-1px]"
            style={{
              backgroundImage: "url(/assets/img/blog/tabanidBg.jpg)",
              backgroundClip: "text",
              WebkitTextFillColor: "transparent",
            }}>
            Importance of Biodiversity
          </h2>
        </Container>
      </m.section>
      {/* Section End */}

      {/* Section Start */}
      <m.section className="bg-white overflow-hidden mxl:mt-[50px] xl:mt-[50px] lg:mt-[50px] md:mt-[25px] sm:mt-[40px] xs:mt-[0px]">
        <Container>
          <Row className="justify-center">
            <div className="bg-white">
              <p className="text-justify font-light text-[#0F3443] mxl:text-[30px] xl:text-[28px] md:text-[22px] sm:text-[20px] xs:text-[12px] mxl:leading-[45px] xl:leading-[40px] md:leading-[30px] sm:leading-[30px] xs:leading-[20px]">
                Biodiversity refers to the variety of life on Earth, encompassing different
                species, ecosystems, and genetic variations. Biodiversity ensures the
                stability and resilience of ecosystems, which in turn support human life
                by providing essential services like clean air, water, and fertile soil.
                Maria Neira, Director of WHO's Department for the Protection of the Human
                Environment, states, "Human health is strongly linked to the health of
                ecosystems, which meet many of our most critical needs."
              </p>
            </div>
          </Row>
        </Container>
      </m.section>
      {/* Section End */}

      {/* Seaction Start */}
      <m.section
        className="bg-[#6D597A] overflow-hidden mxl:mt-[100px] xl:mt-[100px] lg:mt-[100px] md:mt-[50px] xs:mt-[40px] mxl:py-[100px] xl:py-[70px] lg:py-[60px] md:py-[50px] xs:py-[30px] xs:px-[5px]"
        {...fadeIn}
        style={{ backgroundImage: "url('/assets/img/webp/blog-post-layout-02-img-pattern.webp')" }}>
        <Container>
          <Row className="justify-center">
            <Col xl={6} md={8} lg={7} className="px-md-0">
              <p className="font-bold text-[#fff] tracking-[1px]  text-center font-serif xxl:text-[40px] mxl:text-[40px] xl:text-[40px] md:text-[35px] xs:text-[25px]">
                WANT TO KNOW MORE?
              </p>
              <p className="font-bold text-darkgray tracking-[1px] my-16 text-center font-serif xl:leading-[55px] xxl:text-[40px] mxl:text-[40px] xxl:leading-[55px] mxl:leading-[55px] xl:text-[40px] xs:text-[25px] xs:leading-[30px]">
                REACH OUT TO OUR BIODIVERSITY EXPERTS TODAY
              </p>
              <Link
                className="uppercase inner-link nav-link font-bold text-[#fff] tracking-[1px]  text-center font-serif xxl:text-[40px] mxl:text-[40px] xl:text-[40px] md:text-[35px] xs:text-[25px]"
                to={'/contact'}>Click Here!
              </Link>
            </Col>
          </Row>
        </Container>
      </m.section>
      {/* Seaction End */}

      {/* Section Start */}
      <m.section className="bg-white overflow-hidden mxl:mt-[100px] xl:mt-[100px] lg:mt-[100px] md:mt-[75px] sm:mt-[60px] xs:mt-[50px]">
        <Container>
          <Row className="justify-center">
            <h2 className="text-[#0F3443] text-uppercase mxl:mb-[50px] xl:mb-[50px] lg:mb-[50px] md:mb-[25px] xs:mb-[20px] text-center font-serif font-semibold tracking-[3px] mxl:text-[50px] xl:text-[50px] lg:text-[35px] md:text-[32px] sm:text-[20px] xs:text-[15px] xs:tracking-[1px] sm:leading-[30px] xs:leading-[30px]">
              Biodiversity & <span className="text-gradient bg-gradient-to-tr from-[#897198] to-[#6D597A] font-semibold">Human Health</span>
            </h2>
            <Col>
              <div className="bg-white">
                <p className="text-justify font-light text-[#0F3443] mxl:mb-[50px] xl:mb-[50px] lg:mb-[50px] md:mb-[25px] sm:mb-[30px] xs:mb-[20px] mxl:text-[30px] xl:text-[28px] md:text-[22px] sm:text-[20px] xs:text-[12px] mxl:leading-[45px] xl:leading-[40px] lg:leading-[35px] md:leading-[30px] sm:leading-[30px] xs:leading-[20px]">
                  Healthy ecosystems are fundamental to human health, providing a
                  range of services that sustain life. Ecosystem services
                  directly impacting human well-being include:
                </p>
              </div>
            </Col>
          </Row>
        </Container>
      </m.section>
      {/* Section End */}

      {/* Section Start */}
      <section className="xxl:mt-[10px] mxl:mt-[10px] xl:mt-[20px] lg:mt-[0px] md:mt-[0px] sm:mt-[0px] xs:mt-[0px] mb-[50px] xs:mb-[30px] relative">
        <Container fluid>
          <Swiper
            ref={swiperRef}
            slidesPerView="auto"
            speed={1000}
            pagination={{ clickable: true }}
            breakpoints={{
              992: {
                slidesPerView: "auto",
                spaceBetween: 0
              }
            }}
            loop={true}
            modules={false}
            autoplay={false}
            keyboard={{ enabled: true, onlyInViewport: true }}
            centeredSlides={true}
            className="SingleProjectPage05 relative black-move"
          >
            {TextSliderData02.map((item, i) => {
              return (
                <SwiperSlide key={i} className="xxl:w-[25%] mxl:w-[37%] w-[55%] lg:w-5/6 md:w-full xs:w-full">
                  <div className="relative">
                    <img height="" width="" src={item.img} alt="" />
                    <div
                      className="absolute bottom-0 right-0 mxl:w-[70%] xl:w-[60%] lg:w-[60%] md:w-[60%] sm:w-[60%] xs:w-[65%]  mxl:h-4/5 xl:h-4/5 lg:h-4/5  md:h-4/5 xs:h-full flex justify-center items-center bg-white opacity-[95%] p-4"
                      style={{ backgroundClip: 'content-box' }}
                    >
                      <Row>
                        <Col className="justify-center">
                          <Col>
                            <div className="sm:pr-[10px] sm:pl-[13px] sm:mr-[50px] sm:mt-[-15px] xs:pr-[7px] xs:pl-[4px] xs:mr-[15px] xs:mt-[-20px] mxl:mt-[-20px] mxl:pl-[15px] mxl:mr-[20px] xl:mt-[-15px] xl:mr-[20px] xl:pl-[18px] lg:mt-[-20px] lg:mr-[30px] lg:pl-[30px] mr-[40px] pl-[30px] mt-[0px] md:mt-[-10px] md:mr-[60px] md:pl-[20px]">
                              <h2 className="uppercase font-serif text-[#6D597A] font-semibold mxl:text-[18px] xl:text-[18px] lg:text-[20px] md:text-[18px] sm:text-[10px] xs:text-[7px] tracking-[1.5px] xs:tracking-[-0.5px] mxl:mt-[10px] xl:mt-[0px] lg:mt-[0px] md:mt-[0px] sm:mt-[0px] xs:mt-[0px]">{item.subtitle}</h2>
                              <p className="text-justify font-serif text-[#0F3443] font-light mxl:text-[13px] xl:text-[13px] lg:text-[14px] md:text-[13px] sm:text-[9px] xs:text-[5px] mxl:leading-[18px] xl:leading-[18px] lg:leading-[18px] md:leading-[15px] sm:leading-[11px] xs:leading-[6px] mxl:mt-[-40px] xl:mt-[-35px] lg:mt-[-20px] md:mt-[-20px] sm:mt-[-28px] xs:mt-[-32px]">{item.content}</p>
                              <p className="text-justify font-serif text-[#0F3443] font-light mxl:text-[13px] xl:text-[13px] lg:text-[14px] md:text-[13px] sm:text-[9px] xs:text-[5px] mxl:leading-[18px] xl:leading-[18px] lg:leading-[18px] md:leading-[15px] sm:leading-[11px] xs:leading-[6px] mxl:mt-[8px] xl:mt-[8px] lg:mt-[10px] md:mt-[12px] sm:mt-[8px] xs:mt-[3px]">{item.subcontent}</p>
                              <p className="text-justify font-serif text-[#0F3443] font-light mxl:text-[13px] xl:text-[13px] lg:text-[14px] md:text-[13px] sm:text-[9px] xs:text-[5px] mxl:leading-[18px] xl:leading-[18px] lg:leading-[18px] md:leading-[15px] sm:leading-[11px] xs:leading-[6px] mxl:mt-[8px] xl:mt-[8px] lg:mt-[10px] md:mt-[12px] sm:mt-[8px] xs:mt-[3px]">{item.subcontent1}</p>
                            </div>
                          </Col>
                        </Col>
                      </Row>
                    </div>
                  </div>
                </SwiperSlide>
              );
            })}
          </Swiper>
        </Container>
        {
          <>
            <div
              onClick={() => swiperRef.current.swiper.slidePrev()}
              className="absolute top-1/2 -translate-y-1/2 left-[40px] z-[1] cursor-pointer slider-navigation-style-07 swiper-button-previous-nav xs:left-[10px]"
            >
              <i className="feather-arrow-left text-[15px] text-black font-normal shadow-[0_0_30px_rgba(0,0,0,0.08)] bg-white flex justify-center items-center rounded-full h-[40px] w-[40px] text-center sm:h-[40px] sm:w-[40px]"></i>
            </div>
            <div
              onClick={() => swiperRef.current.swiper.slideNext()}
              className="absolute top-1/2 -translate-y-1/2 right-[40px] z-[1] cursor-pointer slider-navigation-style-07 swiper-button-next-nav xs:right-[10px]"
            >
              <i className="feather-arrow-right text-[15px] text-black font-normal shadow-[0_0_30px_rgba(0,0,0,0.08)] bg-white flex justify-center items-center rounded-full h-[40px] w-[40px] text-center sm:h-[40px] sm:w-[40px]"></i>
            </div>
          </>
        }
      </section>
      {/* Section End */}

      {/* Section Start */}
      <m.section className="justify-center bg-white overflow-hidden mxl:mt-[100px] xl:mt-[100px] lg:mt-[100px] md:mt-[50px] xs:mt-[0px]">
        <Container fluid>
          <h2
            className="bg-gradient-to-r font-serif title font-bold text-center uppercase mxl:text-[130px] xl:text-[80px] lg:text-[70px] md:text-[50px] sm:text-[20px] xs:text-[15px] mxl:leading-[130px] xl:leading-[80px] lg:leading-[80px] md:leading-[60px] sm:leading-[30px] xs:leading-[25px] -tracking-[-1px] xs:-tracking-[-1px]"
            style={{
              backgroundImage: "url(/assets/img/blog/tabanidBg.jpg)",
              backgroundClip: "text",
              WebkitTextFillColor: "transparent",
            }}>
            Social & Environmental Consequences
          </h2>
        </Container>
      </m.section>
      {/* Section End */}

      {/* Section Start */}
      <m.section className="bg-white overflow-hidden mxl:mt-[50px] xl:mt-[50px] lg:mt-[50px] md:mt-[25px] sm:mt-[40px] xs:mt-[0px]">
        <Container>
          <Row className="justify-center">
            <div className="bg-white">
              <p className="text-justify font-light text-[#0F3443] mxl:mb-[50px] xl:mb-[50px] lg:mb-[50px] md:mb-[30px] sm:mb-[30px] xs:mb-[15px] mxl:text-[30px] xl:text-[28px] md:text-[22px] sm:text-[20px] xs:text-[12px] mxl:leading-[45px] xl:leading-[40px] md:leading-[30px] sm:leading-[30px] xs:leading-[20px] border-l-[7px] border-[#6D597A] xl:border-l-[7px] lg:border-l-[5px] md:border-l-[5px] xs:border-l-[2px] pl-4 pr-4">
                Biodiversity is vital for the livelihoods of many communities,
                particularly in developing countries where people rely directly
                on natural resources for their survival and economic activities.
              </p>
            </div>
            <div className="bg-white">
              <p className="text-justify font-light text-[#0F3443] mxl:mb-[50px] xl:mb-[50px] lg:mb-[50px] md:mb-[30px] sm:mb-[30px] xs:mb-[15px] mxl:text-[30px] xl:text-[28px] md:text-[22px] sm:text-[20px] xs:text-[12px] mxl:leading-[45px] xl:leading-[40px] md:leading-[30px] sm:leading-[30px] xs:leading-[20px]">
                Communities in regions such as Southeast Asia, Africa, and
                Latin America depend on biodiversity for fishing, agriculture,
                medicine, and raw materials. The loss of biodiversity can lead
                to reduced income, food insecurity, and increased poverty.
              </p>
            </div>
            <div className="bg-white">
              <p className="text-justify font-light text-[#0F3443] mxl:mb-[50px] xl:mb-[50px] lg:mb-[50px] md:mb-[30px] sm:mb-[30px] xs:mb-[15px] mxl:text-[30px] xl:text-[28px] md:text-[22px] sm:text-[20px] xs:text-[12px] mxl:leading-[45px] xl:leading-[40px] md:leading-[30px] sm:leading-[30px] xs:leading-[20px]">
                In the Amazon rainforest, indigenous communities rely on the
                forest for food, medicine, and shelter. Deforestation and habitat
                loss threaten their way of life, leading to displacement and
                loss of traditional knowledge.
              </p>
            </div>
            <div className="bg-white">
              <p className="text-justify font-light text-[#0F3443] mxl:text-[30px] xl:text-[28px] md:text-[22px] sm:text-[20px] xs:text-[12px] mxl:leading-[45px] xl:leading-[40px] md:leading-[30px] sm:leading-[30px] xs:leading-[20px]">
                In coastal Kenya, the decline of fish populations due to
                overfishing and habitat destruction has severely impacted
                local fishing communities, reducing their main source of
                food and income.
              </p>
            </div>
          </Row>
        </Container>
      </m.section>
      {/* Section End */}

      {/* Section Start */}
      <m.section className="bg-white overflow-hidden mxl:mt-[50px] xl:mt-[50px] lg:mt-[50px] md:mt-[25px] sm:mt-[40px] xs:mt-[20px]">
        <Container>
          <Row className="justify-center">
            <h3 className="text-[#0F3443] text-uppercase text-center font-serif font-semibold tracking-[3px] mxl:text-[50px] xl:text-[50px] lg:text-[35px] md:text-[30px] sm:text-[20px] xs:text-[12px] xs:tracking-[1px] sm:leading-[30px] xs:leading-[30px]">
              <span className="text-gradient bg-gradient-to-tr from-[#897198] to-[#6D597A] font-semibold">Cultural </span>& <span className="text-gradient bg-gradient-to-tr from-[#897198] to-[#6D597A] font-semibold">Recreational </span>Value
            </h3>
          </Row>
        </Container>
      </m.section>
      {/* Section End */}

      {/* Section Start */}
      <section className="bg-white overflow-hidden mxl:mt-[50px] xl:mt-[50px] lg:mt-[50px] md:mb-[25px] sm:mt-[20px] xs:mt-[20px] h-[600px] xl:h-auto md:h-auto sm:h-auto xs:my-[30px]">
        <Container fluid>
          <Row className="justify-center">
            <m.div className="col-12 col-xl-4 col-md-6 order-md-3 bg-[#6D597A] p-[8.5rem] xl:px-[5rem] xl:py-[3rem] lg:py-[3rem] lg:px-[2rem] md:px-[2rem] md:py-[3rem] sm:px-[2rem] sm:py-[2rem] xs:px-[2rem] xs:py-[2rem] lg:w-1/3 md:w-1/3 xs:w-full">
              <p className="font-serif text-[#fff] font-semibold mxl:text-[35px] xl:text-[30px] lg:text-[22px] md:text-[20px] sm:text-[15px] xs:text-[15px] mxl:pb-[30px] xl:pb-[20px]">
                Cultural Biodiversity
              </p>
              <p className="text-white font-light text-justify mxl:text-[25px] xl:text-[20px] lg:text-[18px] md:text-[15px] sm:text-[12px] xs:text-[15px] mxl:leading-[35px] xl:leading-[25px] lg:leading-[25px] md:leading-[20px] sm:leading-[18px] xs:leading-[20px]">
                Biodiversity plays a significant role in cultural practices
                and recreational activities, contributing to the cultural
                heritage and quality of life for many people.
              </p>
            </m.div>
            <m.div className="col-12 col-xl-4 col-md-6 order-md-3 bg-[#F5F5F5] p-[8.5rem] xl:px-[5rem] xl:py-[3rem] lg:py-[3rem] lg:px-[2rem] md:px-[2rem] md:py-[3rem] sm:px-[2rem] sm:py-[2rem] xs:px-[2rem] xs:py-[2rem] lg:w-1/3 md:w-1/3 xs:w-full">
              <p className="font-serif text-[#6D597A] font-semibold  mxl:text-[35px] xl:text-[30px] lg:text-[22px] md:text-[20px] sm:text-[15px] xs:text-[15px] mxl:pb-[30px] xl:pb-[20px]">
                Traditional Ties
              </p>
              <p className="text-[#6D597A] font-light text-justify mxl:text-[25px] xl:text-[20px] lg:text-[18px] md:text-[15px] sm:text-[12px] xs:text-[15px]  mxl:leading-[35px] xl:leading-[25px] lg:leading-[25px] md:leading-[20px] sm:leading-[18px] xs:leading-[20px]">
                Many cultures have deep-rooted traditions and practices tied
                to the natural world, including rituals, festivals, and traditional
                medicine. The loss of species and ecosystems can erode these
                cultural practices.
              </p>
            </m.div>
            <m.div className="col-12 col-xl-4 col-md-6 order-md-3 bg-[#6D597A] p-[8.5rem] xl:px-[5rem] xl:py-[3rem] lg:py-[3rem] lg:px-[2rem] md:px-[2rem] md:py-[3rem] sm:px-[2rem] sm:py-[2rem] xs:px-[2rem] xs:py-[2rem] lg:w-1/3 md:w-1/3 xs:w-full">
              <p className="font-serif text-[#fff] font-semibold mxl:text-[35px] xl:text-[30px] lg:text-[22px] md:text-[20px] sm:text-[15px] xs:text-[15px] mxl:pb-[30px] xl:pb-[20px]">
                Medicinal Heritage
              </p>
              <p className="text-white font-light text-justify mxl:text-[25px] xl:text-[20px] lg:text-[18px] md:text-[15px] sm:text-[12px] xs:text-[15px] mxl:leading-[35px] xl:leading-[25px] lg:leading-[25px] md:leading-[20px] sm:leading-[18px] xs:leading-[20px]">
                In India, the use of plants in traditional Ayurvedic medicine
                is integral to cultural heritage. The decline in plant biodiversity
                threatens the availability of these medicinal resources.
              </p>
            </m.div>
          </Row>
        </Container>
      </section>
      {/* Seaction End */}

      {/* Section Start */}
      <m.section className="justify-center bg-white overflow-hidden mxl:mt-[100px] xl:mt-[100px] lg:mt-[100px] md:mt-[50px] xs:mt-[0px]">
        <Container fluid>
          <h2
            className="bg-gradient-to-r font-serif title font-bold text-center uppercase mxl:text-[130px] xl:text-[80px] lg:text-[70px] md:text-[50px] sm:text-[20px] xs:text-[15px] mxl:leading-[130px] xl:leading-[80px] lg:leading-[80px] md:leading-[60px] sm:leading-[30px] xs:leading-[25px] -tracking-[-1px] xs:-tracking-[-1px]"
            style={{
              backgroundImage: "url(/assets/img/blog/tabanidBg.jpg)",
              backgroundClip: "text",
              WebkitTextFillColor: "transparent",
            }}>
            Corporate Responsibility
          </h2>
        </Container>
      </m.section>
      {/* Section End */}


      {/* Section Start */}
      <section className="mxl:mt-[140px] xl:mt-[80px] lg:mt-[100px] md:mt-[50px] sm:mt-[40px] xs:mt-[30px]">
        <Container>
          <Row className="justify-center">
            <m.div className="col-12 col-md-6 bg-lightgray bg-no-repeat bg-right-bottom px-[70px] md:px-[40px] mxl:py-[45px] xl:py-[50px] lg:py-[40px] md:py-[30px] xs:py-10 lg:px-[5.5rem]" {...{ ...fadeIn, transition: { delay: 0.1 } }} >
              <Blockquote
                theme="blockquote-style-04-03"
                subtitle="Business & Biodiversity: A Sustainable Synergy"
                style={{
                  '--subtitle-color': "text-[#6D597A]"
                }}
                title="Corporate Responsibility"
                content="
                Businesses play a crucial role in protecting biodiversity, as 
                their operations can significantly impact natural ecosystems. 
                By integrating biodiversity conservation into their strategies, 
                companies can not only mitigate negative effects but also reap 
                several benefits:"
                subcontent="Companies that invest in sustainable practices and 
                show commitment to biodiversity gain a positive reputation, 
                fostering customer loyalty and trust. Sustainable resource 
                management ensures long-term availability of raw materials and 
                reduces risks associated with environmental degradation. Proactive 
                biodiversity conservation can help businesses stay ahead of 
                environmental regulations, avoiding potential fines and legal issues."
              />
            </m.div>
            <m.div className="col col-md-6 bg-cover bg-center sm:h-[450px] xs:h-[300px]" style={{ backgroundImage: "url(/assets/img/blog/corporateRes.jpg)" }} {...{ ...fadeIn, transition: { delay: 0.3 } }}></m.div>
          </Row>
        </Container>
      </section>
      {/* Seaction End */}

      {/* Section Start */}
      <m.section className="bg-white overflow-hidden mxl:mt-[100px] xl:mt-[100px] lg:mt-[100px] md:mt-[50px] sm:mt-[40px] xs:mt-[40px]">
        <Container>
          <Row className="justify-center">
            <h3 className="text-[#0F3443] text-uppercase text-center font-serif font-semibold tracking-[3px] mxl:text-[50px] xl:text-[50px] lg:text-[30px] md:text-[22px] sm:text-[20px] xs:text-[12px] xs:tracking-[1px] md:leading-[30px] sm:leading-[30px] xs:leading-[20px]">
              Businesses can take several <span className="text-gradient bg-gradient-to-tr from-[#897198] to-[#6D597A] font-semibold">practical steps </span><br></br>to support <span className="text-gradient bg-gradient-to-tr from-[#897198] to-[#6D597A] font-semibold">biodiversity </span>
            </h3>
          </Row>
        </Container>
      </m.section>
      {/* Section End */}

      {/* Section Start */}
      <m.section className="bg-white overflow-hidden mxl:mt-[50px] xl:mt-[50px] lg:mt-[25px] md:mt-[0px] sm:mt-[20px] xs:mt-[20px] pt-[15px]"  {...fadeIn}>
        <Container>
          <Tab06 data={TabData06} />
        </Container>
      </m.section>
      {/* Section End */}


      {/* Section Start */}
      <m.section className="justify-center bg-white overflow-hidden mxl:mt-[100px] xl:mt-[100px] lg:mt-[100px] md:mt-[50px] xs:mt-[30px]">
        <Container fluid>
          <h2
            className="bg-gradient-to-r font-serif title font-bold text-center uppercase mxl:text-[130px] xl:text-[80px] lg:text-[70px] md:text-[50px] sm:text-[20px] xs:text-[25px] mxl:leading-[130px] xl:leading-[80px] lg:leading-[80px] md:leading-[60px] sm:leading-[30px] xs:leading-[25px] -tracking-[-1px] xs:-tracking-[-1px] mxl:mb-[30px] xl:mb-0 lg:mb-0 md:mb-0 xs:mb-[0px]"
            style={{
              backgroundImage: "url(/assets/img/blog/tabanidBg.jpg)",
              backgroundClip: "text",
              WebkitTextFillColor: "transparent",
            }}>
            Preserving Biodiversity
          </h2>
          <h2 className="font-light text-center text-[#6D597A] mxl:text-[50px] xl:text-[40px] lg:text-[35px] md:text-[30px] xs:text-[20px]">
            for a sustainable future
          </h2>
        </Container>
      </m.section>
      {/* Section End */}


      {/* Section Start */}
      <m.section className="bg-white overflow-hidden mxl:mt-[50px] xl:mt-[50px] lg:mt-[50px] md:mt-[25px] sm:mt-[40px] xs:mt-[0px]">
        <Container>
          <Row className="justify-center">
            <div className="bg-white">
              <p className="text-justify font-light text-[#0F3443] mxl:mb-[50px] xl:mb-[50px] lg:mb-[50px] md:mb-[30px] sm:mb-[30px] xs:mb-[15px] mxl:text-[30px] xl:text-[28px] md:text-[22px] sm:text-[20px] xs:text-[12px] mxl:leading-[45px] xl:leading-[40px] md:leading-[30px] sm:leading-[30px] xs:leading-[20px]">
                The loss of biodiversity has far-reaching impacts on human
                health, economic stability, and social well-being. Biodiversity
                is crucial for ecosystem services, food security, sustainable
                industries, community livelihoods, cultural heritage, and mental
                health.
              </p>
            </div>
            <div className="bg-white">
              <p className="text-justify font-light text-[#0F3443] mxl:mb-[70px] xl:mb-[50px] lg:mb-[50px] md:mb-[30px] sm:mb-[30px] xs:mb-[15px] mxl:text-[30px] xl:text-[28px] md:text-[22px] sm:text-[20px] xs:text-[12px] mxl:leading-[45px] xl:leading-[40px] md:leading-[30px] sm:leading-[30px] xs:leading-[20px]">
                Business leaders are in a unique position to drive significant
                change. Immediate action is needed to integrate biodiversity
                conservation into corporate strategies, adopt sustainable practices,
                and collaborate with other stakeholders. By doing so, businesses
                can ensure a healthy planet and sustainable future for all.
              </p>
            </div>
          </Row>
        </Container>
      </m.section>
      {/* Section End */}

      {/* Section Start */}
      <m.section className="bg-white overflow-hidden">
        <Container>
          <Row className="row-cols-1 justify-center">
            <Col xl={8} lg={10} className="text-center">
              <p className="font-serif font-medium text-[#0F3443] mxl:text-[45px] xl:text-[45px] lg:text-[40px] md:text-[35px] xs:text-[18px] mxl:leading-[70px] xl:leading-[65px] lg:leading-[60px] md:leading-[55px] xs:leading-[28px] -tracking-[1px]">
                "In the end, we will <span className="text-gradient bg-gradient-to-tr from-[#897198] to-[#6D597A] font-semibold">conserve </span>only what we <span className="text-gradient bg-gradient-to-tr from-[#897198] to-[#6D597A] font-semibold">love; </span> we will love only what we <span className="text-gradient bg-gradient-to-tr from-[#897198] to-[#6D597A] font-semibold"> understand, </span>
                and we will understand only what we are <span className="text-gradient bg-gradient-to-tr from-[#897198] to-[#6D597A] font-semibold"> taught."</span> <br></br><span className="font-light mxl:text-[18px] xl:text-[12px] xs:text-[10px] mxl:tracking-[1px] xl:tracking-[1px] xs:tracking-[0px]">— Baba Dioum, Senegalese Forestry Engineer</span></p>
            </Col>
          </Row>
        </Container>
      </m.section>
      {/* Section End */}

      {/* Section Start */}
      <m.section className="bg-white overflow-hidden mxl:mt-[70px] xl:mt-[50px] lg:mt-[50px] md:mt-[25px] sm:mt-[40px] xs:mt-[15px]">
        <Container>
          <Row className="justify-center">
            <div className="bg-white">
              <p className="text-justify font-light text-[#0F3443] mxl:text-[30px] xl:text-[28px] md:text-[22px] sm:text-[20px] xs:text-[12px] mxl:leading-[45px] xl:leading-[40px] md:leading-[30px] sm:leading-[30px] xs:leading-[20px]">
                This quote encapsulates the essence of why businesses must take up the
                mantle of biodiversity conservation. Understanding and valuing biodiversity
                is the first step towards its protection, and it is through collective
                efforts that we can secure a thriving planet for future generations.
              </p>
            </div>
          </Row>
        </Container>
      </m.section>
      {/* Section End */}

      <m.section className="xxl:my-[200px] mxl:my-[200px] xl:my-[100px] lg:my-[100px] md:py-[95px] sm:pt-[80px] xs:mt-[50px] xs:mb-[-100px]">
        <Container>
          <Row className="">
            <Col className="mb-[2%]">
              <p className="font-serif text-dark text-left text-[15px] font-light mb-[-150px] lg:mb-[15px] xs:mb-[-20px]">
                Sources</p>
            </Col>
          </Row>
          <Row>
            <div className=" p-[10px] sm:p-[20px] text-[15px] xs:text-[5px] xs:leading-[10px]">
              <li>https://www.ncbi.nlm.nih.gov/pmc/articles/PMC6871202/
              </li>
              <li>https://wwf.panda.org/discover/our_focus/biodiversity/biodiversity_and_you/
              </li>
              <li>https://unfoundation.org/blog/post/how-biodiversity-loss-harms-human-health/
              </li>
              <li>https://www.who.int/news-room/feature-stories/detail/antimicrobial-resistance-conserving-life-saving-medicines-takes-everyone-s-help
              </li>
              <li>https://dash.harvard.edu/bitstream/handle/1/8889467/Gottfried05.html
              </li>
              <li>https://www.reactgroup.org/news-and-views/news-and-opinions/year-2018/7-ways-penicillin-has-cured-the-world-for-90-years/
              </li>
              <li>https://www.frontiersin.org/journals/tropical-diseases/articles/10.3389/fitd.2024.1272141/full
              </li>
            </div>
          </Row>
        </Container>
      </m.section>
    </div >







    //            

    //             
    //               



    //
  )
}

export default memo(BioHumans)


