import React, { memo } from 'react'
import { Col, Container, Row } from "react-bootstrap"
import Videos from "../../../Components/Videos/Videos"

const FaunaTestimonials2 = () => {

  return (

    <section className=" bg-white xs:mt-[0rem] xs:mb-[1.5rem]">

      <Videos
        className="z-[0]"
        src="/assets/video/insectSwarm.mp4"
        loop="true"
        fullscreen={false}
        poster="/assets/video/insectSwarm.mp4"
        overlay={["#DA7D58", "#D36135", "#F2C05A", "#F4A4BF", "#EB5C8B"]}
        opacity={0.8}
      >

        <Container>
          <Row className="justify-center z-[1] relative xxl:pt-[50px] mxl:pt-[50px] xl:pt-[35px] xs:pt-[20px]">
            <div className={'col-12 flex justify-content-center  '}>
              <img
                src="/assets/img/png/customers/bavarianNordic.png"
                alt="hej"
                className="h-[5rem] " />
            </div>
            <Col
              lg={8}
              xl={12}
              md={10}
              xs={10}
              className="text-center z-[1] relative pt-[5%] pb-[5%]">
              <p className="xxl:text-[30px] mxl:text-[30px] xl:text-[25px] xxl:leading-[40px] mxl:leading-[40px] xl:leading-[35px] xs:text-[10px] xs:leading-[15px] font-regular font-serif text-white">
                ”We have decided to engage with FaunaPhotonics due to their
                data-backed solution and extensive science-based expertise
                within biodiversity. <br></br> <br></br><span className="font-semibold">
                We believe they are a great partner to
                help us baseline, understand, and continuously monitor our
                impact on biodiversity, firstly throughout our own manufacturing
                operations, and eventually with the potential to upscale further
                into our value chain.”</span>

              </p>
            </Col>
          </Row>
        </Container>
      </Videos>
    </section>

  )
}


export default memo(FaunaTestimonials2)