import React, {memo} from 'react'
import {Col, Container, Row} from "react-bootstrap"
import Videos from "../../../Components/Videos/Videos"

const FaunaTestimonials = () => {

    return (
    
        <section className=" bg-white xs:mt-[0rem] xs:mb-[1.5rem]">

        <Videos
          className="z-[0]"
          src="/assets/video/insectSwarm.mp4"
          loop="true"
          fullscreen={false}
          poster="/assets/video/insectSwarm.mp4"
          overlay={["#DA7D58", "#D36135", "#F2C05A", "#F4A4BF", "#EB5C8B"]}
          opacity={0.8}
        >

          <Container>
            <Row className="justify-center z-[1] relative xxl:pt-[50px] mxl:pt-[50px] xl:pt-[25px] xs:pt-[20px]">
              <div className={'col-12 flex justify-content-center  '}>
                <img
                  src="/assets/img/webp/rema-1000.webp"
                  alt="hej"
                  className="h-[3rem] " />
              </div>
              <Col
                lg={8}
                xl={12}
                md={10}
                xs={10}
                className="text-center z-[1] relative pt-[5%] pb-[5%]">
                <p className="xxl:text-[30px] mxl:text-[30px] xl:text-[25px] xxl:leading-[40px] mxl:leading-[40px] xl:leading-[35px] xs:text-[10px] xs:leading-[15px] font-regular font-serif text-white">
                  ”FaunaPhotonics has been super helpful in the entire process, from
                  planning to setting up the in-field sensors and getting data insights
                  continuously. As we have no biodiversity specialists in-house,
                  it has been really valuable that the team has always been ready to answer any
                  questions we might have. FaunaPhotonics helps us monitor and document our
                  biodiversity initiatives.
                  <br></br><br></br><span className="font-semibold xl:text-[25px] xs:text-[10px]">
                  This is a very important step for us to ensure
                  full transparency with our customers, and to live
                  up to our ambition on reducing our biodiversity footprint.”
                  </span></p>
              </Col>
            </Row>
          </Container>
        </Videos>
      </section>
    )
}


export default memo(FaunaTestimonials)